import {
  useController,
  useFormContext,
  RegisterOptions,
} from "react-hook-form";
import { IMaskMixin, IMask } from "react-imask";
import { TextField } from "@mui/material";
import "./YAOFieldInputOverride.css";

type YAOFieldPhoneProps = {
  name: string;
  defaultValue?: string;
  label?: string;
  placeholder?: string;
  rules?: Omit<
    RegisterOptions<Record<string, any>, string>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  margin?: "dense" | "normal" | "none";
  variant?: "filled" | "outlined" | "standard";
};

type Props = IMask.AnyMaskedOptions & {
  error: any;
  margin: "dense" | "normal" | "none";
  variant: "filled" | "outlined" | "standard";
};

const MaskedTextField = IMaskMixin<Props>(
  ({ error, margin, variant, inputRef, defaultValue, ...otherProps }: any) => (
    <TextField
      fullWidth
      {...otherProps}
      sx={{ p: 0 }}
      inputRef={inputRef}
      value={defaultValue}
      variant={variant}
      margin={margin}
      error={!!error}
      helperText={error?.message}
      className="yao-input-override"
    />
  )
);

export function YAOFieldPhone({
  name,
  defaultValue,
  label,
  placeholder = "",
  rules,
  margin = "none",
  variant = "outlined",
}: YAOFieldPhoneProps) {
  const { control } = useFormContext();
  const {
    field: { value, onChange, ...inputProps },
    fieldState: { error },
  } = useController({
    control,
    name,
    defaultValue,
    rules,
  });

  return (
    <MaskedTextField
      {...inputProps}
      id={name}
      label={label}
      mask="(#00) 000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      onAccept={(value: any) => onChange(value)}
      overwrite
      placeholder={placeholder}
      error={error}
      margin={margin}
      variant={variant}
      value={value}
    />
  );
}
