import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";
import { icons } from "@ckeditor/ckeditor5-core";
import { ChangeEvent } from "react";
import customAxios from "src_common/utils/axios";
import axiosLib, { AxiosResponse } from "axios";

class YaoImageCkEditorPlugin extends Plugin {
  init() {
    const editor = this.editor;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    editor.ui.componentFactory.add("yaoImageUpload", () => {
      const button = new ButtonView();
      button.set({
        label: "Upload image",
        icon: icons.image,
        tooltip: true,
      });
      button.on("execute", () => {
        const config = this.editor.config.get("yaoImageUpload");
        if (config?.fileRef?.current) {
          config.fileRef.current.click();
        } else {
          console.error("fail");
        }
      });

      const config = this.editor.config.get("yaoImageUpload");
      if (config?.fileRef?.current) {
        config.fileRef.current.addEventListener(
          "change",
          (event: ChangeEvent<HTMLInputElement>) => {
            if (!event.target.files?.length) {
              return;
            }
            const file = event.target.files.item(0);
            if (!file) {
              return;
            }
            const fileMb = (file.size || 0) / 1024 ** 2;
            if (fileMb >= 2) {
              alert("Please select a file less than 2MB.");
              return;
            }
            (async () => {
              try {
                // @maicon finalizar o upload e colocar a url pública
                // const uploadUrl: AxiosResponse<{
                //   url: string;
                //   fields: {
                //     Key: string;
                //     bucket: string;
                //     "X-Amz-Algorithm": string;
                //     "X-Amz-Credential": string;
                //     "X-Amz-Date": string;
                //     "X-Amz-Security-Token": string;
                //     Policy: string;
                //     "X-Amz-Signature": string;
                //   };
                // }> = await customAxios.post(`/uploads`, {
                //   extension: file.name.split(".").pop()?.toLowerCase(),
                // });
                // if (!uploadUrl || !uploadUrl.data) {
                //   throw new Error("Try again");
                // }
                // const formData = new FormData();
                // formData.append('Content-Type', file.type);
                // Object.entries(uploadUrl.data.fields).forEach(([k, v]) => {
                //   formData.append(k, v);
                // });
                // formData.append("file", file);
                // await axiosLib.post(uploadUrl.data.url, formData, {
                //   headers: { "Content-Type": "multipart/form-data" },
                // });

                // editor.execute("insertImage", {
                //   source: '',
                // });
              } catch (err) {
                alert(err.message);
              }
            })();
          }
        );
      }

      return button;
    });
  }
}

export default YaoImageCkEditorPlugin;
