import { useCallback, useEffect, useState } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
  Box,
  Chip,
  CircularProgress,
  Drawer,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { formatError } from "src_common/utils/misc";
import {
  ContactFile,
  contactIsFileExpiring,
  ContactType,
  deleteContactFile,
  getContact,
  getContacts,
} from "src_lawfirm/api/contacts";
import { Contact } from "../../api/contacts";
import Iconify from "src_common/components/Iconify";
import { getMatters, Matter } from "../../api/matters";
import { useAPI } from "src_common/hooks/useAPI";
import { useNavigate } from "react-router";
import { useContacts } from "./useContacts";
import palette from "src_common/theme/palette";
import { ContactUploadDocuments } from "./DocumentEditor/ContactUploadDocuments";
import { fDate, fDateWithPattern } from "src_common/utils/formatTime";
import { useDownloadFileFromS3 } from "src_common/components/yao-form-fields";
import ConfirmDialog from "src_common/components/dialog/ConfirmDialog";
import { ContactEditDocument } from "./DocumentEditor/ContactEditDocument";

type InputProps = {
  id: string;
  view_only?: boolean;
};

const ContactCard = NiceModal.create<InputProps>(
  ({ id, view_only = false }) => {
    const modal = useModal();
    const navigate = useNavigate();
    const contactsApi = useContacts();
    const mattersAPI = useAPI(getMatters);
    const employeesAPI = useAPI(getContacts);
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState<boolean>(true);
    const [contact, setContact] = useState<Contact>();
    const { directDownload } = useDownloadFileFromS3();

    useEffect(() => {
      mattersAPI.invoke({ contact: id });
      (async () => {
        try {
          const data = await getContact(id);
          setContact(data);
          setLoading(false);
          if (data.type === ContactType.Company) {
            employeesAPI.invoke({
              company: data._id,
            });
          }
        } catch (e) {
          enqueueSnackbar(formatError(e), { variant: "error" });
        } finally {
          setLoading(false);
        }
      })();
    }, []);

    const handleCloseForm = useCallback(() => {
      modal.hide();
      modal.remove();
    }, [modal]);

    const handleEditForm = useCallback(() => {
      contactsApi.editDialog(contact as Contact);
      handleCloseForm();
    }, [contact]);

    const renderLoading = () => {
      if (!loading) return null;
      return (
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      );
    };

    const handleDocumentUploaded = (files: ContactFile[]) => {
      if (!Array.isArray(files) || !files.length) {
        return;
      }
      setContact((old) => {
        if (!old) {
          return old;
        }
        const { contact_files = [], ...rest } = old;
        return {
          ...rest,
          contact_files: [...contact_files, ...files],
        };
      });
    };

    const handleDocumentOpen = async (file: ContactFile) => {
      enqueueSnackbar(
        "The file will be opened in another tab in a few seconds",
        {
          variant: "info",
        }
      );
      await directDownload(file.key);
    };

    const handleDocumentEdit = (file: ContactFile) => {
      NiceModal.show<ContactFile>(ContactEditDocument, {
        file,
      }).then((updated) => {
        if (!!updated) {
          setContact((old) => {
            if (!old) {
              return old;
            }
            const { contact_files = [], ...rest } = old;
            return {
              ...rest,
              contact_files: contact_files.map((f) => {
                if (f._id === file._id) {
                  return { ...f, ...updated };
                }
                return f;
              }),
            };
          });
        }
      });
    };

    const handleDocumentRemove = (file: ContactFile) => {
      NiceModal.show(ConfirmDialog, {
        title: "Are you sure you want to delete this document?",
        description: `${file.name} will be deleted, you can't undo it!`,
      }).then(async () => {
        try {
          await deleteContactFile(file._id);
          setContact((old) => {
            if (!old) {
              return old;
            }
            const { contact_files = [], ...rest } = old;
            return {
              ...rest,
              contact_files: contact_files.filter((f) => f._id !== file._id),
            };
          });
        } catch (error) {
          enqueueSnackbar(formatError(error), { variant: "error" });
        }
      });
    };

    const renderCardData = () => {
      if (loading || !contact) return null;
      const isCompany = contact.type === ContactType.Company;
      let name = "";
      if (isCompany) {
        name = contact.company_name;
      } else {
        name = contact.first_name;
        if (contact.middle_name?.length > 0) {
          name += ` ${contact.middle_name}`;
        }
        if (contact.last_name?.length > 0) {
          name += ` ${contact.last_name}`;
        }
      }
      let address = "";
      if (contact?.address?.address_line_1?.length > 0) {
        address += contact.address.address_line_1;
      }
      if (contact?.address?.address_line_2?.length > 0) {
        address += ` ${contact.address.address_line_2}`;
      }
      if (contact?.address?.city?.length > 0) {
        address += ` - ${contact.address.city}`;
      }
      if (contact?.address?.postal_code?.length > 0) {
        address += ` - ${contact.address.postal_code}`;
      }
      return (
        <Stack direction="column" spacing={2}>
          <Stack direction="row">
            <Iconify
              icon="eva:close-fill"
              onClick={handleCloseForm}
              sx={{ cursor: "pointer" }}
              width={16}
            />
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              fontSize={18}
              fontWeight={600}
              color={palette.yao.primary[2]}
              sx={{ flexGrow: 1, flexShrink: 1 }}
            >
              {name}
            </Typography>
            {!view_only && (
              <Iconify
                icon="tabler:pencil"
                onClick={handleEditForm}
                color={palette.yao.grey[6]}
                sx={{ cursor: "pointer" }}
                width={22}
              />
            )}
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Iconify
              icon="eva:email-outline"
              color={palette.yao.grey[6]}
              fontSize={22}
            />
            <Typography
              fontSize={13}
              fontWeight={400}
              color={palette.yao.primary[1]}
            >
              {contact.email?.length > 0 ? contact.email : "No data"}
            </Typography>
          </Stack>
          {Array.isArray(contact.cc_emails) && contact.cc_emails.length > 0 ? (
            <Stack direction="column" spacing={1} alignItems="flex-start">
              <Typography
                color={palette.yao.grey[7]}
                fontSize={11}
                fontWeight={400}
                mb={0}
              >
                Other Emails:
              </Typography>
              <Typography
                fontSize={11}
                fontWeight={400}
                color={palette.yao.primary[1]}
                mt={0}
              >
                {contact.cc_emails.join(", ")}
              </Typography>
            </Stack>
          ) : null}
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ cursor: "default" }}
          >
            <Iconify
              icon="emojione-monotone:mobile-phone"
              color={palette.yao.grey[6]}
              fontSize={22}
            />
            <Typography
              title="Mobile"
              fontSize={13}
              fontWeight={400}
              color={palette.yao.primary[1]}
            >
              {contact.mobile_phone?.length > 0
                ? contact.mobile_phone
                : "No data"}
            </Typography>
            <Iconify
              icon="eva:phone-outline"
              color={palette.yao.grey[6]}
              fontSize={22}
            />
            <Typography
              title="Home"
              fontSize={13}
              fontWeight={400}
              color={palette.yao.primary[1]}
            >
              {contact.home_phone?.length > 0 ? contact.home_phone : "No data"}
            </Typography>
            <Iconify
              icon="carbon:phone-ip"
              color={palette.yao.grey[6]}
              fontSize={22}
            />
            <Typography
              title="Work"
              fontSize={13}
              fontWeight={400}
              color={palette.yao.primary[1]}
            >
              {contact.work_phone?.length > 0 ? contact.work_phone : "No data"}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Iconify
              icon="eva:pin-outline"
              color={palette.yao.grey[6]}
              fontSize={22}
            />
            <Typography>{address?.length > 0 ? address : "No data"}</Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Iconify
              icon="prime:user"
              color={palette.yao.grey[6]}
              fontSize={22}
            />
            <Typography>
              {`Contact created on ${fDate(contact.created_at)} `}
              {contact?.author?.name && `by ${contact.author.name}`}
            </Typography>
          </Stack>
          {!isCompany && contact.company && (
            <Stack direction="row" spacing={1} alignItems="center">
              <Iconify
                icon="foundation:torso-business"
                color={palette.yao.grey[6]}
                fontSize={22}
              />
              <Typography>{contact.company.company_name}</Typography>
            </Stack>
          )}
          {(contact.tags || []).length > 0 && (
            <Stack
              direction="row"
              spacing={0}
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="flex-start"
              alignContent="flex-start"
              alignItems="flex-start"
              paddingTop={3}
            >
              {contact.tags.map((t: string, i: number) => {
                return (
                  <Chip
                    key={t}
                    label={t}
                    size="small"
                    sx={{
                      backgroundColor: "transparent",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: palette.yao.grey[6],
                      borderRadius: "4px",
                      color: palette.yao.primary[2],
                      fontSize: "11px",
                      fontWeight: 400,
                      margin: 0.325,
                    }}
                  />
                );
              })}
            </Stack>
          )}
          <Stack direction="column" spacing={1} paddingTop={3}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                textAlign="left"
                textTransform="uppercase"
                color={palette.yao.grey[7]}
                fontSize={11}
                fontWeight={400}
              >
                Documents
              </Typography>
              <Box flexGrow={1} flexShrink={1}>
                <hr
                  style={{
                    border: "none",
                    height: "1px",
                    backgroundColor: palette.yao.grey[4],
                  }}
                />
              </Box>
            </Stack>
            <ContactUploadDocuments
              contactId={contact._id}
              onUpload={handleDocumentUploaded}
            />
            {!Array.isArray(contact?.contact_files) ? null : (
              <Stack direction="column" spacing={1}>
                {contact.contact_files.map((file) => {
                  const expiration = contactIsFileExpiring(
                    file.expiration_date
                  );
                  return (
                    <Stack
                      direction="row"
                      spacing={1}
                      width="100%"
                      justifyContent="center"
                      alignItems="center"
                      key={file._id}
                    >
                      <Box flexGrow={1} flexShrink={1}>
                        <Typography color={expiration.color}>
                          {expiration.expired ? (
                            <Box
                              sx={{
                                borderWidth: "1px",
                                borderStyle: "solid",
                                borderColor: expiration.color,
                                borderRadius: "3px",
                                height: "20px",
                                padding: "2px",
                                display: "inline-block",
                                marginRight: "5px",
                              }}
                            >
                              <Typography
                                color={expiration.color}
                                fontSize={10}
                              >
                                expired
                              </Typography>
                            </Box>
                          ) : null}
                          {file.name}{" "}
                        </Typography>
                        <Typography variant="caption">
                          Expiration:{" "}
                          {!file.expiration_date
                            ? "not provided"
                            : fDateWithPattern(
                                file.expiration_date,
                                "dd/MM/yyyy"
                              )}
                        </Typography>
                      </Box>
                      <Tooltip title="Open file" placement="left">
                        <IconButton
                          disableRipple
                          onClick={() => {
                            handleDocumentOpen(file);
                          }}
                        >
                          <Iconify
                            icon="tabler:external-link"
                            color={palette.yao.grey[6]}
                            sx={{ cursor: "pointer" }}
                            width={18}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit file" placement="left">
                        <IconButton
                          disableRipple
                          onClick={() => {
                            handleDocumentEdit(file);
                          }}
                        >
                          <Iconify
                            icon="tabler:pencil"
                            color={palette.yao.grey[6]}
                            sx={{ cursor: "pointer" }}
                            width={18}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Remove file" placement="left">
                        <IconButton
                          disableRipple
                          onClick={() => {
                            handleDocumentRemove(file);
                          }}
                        >
                          <Iconify
                            icon="tabler:trash"
                            color={palette.yao.grey[6]}
                            sx={{ cursor: "pointer" }}
                            width={18}
                          />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  );
                })}
              </Stack>
            )}
          </Stack>

          <Stack direction="column" spacing={1} paddingTop={3}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                textAlign="left"
                textTransform="uppercase"
                color={palette.yao.grey[7]}
                fontSize={11}
                fontWeight={400}
              >
                Matters
              </Typography>
              <Box flexGrow={1} flexShrink={1}>
                <hr
                  style={{
                    border: "none",
                    height: "1px",
                    backgroundColor: palette.yao.grey[4],
                  }}
                />
              </Box>
            </Stack>
            {!mattersAPI.error &&
            !mattersAPI.loading &&
            Array.isArray(mattersAPI.data) &&
            mattersAPI.data.length > 0 ? (
              mattersAPI.data.map((matter: Matter) => (
                <Stack
                  direction="column"
                  key={matter._id}
                  sx={{
                    "&&": {
                      borderRadius: 0,
                      borderBottomWidth: "1px",
                      borderBottomColor: palette.yao.primary[4],
                      borderBottomStyle: "solid",
                    },
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/matters/${matter._id}/overview`);
                    handleCloseForm();
                  }}
                >
                  <Stack direction="row" spacing={1} width="100%">
                    <Typography
                      textAlign="left"
                      color={palette.yao.primary[2]}
                      fontSize={13}
                      lineHeight="24px"
                      fontWeight={600}
                    >
                      {matter.case_name}
                    </Typography>
                    <Box
                      sx={{
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: palette.yao.grey[5],
                        borderRadius: "3px",
                        height: "20px",
                        padding: "2px",
                        marginTop: "-4px!important",
                        marginLeft: "4px!important",
                      }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography color="#be6cda" fontSize={10}>
                        {matter.status}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={1}
                    width="100%"
                    alignItems="center"
                  >
                    <Typography
                      color={palette.yao.primary[2]}
                      fontSize="12px"
                      lineHeight="32px"
                      fontWeight={400}
                    >
                      {matter.responsible_lawyer?.name}
                    </Typography>
                  </Stack>
                </Stack>
              ))
            ) : mattersAPI.loading ? (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress size={10} />
              </Stack>
            ) : (
              <Typography variant="overline" color={palette.yao.grey[6]}>
                No matter found
              </Typography>
            )}
          </Stack>
          {isCompany && (
            <Stack direction="column" spacing={1} paddingTop={3}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  textAlign="left"
                  textTransform="uppercase"
                  color={palette.yao.grey[7]}
                  fontSize={11}
                  fontWeight={400}
                >
                  Employees
                </Typography>
                <Box flexGrow={1} flexShrink={1}>
                  <hr
                    style={{
                      border: "none",
                      height: "1px",
                      backgroundColor: palette.yao.grey[4],
                    }}
                  />
                </Box>
              </Stack>
              {!employeesAPI.error &&
              !employeesAPI.loading &&
              Array.isArray(employeesAPI.data) &&
              employeesAPI.data.length > 0 ? (
                employeesAPI.data.map((contact: Contact) => (
                  <Stack
                    direction="column"
                    key={contact._id}
                    sx={{
                      "&&": {
                        borderRadius: 0,
                        borderBottomWidth: "1px",
                        borderBottomColor: palette.yao.primary[4],
                        borderBottomStyle: "solid",
                      },
                    }}
                  >
                    <Stack direction="row" spacing={1} width="100%">
                      <Typography
                        textAlign="left"
                        color={palette.yao.primary[2]}
                        fontSize={13}
                        lineHeight="24px"
                        fontWeight={600}
                      >
                        {contact.first_name} {contact.last_name}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={1}
                      width="100%"
                      alignItems="center"
                    >
                      <Typography
                        color={palette.yao.primary[2]}
                        fontSize="12px"
                        lineHeight="32px"
                        fontWeight={400}
                      >
                        {contact.email} - {contact.mobile_phone}
                      </Typography>
                    </Stack>
                  </Stack>
                ))
              ) : employeesAPI.loading ? (
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress size={10} />
                </Stack>
              ) : (
                <Typography variant="overline" color={palette.yao.grey[6]}>
                  No employees found
                </Typography>
              )}
            </Stack>
          )}
        </Stack>
      );
    };

    return (
      <Drawer anchor="right" open={modal.visible} onClose={handleCloseForm}>
        <Stack
          width={450}
          height="100%"
          padding={2}
          role="presentation"
          direction="column"
          spacing={1}
          justifyContent="flex-start"
        >
          {renderLoading()}
          {renderCardData()}
        </Stack>
      </Drawer>
    );
  }
);

export default ContactCard;
