import { Theme } from "@mui/material/styles";
import palette from "../palette";

export default function Timeline(theme: Theme) {
  return {
    MuiTimeline: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },

    MuiTimelineDot: {
      styleOverrides: {
        root: {
          width: 12,
          height: 12,
          boxShadow: "none",
        },
        outlined: {
          borderWidth: 1,
          borderColor: palette.yao.grey[4],
        },
        filled: {
          backgroundColor: palette.yao.secondary[1],
        },
      },
    },

    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          width: 1,
          backgroundColor: palette.yao.grey[4],
        },
      },
    },

    MuiTimelineItem: {
      styleOverrides: {
        root: {
          "&:before": {
            display: "none",
          },
        },
      },
    },
  };
}
