import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Typography } from "@mui/material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
} from "@mui/material";
import { useMemo, useRef, useState } from "react";
import YaoTable from "src_common/components/yao-table";
import { CellProps, Column } from "src_common/components/yao-table/types";
import { fCapitalizeFirstLetter } from "src_common/utils/formatText";
import { Contact, ContactType } from "src_lawfirm/api/contacts";

type DialogProps = {
  contacts: Contact[];
};

const ContactConflictDialog = NiceModal.create<DialogProps>(
  ({ contacts = [] }) => {
    const modal = useModal();

    const [reasons, setReasons] = useState<
      { _id: string; description: string }[]
    >(contacts.map((ctc) => ({ _id: ctc._id, description: "" })));


    const columns = useMemo<Column[]>(() => {
      return [
        {
          id: "type",
          label: "type",
        },
        {
          id: "conflict_type",
          label: "Conflict Type",
          CellComponent: ({ data }: CellProps<string>) => {
            return (
              <Typography
                textAlign="center"
                noWrap={true}
                maxWidth={300}
                title={fCapitalizeFirstLetter(data)}
              >
                {fCapitalizeFirstLetter(data)}
              </Typography>
            );
          },
        },
        {
          id: "name",
          label: "name",
        },
        {
          id: "email",
          label: "email",
        },
        {
          id: "mobile_phone",
          label: "Mobile Phone",
        },
        {
          id: "address.address_line_1",
          label: "address",
        },
        {
          id: "_id",
          label: "reason",
          CellComponent: ({ data }) => {
            const ref = useRef<HTMLInputElement>(null);
            return (
              <Input
                ref={ref}
                placeholder="Reason"
                onBlur={(e) =>
                  setReasons((prev) =>
                    prev.map((reason) =>
                      reason._id === data
                        ? { ...reason, description: e.target.value || "" }
                        : { ...reason }
                    )
                  )
                }
              />
            );
          },
        },
      ];
    }, []);

    const handleSave = (forcedUpdate: boolean) => {
      if (forcedUpdate) {
        modal.resolve({ forcedUpdate: true, reasons });
      } else {
        modal.resolve(false);
      }
      modal.hide();
    };

    return (
      <Dialog
        maxWidth="lg"
        fullWidth
        open={modal.visible}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
        sx={{ p: 0 }}
        scroll="paper"
      >
        <DialogTitle sx={{ p: 1 }}>Contact conflict</DialogTitle>
        <DialogContent sx={{ p: 1 }}>
          <p>
            Found {contacts.length} contacts with the same data, what do you
            want to do?
          </p>
          <YaoTable
            rows={(contacts || []).map((r) => ({
              ...r,
              name:
                r.type === ContactType.Company
                  ? r.company_name
                  : `${r.first_name} ${r.last_name}`,
            }))}
            columns={columns}
          />
        </DialogContent>
        <DialogActions sx={{ p: 1 }}>
          <Button color="secondary" onClick={() => handleSave(false)}>
            Cancel
          </Button>
          <Button
            color="warning"
            sx={{ minWidth: 100 }}
            onClick={() => handleSave(true)}
          >
            Save anyway
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default ContactConflictDialog;
