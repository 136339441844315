import numeral from "numeral";

export function fCurrency(number: string | number, symbol: string) {
  return numeral(number).format(Number.isInteger(number) ? `${symbol}0,0` : `${symbol}0,0.00`);
}

export function fPercent(number: number) {
  return numeral(number / 100).format("0.0%");
}

export function fNumber(number: string | number) {
  return numeral(number).format();
}

export function fShortenNumber(number: string | number) {
  return numeral(number).format("0.00a").replace(".00", "");
}

export function fData(number: string | number) {
  return numeral(number).format("0.0 b");
}

export function fCurrencyWithFormat(
  number: string | number,
  locale = "en-GB",
  currency = "GBP",
  symbol = '£'
): string {
  try {
    const formatter = Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    });
    return formatter.format(Number(number) || 0);
  } catch {
    return fCurrency(number, symbol) || `${symbol}0`;
  }
}
