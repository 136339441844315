import { useFormContext, Controller } from "react-hook-form";
import { TextareaAutosize, TextareaAutosizeProps } from "@mui/material";

type IProps = {
  name: string;
};

type Props = IProps & TextareaAutosizeProps;

export default function RHFTextArea({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextareaAutosize
          aria-label={`${name}`}
          placeholder={other?.placeholder}
          style={{ padding: "10px", borderColor: "rgba(145, 158, 171, 0.16)" }}
          {...field}
          {...other}
        />
      )}
    />
  );
}
