import { createFilterOptions, Stack, Typography } from "@mui/material";
import { HTMLAttributes } from "react";
import Iconify from "src_common/components/Iconify";
import palette from "src_common/theme/palette";
import { Contact } from "src_lawfirm/api/contacts";
import { Matter } from "src_lawfirm/api/matters";
import { contactNameResolver } from "../contacts/useContacts";

export const MatterAutoCompleteFilterHelper = createFilterOptions({
  stringify: (option: Matter) =>
    `${option.number} ${option.case_name} ${(option.clients || [])
      .map((o) => contactNameResolver(o.contact as Contact))
      .join(" ")}`,
});

export const MatterAutoCompleteRenderOptionHelper = (
  props: HTMLAttributes<HTMLLIElement>,
  option: Matter
) => {
  const containerStyle = {
    "&&": {
      borderRadius: 0,
      borderBottomWidth: "1px",
      borderBottomColor: palette.yao.primary[4],
      borderBottomStyle: "solid",
    },
  };
  return (
    <Stack
      {...props}
      direction="column"
      component="li"
      key={option._id}
      sx={containerStyle}
    >
      <Stack direction="row" spacing={1} width="100%">
        <Typography
          textAlign="left"
          color={palette.yao.primary[2]}
          fontSize="12px"
          lineHeight="24px"
          fontWeight={600}
        >
          #{option.number} - {option.case_name}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1} width="100%" alignItems="center">
        <Iconify icon="clarity:user-line" color={palette.yao.grey[6]} />
        <Typography
          variant="caption"
          color={palette.yao.primary[2]}
          fontSize="12px"
          lineHeight="32px"
          fontWeight={400}
        >
          {(option.clients || [])
            .map((o) => contactNameResolver(o.contact as Contact))
            .join(", ")}
        </Typography>
      </Stack>
    </Stack>
  );
};
