import { createContext, Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { getMatter, Matter } from "src_lawfirm/api/matters";

type AddMenuContextProps = {

    matterId: string | undefined;
    setMatterId: Dispatch<SetStateAction<string | undefined>>

    matter: Matter | undefined;

    inMatter: boolean;
    setInMatter: Dispatch<SetStateAction<boolean>>

}

const DEFAULT_VALUES = {
    matterId: undefined,
    setMatterId: () => {},
    matter: undefined,
    inMatter: false,
    setInMatter: () => {},
}

const AddMenuContext = createContext<AddMenuContextProps>(DEFAULT_VALUES);

const AddMenuContextProvider: FC = ({ children }) => {

    const [matterId, setMatterId] = useState<string | undefined>(DEFAULT_VALUES.matterId);
    const [matter, setMatter] = useState<Matter | undefined>(DEFAULT_VALUES.matter);
    const [inMatter, setInMatter] = useState<boolean>(DEFAULT_VALUES.inMatter)

    useEffect(() => {
        if(matterId === undefined){
            setMatter(undefined);
            return;
        }
        (async() => {
            try {
                const response = await getMatter(matterId as string);
                setMatter(response || undefined);
            } catch (e) {
                setMatter(undefined);
            }
        })();
    }, [matterId])

    return (
        <AddMenuContext.Provider value={{
            matterId, setMatterId,
            inMatter, setInMatter,
            matter
        }}>
            {children}
        </AddMenuContext.Provider>
    )
    
}

export { AddMenuContextProvider };
export default AddMenuContext;