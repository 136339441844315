import { path } from "src_common/utils/misc";

const ROOT_AUTH = "/auth";
export const ROOT_HOME = "/home";

export const PATH_AUTH = {
  login: path(ROOT_AUTH, "/login"),
  register: path(ROOT_AUTH, "/register"),
  resetPassword: path(ROOT_AUTH, "/reset-password"),
  newPassword: path(ROOT_AUTH, "/new-password"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page403: "/403",
  page404: "/404",
  page500: "/500",
  components: "/components",
};
