import {ContactFile, ContactFileDto, editContactFile} from "../../../api/contacts";
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {SubmitHandler, useForm} from "react-hook-form";
import {useState} from "react";
import {useSnackbar} from "notistack";
import {
    AppBar,
    Button,
    Chip,
    CircularProgress,
    Dialog, DialogActions,
    DialogContent,
    Grid,
    Stack,
    Toolbar,
    Typography
} from "@mui/material";
import {FormProvider} from "src_common/components/hook-form";
import {YaoFormFieldLabel} from "src_common/components/yao-form/YaoForm";
import {YAOFieldDatePicker, YAOFieldText} from "src_common/components/yao-form-fields";
import {addMonths, addYears} from "date-fns";
import {formatError} from "../../../../src_common/utils/misc";

type InputProps = {
    file: ContactFile
}

type FormProps = {
    file: ContactFileDto
}

export const ContactEditDocument = NiceModal.create<InputProps>(({
                                                                     file
                                                                 }) => {

    const modal = useModal();
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState<boolean>(false);
    const methods = useForm<FormProps>({
        mode: "all", defaultValues: {
            file: {
                name: file.name,
                key: file.key,
                expiration_date: !file.expiration_date ? null : new Date(file.expiration_date)
            }
        }
    })

    const onSubmit: SubmitHandler<FormProps> = async (data) => {
        try {
            setLoading(true)
            const response = await editContactFile(file._id, data.file)
            if(response?.modifiedCount > 0) {
                modal.resolve({
                    ...file,
                    name: data.file.name,
                    expiration_date: data.file.expiration_date,
                })
            }
            modal.hide()
        } catch (error) {
            enqueueSnackbar(formatError(error), {variant: "error"});
        } finally {
            setLoading(false);
        }
    }

    return <Dialog open={modal.visible}
                   maxWidth="md"
                   fullWidth
                   disableEscapeKeyDown={true}
                   TransitionProps={{
                       onExited: () => modal.remove(),
                   }}>
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                    Edit file
                </Typography>
            </Toolbar>
        </AppBar>
        <DialogContent>
            <FormProvider
                methods={methods}
                onSubmit={methods.handleSubmit(onSubmit)}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12} md={8}>
                        <YaoFormFieldLabel
                            name="file.name"
                            label="File name"
                            required
                        />
                        <YAOFieldText
                            name="file.name"
                            type="text"
                            placeholder="Enter the file name"
                            rules={{
                                required: "Field is required",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <YaoFormFieldLabel
                            name="file.expiration_date"
                            label="Expiration date"
                        />
                        <YAOFieldDatePicker
                            name="file.expiration_date"
                            datePickerProps={{
                                views: ["year", "month", "day"],
                            }}
                            defaultValue={null}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
                            <Typography fontSize="small" variant="subtitle2">Suggestions:</Typography>
                            <Chip label="none" size="small" variant="outlined" onClick={() => {
                                methods.setValue("file.expiration_date", null)
                            }}/>
                            <Chip label="1m" size="small" variant="outlined" onClick={() => {
                                methods.setValue("file.expiration_date", addMonths(new Date(), 1))
                            }}/>
                            <Chip label="3m" size="small" variant="outlined" onClick={() => {
                                methods.setValue("file.expiration_date", addMonths(new Date(), 3))
                            }}/>
                            <Chip label="6m" size="small" variant="outlined" onClick={() => {
                                methods.setValue("file.expiration_date", addMonths(new Date(), 6))
                            }}/>
                            <Chip label="1y" size="small" variant="outlined" onClick={() => {
                                methods.setValue("file.expiration_date", addYears(new Date(), 1))
                            }}/>
                        </Stack>
                    </Grid>
                </Grid>
            </FormProvider>
        </DialogContent>
        <DialogActions>
            <Button
                color="secondary"
                onClick={() => modal.hide()}
                disabled={loading}
            >
                Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={() => {
                methods.handleSubmit(onSubmit)()
            }} disabled={loading}>
                {loading ? <><CircularProgress size={10}/> Saving...</> : 'Save'}
            </Button>
        </DialogActions>
    </Dialog>

})