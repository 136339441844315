import { Theme } from "@mui/material/styles";
import palette from "../palette";

export default function Paper(theme: Theme) {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },

      variants: [
        {
          props: { variant: "outlined" },
          style: { borderColor: palette.yao.primary[4] },
        },
      ],

      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
        elevation10: {
          boxShadow: `0px 8px 25px ${palette.yao.grey[8_08]}`,
        },
        rounded: {
          borderRadius: 8,
        },
      },
    },
  };
}
