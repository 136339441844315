import {
  RegisterOptions,
  useController,
  useFormContext,
} from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import React from "react";

type YaoFieldPhoneInputProps = {
  name: string;
  defaultCountry?: string;
  disabled?: boolean;
  placeholder?: string;
  onlyCountries?: string[];
  preferredCountries?: string[];
  excludeCountries?: string[];
  defaultValue?: string;
  label?: string;
  rules?: Omit<
    RegisterOptions<Record<string, any>, string>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  containerStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  dropdownStyle?: React.CSSProperties;
  searchStyle?: React.CSSProperties;
};

export function YaoFieldPhoneInput({
  name,
  defaultCountry = "gb",
  disabled = false,
  defaultValue,
  label,
  rules,
  inputStyle = {},
  containerStyle = {},
  buttonStyle = {},
  dropdownStyle = {},
  searchStyle = {},

  ...props
}: YaoFieldPhoneInputProps) {
  const { control } = useFormContext();
  const {
    field: { value, onChange, ...inputProps },
    fieldState: { error },
  } = useController({
    control,
    name,
    defaultValue,
    rules,
  });

  return (
    <PhoneInput
      {...props}
      country={defaultCountry}
      value={value}
      onChange={(phone) => onChange(phone)}
      disabled={disabled}
      inputStyle={
        !!error ? { borderColor: "red", ...inputStyle } : { ...inputStyle }
      }
      containerStyle={containerStyle}
      buttonStyle={buttonStyle}
      dropdownStyle={dropdownStyle}
      searchStyle={searchStyle}
      enableLongNumbers={true}
    />
  );
}
