import { useState } from "react";
import { useSnackbar } from "notistack";
import { formatError } from "src_common/utils/misc";
import { getMatters, Matter } from "src_lawfirm/api/matters";

type UseMattersHook = {
  loading: boolean;
  endReached: boolean;
  invoke: (
    search?: string,
    limit?: number,
  ) => Promise<void>;
  data: Matter[];
  error: object | null;
};

export const useMatters = (): UseMattersHook => {
  const [data, setData] = useState<Matter[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [endReached, setEndReached] = useState<boolean>(false);
  const [error, setError] = useState<object | null>(null);
  const [lastSearch, setLastSearch] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();

  const invoke = async (
    search = "",
    limit = 50,
  ) => {
    if (loading) {
      return;
    }
    let lastRow = data.length > 0 ? data[data.length - 1]._id : "";
    if (search !== lastSearch) {
      setLastSearch(search);
      setData([]);
      setEndReached(false);
      setError(null);
      lastRow = "";
    }

    setLoading(true);
    try {
      const response = await getMatters({
        search,
        limit,
        last_id: lastRow,
      });
      const converted = response.map((r) => ({
        ...r,
      }));
      setData((old) => [...old, ...converted]);
      setEndReached(!response.length);
      setError(null);
    } catch (e) {
      setError(e);
      enqueueSnackbar(formatError(e), { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    endReached,
    invoke,
    data,
    error,
  };
};
