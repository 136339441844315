import {createContext, FC, useEffect} from "react"
import {useInfiniteQuery, useMutation, UseMutationResult, useQuery, useQueryClient} from '@tanstack/react-query'
import {UseInfiniteQueryResult, UseQueryResult} from "@tanstack/react-query/src/types";
import useAuth from "../../hooks/useAuth";
import axios from "src_common/utils/axios";
import {AxiosResponse} from "axios";
import {formatError} from "../../utils/misc";
import {useSnackbar} from "notistack";

export enum HistoryStatus {
    PLAIN = 'PLAIN', UNREAD = 'UNREAD', READ = 'READ',
}

export enum HistoryType {
    TASK = 'TASK', PAYMENT = 'PAYMENT', FORM = 'FORM', WORKFLOW = 'WORKFLOW',
}

export const HistoryTypeLabel: Record<HistoryType, string> = {
    [HistoryType.TASK]: 'Task',
    [HistoryType.PAYMENT]: 'Payment Notification',
    [HistoryType.FORM]: 'Form',
    [HistoryType.WORKFLOW]: 'Workflow',
}

export const HistoryTypeIcon: Record<HistoryType, string> = {
    [HistoryType.TASK]: 'task',
    [HistoryType.PAYMENT]: 'money',
    [HistoryType.FORM]: 'form',
    [HistoryType.WORKFLOW]: 'workflow',
}
export type HistoryItem = {
    _id: string;
    description: string;
    status: HistoryStatus;
    type: HistoryType;
    law_firm: string;
    matter: {
        _id: string;
        case_name: string;
        number: number;
    };
    attorney: string;
    reference: string;
    created_at: Date;
    updated_at: Date;
}

type RefreshKind = 'all' | 'badge' | 'list'

type NotificationContextProps = {
    badge: UseQueryResult<number> | undefined;
    notifications: UseInfiniteQueryResult<HistoryItem[]> | undefined;
    maskNotificationAsRead: UseMutationResult<boolean, unknown, string, unknown> | undefined;
    refresh: (kind?: RefreshKind) => void;
}

const DEFAULT_VALUES = {
    badge: undefined, notifications: undefined, maskNotificationAsRead: undefined, refresh: (kind?: RefreshKind) => {}
}

const fetchBadgeCount = async (): Promise<number> => {
    try {
        const response: AxiosResponse<{ count: number; }> = await axios.get('/matters/notifications/count')
        return response?.data?.count || 0;
    } catch (e) {
        return 0;
    }
}

const fetchNotifications = async (page : number): Promise<HistoryItem[]> => {
    try {
        const response: AxiosResponse<HistoryItem[]> = await axios.get('/matters/notifications', {
            params: {
                page, size: 10
            }
        });
        return response?.data || [];
    } catch (e) {
        throw new Error(formatError(e));
    }
}

const setNotificationAsRead = async (id: string): Promise<boolean> => {
    try {
        const response: AxiosResponse<{ success: boolean }> = await axios.patch(`/matters/notifications/${id}`);
        return response?.data?.success || false;
    } catch (e) {
        return false;
    }
}

const NotificationContext = createContext<NotificationContextProps>(DEFAULT_VALUES);

const NotificationContextProvider: FC = ({children}) => {
    const {user} = useAuth()
    const queryClient = useQueryClient()
    const { enqueueSnackbar } = useSnackbar();

    const badge = useQuery(['notification-badge', user?._id], () => fetchBadgeCount(), {
        enabled: !!user?._id,
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchIntervalInBackground: true,
        refetchInterval: 3 * 60_000,
    })

    const notifications = useInfiniteQuery(['notification-list', user?._id], ({pageParam}) => fetchNotifications(pageParam), {
        enabled: !!user?._id,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
        refetchInterval: 3 * 60_000,
        getNextPageParam: (lastPage, allPages) => Array.isArray(lastPage) && !lastPage.length ? undefined : allPages.length,
    })

    const maskNotificationAsRead = useMutation(
        (id: string) => setNotificationAsRead(id),
        {
            onSuccess: (data, variables, context) => {
                if(data) {
                    refresh();
                } else {
                    enqueueSnackbar('Failed to update notification status, try again later', { variant: "error" });
                }
            }
        }
    )

    const refresh = (kind: RefreshKind = 'all') => {
        if(['all', 'badge'].indexOf(kind) !== -1) {
            queryClient.invalidateQueries(['notification-badge', user?._id])
        }
        if(['all', 'list'].indexOf(kind) !== -1) {
            queryClient.invalidateQueries(['notification-list', user?._id])
        }
    }

    return <NotificationContext.Provider value={{
        badge, notifications, maskNotificationAsRead, refresh
    }}>
        {children}
    </NotificationContext.Provider>
}

export {NotificationContext, NotificationContextProvider}