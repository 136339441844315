import {Correspondence, CorrespondenceCategory,} from "src_lawfirm/api/correspondence";
import {getAttachmentIconName} from "src_common/utils/fileHelpers";
import CustomIcon from "src_common/components/custom-icon";
import {WorkType} from "src_lawfirm/api/time-entries";
import FolderIcon from "@mui/icons-material/Folder";

export const isFolder = (c?: Correspondence) =>
  !!c && c.category === CorrespondenceCategory.FOLDER;
export const isFolderEmpty = (c?: Correspondence) =>
  isFolder(c) && c?.children_count !== undefined && c.children_count <= 0;
export const isNote = (c?: Correspondence) =>
  c && c.category === CorrespondenceCategory.NOTE;
export const isCall = (c?: Correspondence) =>
  c && c.category === CorrespondenceCategory.CALL;
export const isEmail = (c?: Correspondence) =>
  c && c.category === CorrespondenceCategory.EMAIL;
export const isFile = (c?: Correspondence) =>
  c &&
  c.category !== CorrespondenceCategory.NOTE &&
  c.category !== CorrespondenceCategory.CALL &&
  c.category !== CorrespondenceCategory.EMAIL &&
  c.category !== CorrespondenceCategory.FOLDER;
export const isDocument = (c?: Correspondence) =>
    c && c.category === CorrespondenceCategory.DOCUMENT;

export const getCorrespondenceIcon = (
  correspondence: Correspondence,
  className: string = "",
  color: string = "",
  sx: { [key: string]: string | number } = {}
) => {
  if(correspondence.category === CorrespondenceCategory.FOLDER){
    return <FolderIcon color="primary" sx={{ height: "27px", width: "27px", ...sx }} />
  }

  let name = "";
  switch (correspondence.category) {
    case CorrespondenceCategory.DOCUMENT:
      name = getAttachmentIconName(correspondence.key?.split(".").pop()?.toLocaleLowerCase()) || ''
      break;
    case CorrespondenceCategory.CALL:
      name = "phone";
      break;
    case CorrespondenceCategory.NOTE:
      name = "note";
      break;
    case CorrespondenceCategory.EMAIL:
      name = "mail";
      break;
  }

  if (!name.length) {
    return <img src="/assets/icons/correspondences/ic_document.svg" alt="" />;
  }
  return (
    <CustomIcon
      className={className}
      color={color}
      name={name}
      sx={{ height: "27px", width: "27px", ...sx }}
    />
  );
};

export const CORRESPONDENCE_LAST_FOLDER_KEY = "corlf_";

export const extractEmailFromString = (text: string = "") => {
  // eslint-disable-next-line no-control-regex
  const email = text.match(/(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi);

  return email?.length ? email[0] : "";
}

type IDefaults = {
  workType?: WorkType | string; description?: string;
}

export const defineTimeEntryDefaults = (cor: Correspondence): IDefaults => {
  switch (cor.category) {
    case CorrespondenceCategory.EMAIL:
      return {
        workType: WorkType.Email, description: `Email Re: ${cor.title}`
      };
    case CorrespondenceCategory.DOCUMENT:
      return {
        workType: WorkType.Drafting, description: `Drafting ${cor.title}`
      };
    case CorrespondenceCategory.CALL:
      return {
        workType: WorkType.Telephone, description: `Call with ${cor.case_contact?.display_name || ""}`
      };
    case CorrespondenceCategory.NOTE:
      return {
        workType: WorkType.Reviewing, description: `Note by ${cor.author?.name || ""}`
      };
    default:
      return {
        workType: "", description: ""
      };
  }
}
