import {addContactFile, ContactFileDto} from "../../../api/contacts";
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {
    AppBar,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Stack,
    Toolbar,
    Typography
} from "@mui/material";
import {SubmitHandler, useFieldArray, useForm} from "react-hook-form";
import {YaoFormFieldLabel} from "src_common/components/yao-form/YaoForm";
import {YAOFieldDatePicker, YAOFieldText} from "src_common/components/yao-form-fields";
import {FormProvider} from "src_common/components/hook-form";
import {addMonths, addYears} from "date-fns";
import {useState} from "react";
import {formatError} from "../../../../src_common/utils/misc";
import {useSnackbar} from "notistack";


export type ContactDocumentUploaded = { key: string; title: string }

type InputProps = {
    contactId: string
    files: ContactDocumentUploaded[]
}

type FormProps = {
    files: ContactFileDto[]
}

export const ContactAddDocuments = NiceModal.create<InputProps>(({
                                                                     contactId,
                                                                     files
                                                                 }) => {
    const modal = useModal();
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState<boolean>(false);
    const methods = useForm<FormProps>({
        mode: "all",
        defaultValues: {
            files: files.map(f => ({name: f.title, key: f.key, expiration_date: null}))
        }
    })
    const formFields = useFieldArray({
        name: 'files',
        control: methods.control,
    })

    const onSubmit: SubmitHandler<FormProps> = async (data) => {
        try {
            setLoading(true)
            const response = Promise.all(data.files.map(f => addContactFile(contactId, f)))
            modal.resolve(response)
            modal.hide()
        } catch (error) {
            enqueueSnackbar(formatError(error), {variant: "error"});
        } finally {
            setLoading(false);
        }
    }

    const plural = files.length !== 1;

    return <Dialog open={modal.visible}
                   maxWidth="md"
                   fullWidth
                   disableEscapeKeyDown={true}
                   TransitionProps={{
                       onExited: () => modal.remove(),
                   }}>
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                    {files.length} file{plural ? 's' : ''} selected
                </Typography>
            </Toolbar>
        </AppBar>
        <DialogContent>
            <Typography variant="subtitle1">Please review {plural ? 'each' : 'the'} file{plural ? 's' : ''} before
                save</Typography>
            <FormProvider
                methods={methods}
                onSubmit={methods.handleSubmit(onSubmit)}
            >
                {formFields.fields.map((field, idx) => (
                    <Grid container spacing={1} key={field.id}>
                        <Grid item xs={12} md={8}>
                            <YaoFormFieldLabel
                                name={`files.${idx}.name`}
                                label="File name"
                                required
                            />
                            <YAOFieldText
                                name={`files.${idx}.name`}
                                type="text"
                                placeholder="Enter the file name"
                                rules={{
                                    required: "Field is required",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <YaoFormFieldLabel
                                name={`files.${idx}.expiration_date`}
                                label="Expiration date"
                            />
                            <YAOFieldDatePicker
                                name={`files.${idx}.expiration_date`}
                                datePickerProps={{
                                    views: ["year", "month", "day"],
                                }}
                                defaultValue={null}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
                                <Typography fontSize="small" variant="subtitle2">Suggestions:</Typography>
                                <Chip label="none" size="small" variant="outlined" onClick={() => {
                                    methods.setValue(`files.${idx}.expiration_date`, null)
                                }}/>
                                <Chip label="1m" size="small" variant="outlined" onClick={() => {
                                    methods.setValue(`files.${idx}.expiration_date`, addMonths(new Date(), 1))
                                }}/>
                                <Chip label="3m" size="small" variant="outlined" onClick={() => {
                                    methods.setValue(`files.${idx}.expiration_date`, addMonths(new Date(), 3))
                                }}/>
                                <Chip label="6m" size="small" variant="outlined" onClick={() => {
                                    methods.setValue(`files.${idx}.expiration_date`, addMonths(new Date(), 6))
                                }}/>
                                <Chip label="1y" size="small" variant="outlined" onClick={() => {
                                    methods.setValue(`files.${idx}.expiration_date`, addYears(new Date(), 1))
                                }}/>
                            </Stack>
                        </Grid>
                    </Grid>
                ))}
            </FormProvider>

        </DialogContent>
        <DialogActions>
            <Button
                color="secondary"
                onClick={() => modal.hide()}
                disabled={loading}
            >
                Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={() => {
                methods.handleSubmit(onSubmit)()
            }} disabled={loading}>
                {loading ? <><CircularProgress size={10}/> Saving...</> : 'Save'}
            </Button>
        </DialogActions>
    </Dialog>

});