import { Helmet } from "react-helmet-async";
import {forwardRef, ReactNode, useMemo} from "react";
import { Box, BoxProps } from "@mui/material";

interface Props extends BoxProps {
  children: ReactNode;
  meta?: ReactNode;
  title: string;
  suffix?: string;
}

const Page = forwardRef<HTMLDivElement, Props>(
  ({ children, title = "", meta,  suffix = '', ...other }, ref) =>  {
      const pageTitle = useMemo(() => `${title || ''} | ${suffix?.length > 0 ? suffix : 'YAO'}`, [title, suffix])
      return (
          <>
              <Helmet>
                  <title>{pageTitle}</title>
                  {meta}
              </Helmet>

              <Box ref={ref} {...other}>
                  {children}
              </Box>
          </>
      )
  }
);

export default Page;
