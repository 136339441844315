import {Backdrop, Button, CircularProgress, Grid, InputAdornment, Stack, Typography,} from "@mui/material";
import {useSnackbar} from "notistack";
import {FC, useContext, useEffect, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {FormProvider} from "src_common/components/hook-form";
import {YAOFieldAutocomplete, YAOFieldDatePicker, YAOFieldText,} from "src_common/components/yao-form-fields";
import {YAOFieldCurrency} from "src_common/components/yao-form-fields/YAOFieldCurrency";
import {YaoFormFieldLabel} from "src_common/components/yao-form/YaoForm";
import {useAPI} from "src_common/hooks/useAPI";
import useAuth from "src_common/hooks/useAuth";
import {formatError} from "src_common/utils/misc";
import {getAttorneys} from "src_lawfirm/api/attorneys";
import {Matter} from "src_lawfirm/api/matters";
import {
  createTimeEntry,
  TimeEntry,
  TimeEntryFormData,
  updateTimeEntry,
  WorkType,
  WorkTypeLabel,
} from "src_lawfirm/api/time-entries";
import _ from "lodash";
import {Attorney} from "../../../api/attorneys";
import {LawFirmContext} from "../../law-firm/LawFirmContext";

type TimeEntryFormProps = {
  type: "create" | "update";
  timeEntry?: TimeEntry;
  matter: Matter;
  workType?: string;
  description?: string;
  correspondence?: string;
  onFinish?: (timeEntry: TimeEntry) => void;
  onCancel?: () => void;
};

type TimeEntryFormModel = {
  assignee: {
    label: string;
    value: string;
  };
  work_type: {
    label: string;
    value: string;
  };
  date: Date | null;
  duration_minutes: number;
  description: string;
  rate: number;
  estimated_billable: number;
  correspondence: string;
};

const UNIT_MINUTES = 6;
const MINUTES_IN_HOUR = 60;

function calculateUnits(duration_minutes: number): number {
  return Math.ceil(duration_minutes / UNIT_MINUTES);
}

function calculateBillable(duration_minutes: number, rate: number): number {
  const units = calculateUnits(duration_minutes);
  return (units * UNIT_MINUTES * rate) / MINUTES_IN_HOUR;
}

const TimeEntryForm: FC<TimeEntryFormProps> = ({
  type,
  timeEntry,
  matter,
  workType,
  description,
  correspondence,
  onFinish,
  onCancel,
}) => {
  const lawFirmContext = useContext(LawFirmContext);
  const [loader, setLoader] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const attorneysAPI = useAPI(getAttorneys);
  const { user } = useAuth();

  const methods = useForm<TimeEntryFormModel>({
    defaultValues: {
      work_type: {
        label: timeEntry?.work_type || workType || "",
        value: timeEntry?.work_type || workType || "",
      },
      date: timeEntry?.date ? new Date(timeEntry?.date) : new Date(),
      duration_minutes: timeEntry?.duration_minutes,
      description: timeEntry?.description || description || "",
      rate: timeEntry?.work_type === WorkType.NonChargeable ? timeEntry?.rate || 0 : timeEntry?.rate ?? matter?.rate ?? matter?.fee_earner?.rate,
      estimated_billable: 0,
      assignee: undefined,
      correspondence
    },
  });

  const { watch, setValue } = methods;

  useEffect(() => {
    attorneysAPI.invoke(undefined, (data) => {
      const _id: string = timeEntry?.assignee?._id || user?._id || '';
      if(_id.length > 0){
        const found = ((data as Attorney[]) || []).find((a) => `${a._id}` === `${_id}`);
        if (!!found) {
          setValue("assignee", {
            label: `${found.name} ${found.surname}`,
            value: found._id,
            rate: found.rate,
          } as any);
          if(timeEntry?.work_type !== WorkType.NonChargeable) {
            setValue('rate', timeEntry?.rate ?? matter?.rate ?? matter?.fee_earner?.rate ?? found.rate)
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    const subscription = methods.watch((values, { name, type, ...rest }) => {
      if(name === "work_type" && values.work_type?.value === WorkType.NonChargeable) {
        setValue("rate", 0);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch("work_type")])

  useEffect(() => {
    const subscription = methods.watch((values, { name, type, ...rest }) => {
      if (name === "assignee" && _.hasIn(values, "assignee.rate") && !values.rate && timeEntry?.work_type !== WorkType.NonChargeable) {
        setValue(
          "rate",
          Number(
            _.get(
              values,
              "assignee.rate",
              timeEntry?.rate ?? matter?.rate ?? matter?.fee_earner?.rate
            )
          )
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [watch("assignee")]);

  useEffect(() => {
    if (Number(watch("rate")) === 0) {
      setValue("estimated_billable", 0);
    } else {
      const estimatedBillable = calculateBillable(
        Number(watch("duration_minutes")),
        Number(watch("rate"))
      );
      setValue("estimated_billable", estimatedBillable);
    }
  }, [watch("duration_minutes"), watch("rate")]);

  const handleCancel = () => onCancel && onCancel();

  const onSubmit: SubmitHandler<TimeEntryFormModel> = async (data) => {
    try {
      setLoader(true);

      const timeEntryDto: TimeEntryFormData = {
        rate: data.rate || undefined,
        assignee: data.assignee.value,
        matter: matter._id,
        work_type: (data.work_type.value as WorkType) || undefined,
        date: data.date?.toISOString() || "",
        duration_minutes: Number(data.duration_minutes),
        description: data.description,
        correspondence: data.correspondence,
      };

      let response: TimeEntry | null = null;

      switch (type) {
        case "create":
          response = await createTimeEntry(timeEntryDto);
          break;
        case "update":
          response = await updateTimeEntry((timeEntry as TimeEntry)._id, {
            ...timeEntryDto,
            do_not_bill: false,
          });
          break;
        default:
          throw new Error("Invalid type");
      }
      if (response !== null && response !== undefined) {
        onFinish && onFinish(response);
      } else {
        throw new Error("Invalid response");
      }
    } catch (error) {
      enqueueSnackbar(formatError(error), { variant: "error" });
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container rowSpacing={1.5} columnSpacing={2.5}>
          <Grid item xs={12}>
            <Typography variant="h2" align="center">
              {type === "create" ? "Add" : "Update"} Time Entry
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <YaoFormFieldLabel name="assignee" label="Assignee" required />
            <YAOFieldAutocomplete
              name="assignee"
              options={(attorneysAPI?.data || []).map((a) => ({
                label: `${a.name} ${a.surname}`,
                value: a._id,
                rate: a.rate,
              }))}
              placeholder="Choose assignee"
              rules={{
                required: "Time entry type is required",
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <YaoFormFieldLabel name="work_type" label="Type" />
            <YAOFieldAutocomplete
              name="work_type"
              label=""
              placeholder="Choose time entry type"
              options={Object.keys(WorkTypeLabel).map((wt) => ({
                label: WorkTypeLabel[wt as WorkType],
                value: wt,
              }))}
            />
          </Grid>

          <Grid item xs={3}>
            <YaoFormFieldLabel name="date" label="Date" required />
            <YAOFieldDatePicker
              name="date"
              datePickerProps={{
                views: ["year", "month", "day"],
              }}
              rules={{
                required: "Date is required",
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <YaoFormFieldLabel
              name="duration_minutes"
              label="Time (in minutes)"
              required
            />
            <YAOFieldText
              name="duration_minutes"
              label=""
              type="number"
              placeholder="Choose time in minutes"
              rules={{
                required: "Time is required",
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <YaoFormFieldLabel name="description" label="Description" />
            <YAOFieldText
              name="description"
              label=""
              placeholder="e.g. Attendance at court"
            />
          </Grid>

          <Grid item xs={3}>
            <YaoFormFieldLabel name="rate" label="Override default rate" />

            <YAOFieldCurrency
              name="rate"
              label=""
              type="text"
              placeholder="Enter amount"
              InputProps={{
                endAdornment: <InputAdornment position="end">{lawFirmContext.getCurrencySymbol()}</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <YaoFormFieldLabel label="Value" />

            <YAOFieldCurrency
              name="estimated_billable"
              label=""
              type="text"
              placeholder="Enter amount"
              disabled
              InputProps={{
                endAdornment: <InputAdornment position="end">{lawFirmContext.getCurrencySymbol()}</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>

        <Stack
          direction="row"
          sx={{
            mt: 4,
            justifyContent: "right",
          }}
          spacing={2}
        >
          <Button color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ minWidth: 100 }}
            type="submit"
            onClick={methods.handleSubmit(onSubmit)}
          >
            {type === "create" ? "Add" : "Save"}
          </Button>
        </Stack>
      </FormProvider>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default TimeEntryForm;
