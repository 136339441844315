import { AxiosResponse } from "axios";
import axios from "src_common/utils/axios";
import { Matter } from "./matters";
import { Attorney } from "./attorneys";
import { Contact } from "./contacts";
import qs from "qs";
import { Task } from "./tasks";

export enum ComplianceStatus {
  PENDING = "PENDING",
  CLEARED = "CLEARED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  REFERRED = "REFERRED",
}

export const ComplianceStatusLabels: Record<ComplianceStatus, string> = {
  [ComplianceStatus.APPROVED]: "Approved",
  [ComplianceStatus.CLEARED]: "Cleared",
  [ComplianceStatus.PENDING]: "Pending",
  [ComplianceStatus.REJECTED]: "Rejected",
  [ComplianceStatus.REFERRED]: "Referred",
};

export enum ComplianceConflictType {
  MATTER_NAME = "MATTER_NAME",
  CLIENT_IS_CONTACT_OTHER_MATTER = "CLIENT_IS_CONTACT_OTHER_MATTER",
  CONTACT_IS_CLIENT_OTHER_MATTER = "CONTACT_IS_CLIENT_OTHER_MATTER",
  SIMILAR_CLIENT_EXISTS = "SIMILAR_CLIENT_EXISTS",
  SIMILAR_CONTACT_EXISTS = "SIMILAR_CONTACT_EXISTS",
}

export const ComplianceConflictTypeLabels: Record<
  ComplianceConflictType,
  string
> = {
  [ComplianceConflictType.CLIENT_IS_CONTACT_OTHER_MATTER]:
    "Client is other's matter contact",
  [ComplianceConflictType.MATTER_NAME]: "Matter name conflict",
  [ComplianceConflictType.SIMILAR_CLIENT_EXISTS]:
    "Similiar client exists in other matter",
  [ComplianceConflictType.SIMILAR_CONTACT_EXISTS]:
    "Similiar contact exists in other matter",
  [ComplianceConflictType.CONTACT_IS_CLIENT_OTHER_MATTER]:
    "Contact is other's matter client",
};

export enum RiskCategory {
  HIGH_RISK = "HIGH_RISK",
  MONEY_LOUNDERING = "AML",
  CONFLICT = "CONFLICT",
  UNDERTAKING = "UNDERTAKING",
  COMPLAINT = "COMPLAINT",
  OTHER = "OTHER",
}

export const ComplianceRiskCategoryLabels: Record<RiskCategory, string> = {
  [RiskCategory.CONFLICT]: "Conflict",
  [RiskCategory.HIGH_RISK]: "High Risk",
  [RiskCategory.MONEY_LOUNDERING]: "AML",
  [RiskCategory.UNDERTAKING]: "Undertaking",
  [RiskCategory.COMPLAINT]: "Complaint",
  [RiskCategory.OTHER]: "Other",
};

export enum AuditFrequency {
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  OTHER = "OTHER",
  NA = "N/A",
}

export const AuditFrequencyLabels: Record<AuditFrequency, string> = {
  [AuditFrequency.WEEKLY]: "Weekly",
  [AuditFrequency.MONTHLY]: "Monthly",
  [AuditFrequency.QUARTERLY]: "Quaterly",
  [AuditFrequency.OTHER]: "Other",
  [AuditFrequency.NA]: "N/A",
};

export enum DecisionCategory {
  NO_CONFLICT = "NO_CONFLICT", // A -> No conflict
  OWN_INTEREST_CONFLICT = "OWN_INTEREST_CONFLICT", // B -> Own interest conflict
  CLIENT_CONFLICT_EXCEPTION = "CLIENT_CONFLICT_EXCEPTION", // C -> Client conflict of interest (exception)
  CLIENT_CONFLICT = "CLIENT_CONFLICT", // D -> Client conflict of interest
  ADVERSE_CONFIDENTIAL_INFO = "ADVERSE_CONFIDENTIAL_INFO", // E -> Adverse confidential information
  MATERIAL_CONFIDENTIAL_INFO = "MATERIAL_CONFIDENTIAL_INFO", // F -> Material confidential information
  OTHER = "OTHER", // G -> Other
}

export const DecisionCategoryLabels: Record<DecisionCategory, string> = {
  [DecisionCategory.NO_CONFLICT]: "No Conflict or Suggestion of Conflict",
  [DecisionCategory.OWN_INTEREST_CONFLICT]:
    "Own Interest Conflict (or Significant Risk)",
  [DecisionCategory.CLIENT_CONFLICT_EXCEPTION]:
    "Acceptable Client Conflict (Common Interest or Objective)",
  [DecisionCategory.CLIENT_CONFLICT]:
    "Client Conflict (or Significant Risk) with No Exception",
  [DecisionCategory.ADVERSE_CONFIDENTIAL_INFO]:
    "Conflict of interest: Confidential data may affect current instructions",
  [DecisionCategory.MATERIAL_CONFIDENTIAL_INFO]:
    "Acceptable Confidential Data Conflict - No Adverse Interest or Conflict",
  [DecisionCategory.OTHER]: "Other",
};

export const DecisionCategoryTooltips: Record<DecisionCategory, string> = {
  [DecisionCategory.NO_CONFLICT]:
    "There is no conflict and no suggestion that we hold material confidential information about another client",
  [DecisionCategory.OWN_INTEREST_CONFLICT]:
    "There is an own interest conflict or a significant risk of an own interest conflict",
  [DecisionCategory.CLIENT_CONFLICT_EXCEPTION]:
    "There is a client conflict of interest (or a significant risk of one) in relation to the matter, or a particular aspect of it and we cannot rely on: —the substantially common interest exception —the competing for the same objective exception",
  [DecisionCategory.CLIENT_CONFLICT]:
    "There is a client conflict of interest (or a significant risk of one) but the affected clients: - have a substantially common interest; - are competing for the same objective",
  [DecisionCategory.ADVERSE_CONFIDENTIAL_INFO]:
    "We hold confidential information for a current or former client (client A), whose interest is adverse to the client who is instructing us (client B) and the confidential information is material to our current instructions",
  [DecisionCategory.MATERIAL_CONFIDENTIAL_INFO]:
    "We hold confidential information for a current or former client (client A) that is material to the client who is instructing us (client B)'s matter but there is no suggestion of an adverse interest or conflict of interest between A and B",
  [DecisionCategory.OTHER]: "Other",
};

export enum YesNoOptions {
  YES = "YES",
  NO = "NO",
  NA = "N/A",
}

export const YesNoOptionsLabels: Record<YesNoOptions, string> = {
  [YesNoOptions.YES]: "Yes",
  [YesNoOptions.NO]: "No",
  [YesNoOptions.NA]: "N/A",
};

export type ComplianceQueryParams = {
  matter?: string;
  status?: ComplianceStatus[];
  category?: RiskCategory;
  page?: number;
  limit?: number;
};

export type ComplianceAttorney = Pick<Attorney, "_id" | "name" | "surname">;
export type ComplianceContact = Pick<Contact, "_id" | "display_name">;
export type ComplianceMatter = {
  _id: string;
  responsible_lawyer: ComplianceAttorney;
  responsible_supervisor: ComplianceAttorney;
};
export type ComplianceComment = {
  _id: string;
  author: Pick<Attorney, "_id" | "name" | "surname">;
  comment: string;
  created_at: Date;
  updated_at: Date;
};

export type Compliance = {
  _id: string;
  reference: number;
  comments?: ComplianceComment[];
  description?: string;
  complementary_info?: {
    client_confidential_safeguards?: YesNoOptions;
    decision_category: DecisionCategory;
    frequency_of_audits: AuditFrequency;
    other_comments?: string;
    written_informed_consent_clients?: YesNoOptions;
  };
  matter: ComplianceMatter;
  law_firm: string;
  other_matter: Matter;
  approved_by?: ComplianceAttorney;
  author: ComplianceAttorney;
  conflict_type: ComplianceConflictType;
  status: ComplianceStatus;
  category: RiskCategory;
  other_contact?: ComplianceContact;
  contact?: ComplianceContact;
  created_at?: Date;
  updated_at?: Date;
};

export type ComplianceResponse = {
  filter: ComplianceQueryParams;
  rows: Compliance[];
};

export type CreateCompliancePayload = {
  matter: string;
  due_date?: Date;
  category: string;
  description: string;
};

export type CommentaryPayload = {
  comment: string;
};

export type CommentaryResponse = {
  comments: ComplianceComment[];
};

class ComplianceService {
  private baseEndpoint = "/compliance";

  public async list(
    params?: ComplianceQueryParams
  ): Promise<AxiosResponse<ComplianceResponse>> {
    return axios.get(this.baseEndpoint, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }

  public async countMatterPendingCompliances(
    matterId: string
  ): Promise<AxiosResponse<number>> {
    return axios.get(`${this.baseEndpoint}/matter/${matterId}/pending`);
  }

  public async createCompliance(
    payload: CreateCompliancePayload
  ): Promise<AxiosResponse<{ compliance: Compliance; task?: Task }>> {
    return axios.post(this.baseEndpoint, payload);
  }

  public async approve(
    id: string,
    payload: {
      client_confidential_safeguards: YesNoOptions | null;
      written_informed_consent_clients: YesNoOptions | null;
      decision_category: DecisionCategory;
      frequency_of_audits: AuditFrequency;
      other_comments: string;
    }
  ): Promise<AxiosResponse<void>> {
    return axios.patch(`${this.baseEndpoint}/${id}/approve`, payload);
  }

  public async refer(
    id: string,
    payload: CommentaryPayload = {} as CommentaryPayload
  ): Promise<AxiosResponse<Compliance>> {
    return axios.patch(`${this.baseEndpoint}/${id}/refer`, payload);
  }

  public async referBack(
    id: string,
    payload: CommentaryPayload = {} as CommentaryPayload
  ): Promise<AxiosResponse<Compliance>> {
    return axios.patch(`${this.baseEndpoint}/${id}/refer-back`, payload);
  }

  public async registerComment(
    id: string,
    payload: CommentaryPayload
  ): Promise<AxiosResponse<CommentaryResponse>> {
    return axios.post(`${this.baseEndpoint}/${id}/comments`, payload);
  }

  public async updateComment(
    complianceId: string,
    commentId: string,
    payload: CommentaryPayload
  ): Promise<AxiosResponse<CommentaryResponse>> {
    return axios.patch(
      `${this.baseEndpoint}/${complianceId}/comments/${commentId}`,
      payload
    );
  }
}

export default ComplianceService;
