import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useAPI } from "src_common/hooks/useAPI";
import { formatError } from "src_common/utils/misc";
import {
  ConflictApiResponse,
  ConflictQueryDTO,
  ContactConflict,
  ContactsWithConflictApiResponse,
  getAllContactWithConflicts,
  getConflicts,
} from "src_lawfirm/api/contacts";

export interface UseConflictsHook {
  conflicts: ContactConflict[];
  error: object | null;
  isConflictLoading: boolean;
  handlePagination: (
    page: number,
    limit: number,
    params?: Partial<ConflictQueryDTO>
  ) => Promise<void>;
  invoke: (
    params: ConflictQueryDTO
  ) => Promise<ConflictApiResponse | undefined>;
  firstFetch(): Promise<void>;
  isLastPage: boolean;
  conflictedContactsList: ContactsWithConflictApiResponse | undefined;
}

export default function useConflicts(isEmbeded: boolean = true): UseConflictsHook {
  const { error } = useAPI<ConflictApiResponse>(getConflicts);
  const [conflicts, setConflicts] = useState<ContactConflict[]>([]);
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const [isConflictLoading, setIsConflictLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const { data: conflictedContactsList } = useQuery({
    queryKey: ["contactWithConflicts"],
    queryFn: () => getAllContactWithConflicts(),
    cacheTime: 1800000,
    enabled: !isEmbeded
  });
  const invoke = async (params: ConflictQueryDTO) => {
    setIsConflictLoading(true);
    try {
      const response = await getConflicts(params);
      return response;
    } catch (error) {
      enqueueSnackbar(formatError(error), { variant: "error" });
    } finally {
      setIsConflictLoading(false);
    }
  };

  async function firstFetch() {
    const data = await invoke({ page: 0, limit: 50 });
    if (data) {
      setConflicts(data.rows);
    }
  }
  useEffect(() => {
    if (!isEmbeded) {
      firstFetch();
    }
  }, []);

  const handlePagination = async (
    page: number,
    limit: number = 50,
    params?: Partial<ConflictQueryDTO>
  ) => {
    if (isLastPage && !params) {
      return;
    }

    const data = await invoke({ page, limit, ...params });

    if (!data) {
      return;
    }

    if (data.rows.length === 0 && !params) {
      setIsLastPage(true);
    } else if (!params) {
      setConflicts((prev) => [...prev, ...data.rows]);
    } else {
      if (data.rows.length) {
        setConflicts(data.rows);
        setIsLastPage(false);
        return;
      }
      enqueueSnackbar("No conflicts found!", { variant: "info" });
    }
  };
  return {
    conflicts,
    error,
    isConflictLoading,
    handlePagination,
    invoke,
    isLastPage,
    firstFetch,
    conflictedContactsList,
  };
}
