import { useEffect, useMemo, useRef } from "react";

export const useInfiniteScrollQuery = ({
  fetch,
  observerOptions,
}: {
  fetch?: Function;
  observerOptions?: IntersectionObserverInit;
}) => {
  const ref = useRef(null);

  const observer = useMemo(
    () =>
      new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            fetch && fetch();
          }
        });
      }, observerOptions),
    [fetch]
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref.current, fetch]);

  return {
    ref,
  };
};
