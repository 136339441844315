import { useCallback, useState } from "react";
import { useSnackbar } from "notistack";
import { formatError } from "src_common/utils/misc";

export type Callback = (data?: object | null, error?: object | null) => void;

export type UseAPIHook<T> = {
  loading: boolean;
  invoked: boolean;
  data: T | null;
  error: object | null;
  invoke: (params?: object | string, callback?: Callback) => void;
};

export function useAPI<T extends object>(
  apiFn: (...args: any) => Promise<T>
): UseAPIHook<T> {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<object | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [invoked, setInvoked] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const invoke = useCallback(
    async (params?: object | string, callback?: Callback) => {
      setLoading(true);
      setInvoked(true);
      let data, error;
      try {
        data = await apiFn(params);
        setData(data);
      } catch (e) {
        setError(e);
        error = e;
        enqueueSnackbar(formatError(e), { variant: "warning" });
      } finally {
        setLoading(false);
      }
      callback && callback(data, error);
    },
    [apiFn]
  );

  return { loading, data, error, invoke, invoked };
}
