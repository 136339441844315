import { Suspense, lazy, ElementType } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import useAuth from "src_common/hooks/useAuth";
import MainLayout from "src_common/layouts/main";
import LogoOnlyLayout from "src_common/layouts/LogoOnlyLayout";
import GuestGuard from "src_common/guards/GuestGuard";
import LoadingScreen from "src_common/components/LoadingScreen";
import AuthLayout from "src_common/layouts/AuthLayout";
import AuthGuard from "src_common/guards/AuthGuard";
import { PATH_HOME } from "./paths";
import navConfig from "./nav-config";
import HomePage from "../pages/Home";
import { AddMenuContextProvider } from "src_common/contexts/AddMenuContext";
import {MenuContextProvider} from "src_common/components/menu/menu-context";

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes("/dashboard") && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      element: (
        <GuestGuard>
          <AuthLayout label="Backend Team" />
        </GuestGuard>
      ),
      children: [
        { path: "login", element: <LoginPage />, index: true },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "new-password", element: <NewPassword /> },
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "coming-soon", element: <ComingSoon /> },
        { path: "maintenance", element: <Maintenance /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: (
        <AuthGuard>
          <AddMenuContextProvider>
            <MenuContextProvider>
              <MainLayout navConfig={navConfig} />
            </MenuContextProvider>
          </AddMenuContextProvider>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_HOME.root} replace />, index: true },
        { path: "home", element: <HomePage /> },
        { path: "yaoers", element: <YaoersPage /> },
        { path: "law-firms", element: <LawFirmsPage /> },
        { path: "forms", element: <FormsPage /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const LoginPage = Loadable(
  lazy(() => import("src_backoffice/pages/auth/LoginPage"))
);
const ResetPassword = Loadable(
  lazy(() => import("src_backoffice/pages/auth/ResetPassword"))
);
const NewPassword = Loadable(
  lazy(() => import("src_backoffice/pages/auth/NewPassword"))
);

// PAGES
const YaoersPage = Loadable(lazy(() => import("src_backoffice/pages/yaoers")));
const LawFirmsPage = Loadable(
  lazy(() => import("src_backoffice/pages/law-firms"))
);
const FormsPage = Loadable(lazy(() => import("src_backoffice/pages/forms")));

// MISC
const ComingSoon = Loadable(lazy(() => import("src_common/pages/ComingSoon")));
const Maintenance = Loadable(
  lazy(() => import("src_common/pages/Maintenance"))
);
const Page500 = Loadable(lazy(() => import("src_common/pages/Page500")));
const Page403 = Loadable(lazy(() => import("src_common/pages/Page403")));
const Page404 = Loadable(lazy(() => import("src_common/pages/Page404")));
