import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
} from "@mui/material";
import { FC, useContext, useEffect } from "react";
import YaoSelectorContext, {
  YaoSelectorContextProvider,
} from "./yao-selector.context";
import {
  YaoSelectorClose,
  YaoSelectorContainedScroll,
  YaoSelectorFilterCaseType,
  YaoSelectorFilterDepartment,
  YaoSelectorFilterText,
  YaoSelectorGrid,
  YaoSelectorHeader,
  YaoSelectorList,
  YaoSelectorListContainer,
  YaoSelectorTitle,
} from "./yao-selector.layout";
import { YaoSelectorInputProps } from "./yao-selector.types";
import "./yao-selector.css";

const YaoSelectorStateFull: FC<
  YaoSelectorInputProps & { onClose: (data: any) => void }
> = ({ onClose, ...params }) => {
  const selectorCtx = useContext(YaoSelectorContext);

  useEffect(() => {
    selectorCtx.setup(params);
  }, []);

  return (
    <>
      <YaoSelectorGrid sx={{ pl: 2, pt: 2, pr: 2 }}>
        <YaoSelectorClose onClose={() => onClose(undefined)} />
        <YaoSelectorHeader>
          <YaoSelectorTitle />
          <YaoSelectorFilterCaseType />
          <YaoSelectorFilterText />
        </YaoSelectorHeader>
      </YaoSelectorGrid>
      <DialogContent sx={{ pt: 1, pl: 0, pr: 0, pb: 0 }}>
        <YaoSelectorGrid sx={{ height: "100%", overflow: "hidden" }}>
          <YaoSelectorContainedScroll>
            <YaoSelectorFilterDepartment />
          </YaoSelectorContainedScroll>
          <YaoSelectorContainedScroll>
            <YaoSelectorListContainer
              onClose={(data: any) => onClose(data)}
              onContinue={params.onContinue}
            >
              <YaoSelectorList />
            </YaoSelectorListContainer>
          </YaoSelectorContainedScroll>
        </YaoSelectorGrid>
      </DialogContent>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={selectorCtx.backdropEnabled && selectorCtx.loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

const YaoSelectorDialog = NiceModal.create<YaoSelectorInputProps>((params) => {
  const modal = useModal();
  return (
    <Dialog
      fullWidth
      fullScreen
      open={modal.visible}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <YaoSelectorContextProvider>
        <YaoSelectorStateFull
          {...params}
          onClose={(data: any) => {
            if (!!data) {
              modal.resolve(data);
            }
            modal.hide();
          }}
        />
      </YaoSelectorContextProvider>
    </Dialog>
  );
});

export default YaoSelectorDialog;
