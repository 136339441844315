import { useRef } from 'react';

type FN = (...args: any) => any;

interface Return {
  debounce: (fn: FN, delay?: number) => void;
  reset: () => void;
}

const useDebounce = (): Return => {
  const timeRef = useRef<null | NodeJS.Timeout>(null);

  const debounce = (fn: FN, delay = 500) => {
    if (timeRef.current) clearTimeout(timeRef.current);
    timeRef.current = setTimeout(() => {
      fn();
    }, delay);
  };

  const reset = () => {
    if (timeRef.current) {
      clearTimeout(timeRef.current);
    }
  };

  return { debounce, reset };
};

export default useDebounce;