import { Theme } from "@mui/material/styles";
import CustomIcon from "src_common/components/custom-icon";
import palette from "../palette";

export default function Autocomplete(theme: Theme) {
  return {
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: (
          <CustomIcon
            name="arrow"
            sx={{ p: 0.9 }}
            color={palette.yao.grey[6]}
          />
        ),
      },
      styleOverrides: {
        root: {
          ".MuiOutlinedInput-root": {
            padding: "10.5px 16px",
            ".MuiAutocomplete-input": {
              padding: 0,
            },
          },
        },
        paper: {
          boxShadow: theme.customShadows.dropdown,
        },
        listbox: {
          fontSize: 13,
          padding: theme.spacing(0, 1),
          "& .MuiAutocomplete-option": {
            padding: theme.spacing(1),
            margin: theme.spacing(1, 0),
            borderRadius: 4,
          },
        },
        loading: {
          fontSize: 13,
        },
      },
    },
  };
}
