import { Grid, InputLabel, Typography, SxProps, Theme } from "@mui/material";
import _ from "lodash";
import { FC, Fragment } from "react";
import palette from "src_common/theme/palette";
import { RHFMultiCheckbox, RHFCheckbox, RHFTextField } from "../hook-form";
import YaoAutocomplete from "./YaoAutocomplete";

type ComponentProps = {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  multiline?: boolean;
  disabled?: boolean;
  options?: {
    label: string;
    value: string;
  }[];
  [key: string]: any;
};

export type FieldProps = ComponentProps & {
  type?: "textfield" | "autocomplete" | "checkboxgroup" | "checkbox" | "break";
  Component?: FC<ComponentProps>;
  xs: number;
};

type YaoFormProps = {
  fields: FieldProps[];
  sx?: { [key: string]: string | number };
};

export function YaoFormFieldLabel({
  name,
  label,
  required,
  sx = {},
}: {
  name?: string;
  label?: string;
  required?: boolean;
  sx?: SxProps<Theme>;
}) {
  if (!label) return null;

  return (
    <InputLabel htmlFor={name} sx={{ ...sx, minHeight: 32 }}>
      <span>
        {label}
        {required && (
          <span style={{ color: palette.yao.secondary[2] }}> *</span>
        )}
      </span>
    </InputLabel>
  );
}

export default function YaoForm({ fields, ...props }: YaoFormProps) {
  return (
    <Grid container rowSpacing={1.5} columnSpacing={2.5} {...props}>
      {fields.map(({ Component, type, xs, ...entry }) =>
        Component ? (
          <Grid item xs={xs} key={entry.name}>
            <YaoFormFieldLabel
              {..._.pick(entry, ["name", "label", "required"])}
            />
            <Component {...entry} />
          </Grid>
        ) : type === "textfield" ? (
          <Grid item xs={xs} key={entry.name}>
            <YaoFormFieldLabel
              {..._.pick(entry, ["name", "label", "required"])}
            />
            <RHFTextField
              id={entry.name}
              name={entry.name}
              variant="outlined"
              placeholder={entry.placeholder}
              multiline={entry.multiline}
              minRows={2}
              InputProps={entry.InputProps}
              disabled={entry.disabled}
            />
          </Grid>
        ) : type === "autocomplete" ? (
          <Grid item xs={xs} key={entry.name}>
            <YaoFormFieldLabel
              {..._.pick(entry, ["name", "label", "required"])}
            />
            <YaoAutocomplete
              id={entry.name}
              options={entry.options || []}
              renderInput={(params: any) => (
                <RHFTextField
                  {...params}
                  name={entry.name}
                  variant="outlined"
                  placeholder={entry.placeholder}
                />
              )}
            />
          </Grid>
        ) : type === "checkboxgroup" ? (
          <Grid item xs={xs} key={entry.name}>
            <YaoFormFieldLabel
              {..._.pick(entry, ["name", "label", "required"])}
            />
            <Grid container>
              <Grid item xs={12}>
                <RHFMultiCheckbox
                  name={entry.name}
                  value={[]}
                  options={entry.options || []}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : type === "checkbox" ? (
          <Grid item xs={xs} key={entry.name}>
            <RHFCheckbox
              name={entry.name}
              value={[]}
              label={entry.label}
              onClick={entry.onclick}
            />
          </Grid>
        ) : type === "break" ? (
          <Grid item xs={12} sx={{ mt: 2 }} key={entry.name}></Grid>
        ) : (
          <Fragment key={entry.name}></Fragment>
        )
      )}
      <Grid item xs={12}>
        <Typography sx={{ fontSize: 11, color: palette.yao.primary[3] }}>
          <span style={{ color: palette.yao.secondary[2], fontWeight: 600 }}>
            *
          </span>{" "}
          Mandatory fields
        </Typography>
      </Grid>
    </Grid>
  );
}
