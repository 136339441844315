import { formatError } from "src_common/utils/misc";
import axios from "src_common/utils/axios";

export interface EmailTemplate {
    _id: string;
    title: string;
    description: string;
    template: string;
    law_firm: string;
    is_deleted: boolean;
}

export interface EmailTemplateDto {
    title: string;
    description: string;
    template: string;
}

export interface FindEmailTemplatesQueryDto {
    search: string;
}

export interface PopulateTemplateDto {
    matter: string
}

export async function createEmailTemplate(
    form: EmailTemplateDto
): Promise<EmailTemplate> {
    try {
        const res = await axios.post("/email-templates", form);
        return res.data;
    } catch (e) {
        throw new Error(formatError(e));
    }
}

export async function updateEmailTemplate(
    form: EmailTemplateDto,
    id: string
): Promise<EmailTemplate> {
    try {
        const res = await axios.put(`/email-templates/${id}`, form);
        return res.data;
    } catch (e) {
        throw new Error(formatError(e));
    }
}

export async function deleteEmailTemplate( id: string, is_deleted: boolean): Promise<EmailTemplate> {
    try {
        const res = await axios.patch(`/email-templates/${id}/delete`, {is_deleted});
        return res.data;
    } catch (e) {
        throw new Error(formatError(e));
    }
}

export async function getEmailTemplate(
    params: FindEmailTemplatesQueryDto | undefined
): Promise<EmailTemplate[]> {
    const res = await axios.post("/email-templates/search", {}, { params });
    return res.data;
}

export async function getEmailPopulated(
    id: string,
    payload: PopulateTemplateDto
): Promise<{ html: string }> {
    const res = await axios.post(`/email-templates/${id}/populate`, payload);
    return res.data;
}
