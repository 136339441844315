import {
  MouseEvent,
  useContext,
  useState,
  useEffect,
  useRef,
  SyntheticEvent,
} from "react";
import {
  Box,
  Button,
  List,
  ListItemButton,
  ListItemText,
  Drawer,
  Autocomplete,
  TextField,
  Typography,
  Stack,
  Divider,
  ListItemIcon,
} from "@mui/material";
import MenuPopover from "src_common/components/MenuPopover";
import CustomIcon from "src_common/components/custom-icon";
import palette from "src_common/theme/palette";
import AddMenuContext from "src_common/contexts/AddMenuContext";
import { useNavigate } from "react-router";
import { useContacts } from "src_lawfirm/pages/contacts/useContacts";
import { useInvoices } from "src_lawfirm/pages/invoices/useInvoices";
import NiceModal from "@ebay/nice-modal-react";
import { TimeEntry } from "src_lawfirm/api/time-entries";
import TimeEntryDialog from "src_lawfirm/pages/matters/TimeEntry/TimeEntryDialog";
import { AddCorrespondenceCallNoteModal } from "src_lawfirm/pages/matters/CorrespondenceForms";
import { getMatters, Matter } from "src_lawfirm/api/matters";
import { AddNewTask } from "src_lawfirm/pages/matters/TaskForms";
import { useAPI } from "src_common/hooks/useAPI";
import { YaoFormFieldLabel } from "src_common/components/yao-form/YaoForm";
import {
  MatterAutoCompleteFilterHelper,
  MatterAutoCompleteRenderOptionHelper,
} from "src_lawfirm/pages/matters/MatterAutoCompleteHelper";

export default function AddItemPopover() {
  const getMattersAPI = useAPI(getMatters);
  const addMenuCtx = useContext(AddMenuContext);
  const navigate = useNavigate();
  const contactsApi = useContacts();
  const invoicesApi = useInvoices();
  const [openActions, setOpenActions] = useState<HTMLElement | null>(null);
  const [openSelectMatter, setOpenSelectMatter] = useState<HTMLElement | null>(
    null
  );
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedMatter, setSelectedMatter] = useState<Matter>();

  const [addCorrespondenceModal, setAddCorrespondenceModal] =
    useState<boolean>(false);
  const [taskAddForm, setTaskAddForm] = useState<boolean>(false);
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getMattersAPI.invoke();
  }, []);

  const handleOpen = () => {
    if (!addMenuCtx.matterId) {
      setOpenActions(null);
      setOpenSelectMatter(rootRef.current);
    } else {
      setOpenSelectMatter(null);
      setOpenActions(rootRef.current);
    }
  };

  const handleClose = () => {
    setOpenActions(null);
  };

  const handleSelectMatterClose = () => {
    setOpenSelectMatter(null);
  };

  const handleNewCallNote = (event: MouseEvent<HTMLElement>) => {
    setAddCorrespondenceModal(true);
    handleClose();
  };

  const handleNewTask = (event: MouseEvent<HTMLElement>) => {
    setTaskAddForm(true);
    handleClose();
  };

  const handleNewTimeEntry = (event: MouseEvent<HTMLElement>) => {
    NiceModal.show<TimeEntry>(TimeEntryDialog, {
      matterId: addMenuCtx.matterId as string,
    });
    handleClose();
  };

  const handleNewInvoice = (event: MouseEvent<HTMLElement>) => {
    invoicesApi.createDialog(addMenuCtx.matterId as string);
    handleClose();
  };

  const handleNewContact = (event: MouseEvent<HTMLElement>) => {
    contactsApi.createDialog();
    handleClose();
  };

  const handleNewCompany = (event: MouseEvent<HTMLElement>) => {
    contactsApi.createDialog(true);
    handleClose();
  };

  const handleNewMatter = (event: MouseEvent<HTMLElement>) => {
    navigate("/matters/new");
    handleClose();
  };

  const handleSelectMatter = () => {
    setTimeout(() => {
      addMenuCtx.setMatterId(selectedMatter?._id || undefined);
      setOpenSelectMatter(null);
      setOpenActions(rootRef.current);
    }, 100);
  };

  const handleMatterChange = () => {
    setTimeout(() => {
      addMenuCtx.setMatterId(undefined);
      setOpenActions(null);
      setOpenSelectMatter(rootRef.current);
    }, 100);
  };

  const _matterOptions = (getMattersAPI?.data || []).map((m) => ({
    ...m,
    label: m.case_name,
    value: m._id,
  }));

  return (
    <>
      <div ref={rootRef}>
        <Button
          onClick={handleOpen}
          startIcon={
            <CustomIcon
              name="add"
              color={palette.yao.grey[6]}
              sx={{ position: "absolute", left: 8, top: 5, p: 0.1 }}
            />
          }
          variant="outlined"
          sx={{
            textAlign: "left",
            width: 110,
            pl: 3.5,
            ...((openActions || openSelectMatter) && {
              "&:before": {
                zIndex: 1,
                content: "''",
                width: "100%",
                height: "100%",
                position: "absolute",
              },
            }),
          }}
        >
          Add
        </Button>
      </div>

      <MenuPopover
        open={Boolean(openSelectMatter)}
        anchorEl={openSelectMatter}
        onClose={handleSelectMatterClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
          width: "350px",
        }}
      >
        <Box sx={{ py: 1, px: 2, color: palette.yao.primary[3] }}>
          <YaoFormFieldLabel label="Matter" />
          <Autocomplete
            id="addmenu-matter"
            options={_matterOptions}
            getOptionLabel={(o: Matter) => o.case_name}
            isOptionEqualToValue={(o, v) => o._id === v?._id}
            value={selectedMatter}
            inputValue={inputValue}
            onInputChange={(_: SyntheticEvent, value: string) =>
              setInputValue(value)
            }
            disableClearable
            renderInput={(params) => (
              <TextField {...params} fullWidth placeholder="Choose matter" />
            )}
            onChange={(_, newValue: Matter) => {
              setSelectedMatter(newValue);
            }}
            renderOption={MatterAutoCompleteRenderOptionHelper}
            filterOptions={MatterAutoCompleteFilterHelper}
            defaultValue={_matterOptions.find(
              (m) => m._id === addMenuCtx.matterId
            )}
          />
          <Stack
            direction="row"
            justifyContent="right"
            spacing={1}
            sx={{ mt: 2.5 }}
          >
            <Button color="secondary" onClick={handleSelectMatterClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={!selectedMatter}
              onClick={handleSelectMatter}
            >
              Select
            </Button>
          </Stack>
        </Box>
      </MenuPopover>

      <MenuPopover
        open={Boolean(openActions)}
        anchorEl={openActions}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
          width: "275px",
        }}
      >
        <Box>
          <Box paddingLeft="20px" paddingRight="20px" paddingTop="20px">
            <Stack
              direction="row"
              spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                flexGrow={1}
                flexShrink={1}
                color="#919EAB"
                fontWeight={400}
                fontSize={10}
                lineHeight="24px"
              >
                MATTER
              </Typography>
              <Typography
                color="#0186B0"
                fontWeight={700}
                fontSize={11}
                lineHeight="20px"
                sx={{
                  cursor: "pointer",
                  "&:hover": { textDecoration: "underline" },
                }}
                onClick={handleMatterChange}
              >
                Change
              </Typography>
            </Stack>
            {addMenuCtx.matter === null ? null : (
              <Typography
                color="#0D394D"
                fontWeight={700}
                fontSize={13}
                lineHeight="30px"
                marginBottom="10px"
              >
                {addMenuCtx.matter?.case_name}
              </Typography>
            )}
            <Divider />
          </Box>
          <List>
            <ListItemButton onClick={handleNewTask}>
              <ListItemIcon>
                <CustomIcon name="bulletList" size={16} color="#646D76" />
              </ListItemIcon>
              <ListItemText primary="Task" />
            </ListItemButton>
            <ListItemButton onClick={handleNewCallNote}>
              <ListItemIcon>
                <CustomIcon name="phone" size={16} color="#646D76" />
              </ListItemIcon>
              <ListItemText
                primary="Call Note"
                primaryTypographyProps={{
                  color: "#646D76",
                  fontSize: "14px",
                  lineHeight: "22px",
                  fontWeight: 500,
                }}
              />
            </ListItemButton>
            <ListItemButton onClick={handleNewInvoice}>
              <ListItemIcon>
                <CustomIcon name="bill" size={16} color="#646D76" />
              </ListItemIcon>
              <ListItemText
                primary="Invoice"
                primaryTypographyProps={{
                  color: "#646D76",
                  fontSize: "14px",
                  lineHeight: "22px",
                  fontWeight: 500,
                }}
              />
            </ListItemButton>
            <ListItemButton onClick={handleNewTimeEntry}>
              <ListItemIcon>
                <CustomIcon name="time" size={16} color="#646D76" />
              </ListItemIcon>
              <ListItemText
                primary="Time Entry"
                primaryTypographyProps={{
                  color: "#646D76",
                  fontSize: "14px",
                  lineHeight: "22px",
                  fontWeight: 500,
                }}
              />
            </ListItemButton>
            <ListItemButton onClick={handleNewContact}>
              <ListItemIcon>
                <CustomIcon name="user_plus" size={16} color="#646D76" />
              </ListItemIcon>
              <ListItemText
                primary="Contact"
                primaryTypographyProps={{
                  color: "#646D76",
                  fontSize: "14px",
                  lineHeight: "22px",
                  fontWeight: 500,
                }}
              />
            </ListItemButton>
            <ListItemButton onClick={handleNewCompany}>
              <ListItemIcon>
                <CustomIcon name="company" size={16} color="#646D76" />
              </ListItemIcon>
              <ListItemText
                primary="Company"
                primaryTypographyProps={{
                  color: "#646D76",
                  fontSize: "14px",
                  lineHeight: "22px",
                  fontWeight: 500,
                }}
              />
            </ListItemButton>
            <ListItemButton onClick={handleNewMatter}>
              <ListItemIcon>
                <CustomIcon name="matter" size={16} color="#646D76" />
              </ListItemIcon>
              <ListItemText
                primary="Matter"
                primaryTypographyProps={{
                  color: "#646D76",
                  fontSize: "14px",
                  lineHeight: "22px",
                  fontWeight: 500,
                }}
              />
            </ListItemButton>
          </List>
        </Box>
      </MenuPopover>

      {addMenuCtx.matterId !== undefined && taskAddForm ? (
        <Drawer
          anchor="right"
          open={taskAddForm}
          onBackdropClick={() => setTaskAddForm(false)}
        >
          <AddNewTask
            matterId={addMenuCtx.matterId as string}
            callTasksAPI={() => {}}
            setAddForm={setTaskAddForm}
          />
        </Drawer>
      ) : null}

      {addMenuCtx.matter !== undefined && addCorrespondenceModal ? (
        <AddCorrespondenceCallNoteModal
          visible={addCorrespondenceModal}
          handleClose={() => setAddCorrespondenceModal(false)}
          matter={addMenuCtx.matter as Matter}
        />
      ) : null}
    </>
  );
}
