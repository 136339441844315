import * as Yup from "yup";
import { Button, DialogActions, TextField, Grid, IconButton, ListSubheader, Select, MenuItem, FormControl, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FormProvider, RHFTextArea, RHFTextField } from 'src_common/components/hook-form'
import { Task, TaskStatus, updateTask, TaskPriority, TaskCategory, TaskForm } from 'src_lawfirm/api/tasks'
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker, LoadingButton } from "@mui/lab";
import _ from "lodash";
import { Controller } from 'react-hook-form';
import { YaoFormFieldLabel } from 'src_common/components/yao-form/YaoForm';
import { YAOFieldAutocomplete } from 'src_common/components/yao-form-fields';
import { useAPI } from "src_common/hooks/useAPI";
import { Attorney, getAttorneys } from "src_lawfirm/api/attorneys";
import { getMatter } from "src_lawfirm/api/matters";
import palette from "src_common/theme/palette";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CloseIcon from '@mui/icons-material/Close';
import {TaskSendMail} from "./task-send-mail";

interface IProps {
  task: Task;
  setUpdatingTask: (val: Task | null) => void;
  callTasksAPI: () => void;
}

const TaskSchema = Yup.object().shape({
  title: Yup.string().required("Heading is required"),
  assigned_to: Yup.string().required("Assigned to is required"),
  description: Yup.string(),
  notify_flag: Yup.bool(),
  estimate_time: Yup.number(),
});

export const UpdateTask = ({ task, callTasksAPI, setUpdatingTask }: IProps) => {
  const [date, setDate] = useState<Date | undefined>(task.due_date);
  const { enqueueSnackbar } = useSnackbar();

  const attorneysAPI = useAPI(getAttorneys);
  const matterAPI = useAPI(getMatter);


  useEffect(() => {
    attorneysAPI.invoke();
  }, [])

  useEffect(() => {
    matterAPI.invoke(task.matter._id);
  }, [task.matter._id])

  const methods = useForm<TaskForm>({
    resolver: yupResolver(TaskSchema),
    defaultValues: {
      ...task,
      priority: {
        label: _.startCase(_.lowerCase(task.priority)),
        value: task.priority
      },
      status: {
        label: _.startCase(_.lowerCase(task.status)),
        value: task.status
      },
      category: {
        label: _.startCase(_.lowerCase(task.category)),
        value: task.category
      },
      assigned_to: task.assigned_to?._id,
      matter: task.matter?._id,
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (form: TaskForm) => {
    try {
      if (!task) return;
      const updatedTask = await updateTask(
        {
          ...form,
          due_date: date,
          priority: form.priority.value,
          status: form.status.value,
          category: form.category ? form.category.value : undefined,
        },
        task._id
      );

      enqueueSnackbar(`${updatedTask.title} task updated`);
      callTasksAPI();
      setUpdatingTask(null);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    }
  }

  const allAttorneys = attorneysAPI.data || [];
  const matterData = matterAPI.data;

  const matterMembers = allAttorneys.filter((a) =>
    a._id === matterData?.responsible_lawyer?._id
    || a._id === matterData?.responsible_supervisor?._id
    || a._id === matterData?.paralegal?._id
    || a._id === matterData?.secretary?._id);

  const otherMembers = allAttorneys.filter((a) => !matterMembers.some(d => d._id === a._id));

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} sx={{ maxWidth: "400px", p: 2 }}>
        <IconButton onClick={() => setUpdatingTask(null)}><CloseIcon /></IconButton>
        <Grid item xs={12} sx={{ display: "flex", alignItems: "start",flexFlow: "column", gap: "5px" }}>
          <Typography sx={{ fontSize: "24px", textAlign: "left", color: palette.yao.primary[2], fontWeight: '600' }}>
            Edit task
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TaskSendMail task={task} />
        </Grid>
        <Grid item xs={12}>
          <YaoFormFieldLabel name="task_heading_label" label="Task heading" required/>
          <RHFTextField name="title" placeholder="Enter task heading" />
        </Grid>
        <Grid item xs={6}>
          <YaoFormFieldLabel name="priority_label" label="Priority" />
          <YAOFieldAutocomplete
            name="priority"
            label=""
            placeholder="Select priority"
            options={(Object.values(TaskPriority) || []).map(
              (priority) => ({
                value: priority,
                label: _.startCase(_.lowerCase(priority)),
              })
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <YaoFormFieldLabel name="status_label" label="Status" />
          <YAOFieldAutocomplete
            name="status"
            label=""
            placeholder="Select status"
            options={(Object.values(TaskStatus) || []).map(
              (status) => ({
                value: status,
                label: _.startCase(_.lowerCase(status)),
              })
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <YaoFormFieldLabel name="category_label" label="Category" />
          <YAOFieldAutocomplete
            name="category"
            label=""
            placeholder="Select category"
            options={(Object.values(TaskCategory) || []).map(
              (category) => ({
                value: category,
                label: _.startCase(_.lowerCase(category)),
              })
            )}
          />
        </Grid>
        <Grid item xs={6}>
        <YaoFormFieldLabel name="due_date_label" label="Due date" />
        <DatePicker
          value={date || null}
          onChange={(newValue) => {
            setDate(newValue || undefined);
          }}
          InputProps={{style: {height: "40px", paddingLeft: 0}}}
          inputFormat="dd/MMM/yyyy"
          disablePast
          disableMaskedInput
          renderInput={(params) => <TextField sx={{ svg: { color: palette.yao.grey[7], height: '18px', width: '20px' } }} {...params} />}
          components={{
            OpenPickerIcon: CalendarTodayOutlinedIcon
          }}
        />
        </Grid>
        <Grid item xs={6}>
          <YaoFormFieldLabel name="estimate_time_label" label="Estimate Time"/>
          <RHFTextField
            name="estimate_time"
            type="number"
            placeholder="Choose time"
          />
        </Grid>
        <Grid item xs={12}>
          <YaoFormFieldLabel name="assigned_to_label" label="Assigned to" required/>
          <Controller
            name="assigned_to"
            control={methods.control}
            render={({ field, fieldState: { error } }) => (
              <FormControl sx={{ width: "100%" }}>
                <Select
                  {...field}
                  error={!!error}
                  sx={{ height: "40px", p: 0 }}
                >
                  <ListSubheader>Responsible Persons</ListSubheader>
                  {(matterMembers || []).map((option: Attorney) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.name} {option.surname}
                    </MenuItem>
                  ))}
                  <ListSubheader>Others</ListSubheader>
                  {(otherMembers || []).map((option: Attorney) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.name} {option.surname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <RHFTextArea
            style={{
              padding: "10px",
              borderColor: "rgba(145, 158, 171, 0.16)",
              width: "100%",
              fontFamily: "inherit"
            }}
            name="description"
            minRows={5}
            placeholder="Enter a description"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: 11, color: palette.yao.primary[3] }}>
            <span style={{ color: palette.yao.secondary[2], fontWeight: 600 }}>
              *
            </span>{" "}
            Mandatory fields
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "right"}}>
          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={() => setUpdatingTask(null)}>
              Cancel
            </Button>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              Update
            </LoadingButton>
          </DialogActions>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

