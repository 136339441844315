import { TableRow, TableCell } from "@mui/material";
import EmptyContent from "../EmptyContent";

type Props = {
  isNotFound: boolean;
  label?: string;
  img: string;
};

export default function TableNoData({ isNotFound, label = "No data at this point", img = '/assets/illustrations/illustration_empty_content.svg' }: Props) {
  return (
    <TableRow>
      {isNotFound ? (
        <TableCell colSpan={12}>
          <EmptyContent
            title={label}
            img={img}
            sx={{
              "& span.MuiBox-root": { height: 160 },
            }}
          />
        </TableCell>
      ) : (
        <TableCell colSpan={12} sx={{ p: 0 }} />
      )}
    </TableRow>
  );
}
