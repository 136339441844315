import {
  TableRow,
  TableCell,
  Typography,
  MenuItem,
  Checkbox,
  TableRowProps,
} from "@mui/material";
import { ReactNode, useState } from "react";
import Iconify from "../Iconify";
import { TableMoreMenu } from "../table";
import { Column, Row } from "./types";
import _ from "lodash";

export type RowOptionFn = (row: any) => RowOption[];
export type RowOption = {
  action?: (row: any) => any;
  label?: string;
  Component?: ReactNode;
  color?: string;
  icon?: string;
};

type Props = {
  row: Row;
  columns: Column[];
  rowOptions?: RowOption[] | RowOptionFn;
  selectable?: boolean;
  onSelectRow: (id: string) => void;
  checked: boolean;
  rowProps?:
    | (TableRowProps & { disabled?: boolean })
    | ((row: Row) => TableRowProps & { disabled?: boolean });
  rowClick?: (row: Row, e?: any) => void;
  selectorCondition?: (row: Row) => boolean;
};

export default function YaoTableRow({
  row,
  columns,
  rowOptions,
  rowProps = { hover: true },
  checked,
  onSelectRow,
  selectable,
  rowClick,
  selectorCondition
}: Props) {
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  let options: RowOption[];
  if (rowOptions instanceof Function) {
    options = rowOptions(row);
  } else {
    options = rowOptions || [];
  }

  const valueGetter = (key: number | string) => {
    if (typeof key === "number" || !key.includes(".")) {
      return row[key];
    }
    const value = _.get(row, key);
    if (Array.isArray(value)) {
      return value.join(", ");
    }
    return value;
  };

  const _rowProps = typeof rowProps === "function" ? rowProps(row) : rowProps;

  return (
    <TableRow
      {..._rowProps}
      sx={{
        opacity: !_rowProps.disabled ? 1 : 0.7,
        pointerEvents: !_rowProps.disabled ? "auto" : "none",
        "&:hover": {
          cursor: 'pointer',
          ".TableMoreMenu": {
            opacity: 1,
          },
        },
      }}
      onClick={(e) => rowClick && rowClick(row, e)}
    >
      {selectable && (
        <TableCell padding="checkbox">
          {!_rowProps.disabled && (!selectorCondition || selectorCondition(row)) && (
            <Checkbox
              checked={checked}
              onClick={(event) => {
                event.stopPropagation()
                onSelectRow(String(row._id))
              }}
            />
          )}
        </TableCell>
      )}
      {columns.map((col, i) => (
        <TableCell
          key={`${col.id}-${i.toString()}`}
          align={i === columns.length - 1 ? "right" : "left"}
          sx={{ paddingRight: i === columns.length - 1 ? "2em !important" : "1em" }}
        >
          {col.CellComponent ? (
            <col.CellComponent data={valueGetter(col.id)} row={row} />
          ) : (
            <Typography variant="inherit" noWrap>
              {col.formatter
                ? col.formatter(valueGetter(col.id), row)
                : valueGetter(col.id)}
            </Typography>
          )}
        </TableCell>
      ))}

      {options.length > 0 && !_rowProps.disabled && (
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              {options.map((opt) => (
                <MenuItem
                  key={opt.label}
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    event.stopPropagation();
                    opt.action(row);
                    handleCloseMenu();
                  }}
                  sx={{ color: opt.color }}
                >
                  {opt.icon && <Iconify icon={opt.icon} />}
                  {opt.label}
                  {opt.Component && <opt.Component onClose={handleCloseMenu} row={row} />}
                </MenuItem>
              ))}
            </>
          }
        />
      )}
    </TableRow>
  );
}
