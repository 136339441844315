import Router from "./routes";
import ThemeProvider from "src_common/theme";
import ThemeSettings from "src_common/components/settings";
import { ChartStyle } from "src_common/components/chart";
import ScrollToTop from "src_common/components/ScrollToTop";
import { ProgressBarStyle } from "src_common/components/ProgressBar";
import NotistackProvider from "src_common/components/NotistackProvider";
import MotionLazyContainer from "src_common/components/animate/MotionLazyContainer";
import NiceModal from "@ebay/nice-modal-react";

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <NiceModal.Provider>
              <ProgressBarStyle />
              <ChartStyle />
              <ScrollToTop />
              <Router />
            </NiceModal.Provider>
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
