import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Dialog as MuiDialog, IconButton } from "@mui/material";

import { useCallback } from "react";
import Iconify from "src_common/components/Iconify";

export type DialogContent = {
  onFinish: (data: any) => void;
  onCancel: () => void;
};

const Dialog = NiceModal.create<{
  Content: any;
  params: any;
}>(({ Content, params = {} }) => {
  const modal = useModal();

  const handleClose = useCallback(() => {
    modal.hide();
  }, [modal]);

  const handleFinish = useCallback(
    (d) => {
      modal.resolve(d);
      modal.hide();
    },
    [modal]
  );

  return (
    <MuiDialog
      maxWidth="md"
      open={modal.visible}
      onClose={handleClose}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <IconButton
        onClick={handleClose}
        style={{
          position: "absolute",
          margin: 8,
        }}
      >
        <Iconify
          icon="eva:close-fill"
          onClick={handleClose}
          style={{
            justifyContent: "flex-start",
            alignSelf: "flex-start",
          }}
        />
      </IconButton>
      <div style={{ padding: 40 }}>
        <Content onCancel={handleClose} onFinish={handleFinish} {...params} />
      </div>
    </MuiDialog>
  );
});

export default Dialog;
