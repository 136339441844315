import { useState } from "react";
import * as Yup from "yup";
import palette from "src_common/theme/palette";
import {
  Box, Button, Container, DialogActions,
  Grid, List, ListItem, Stack, Typography,
  CircularProgress, FormControl, MenuItem, InputLabel, Select,
  IconButton, Divider, Avatar, Checkbox, InputAdornment,
} from "@mui/material";
import { useAPI } from "src_common/hooks/useAPI";
import { useEffect } from "react";
import { createTaskComment, getTaskComments, TaskCommentForm, TaskCommentDefaultValue, TaskComment, TaskCommentUpdateForm, updateTaskComment } from "src_lawfirm/api/task-comments";
import { Task, TaskPriority, TaskStatus, updateTask } from "src_lawfirm/api/tasks";
import { FormProvider, RHFTextField } from "src_common/components/hook-form";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";
import _ from "lodash";
import "./taskComments.css"
import CustomIcon from "src_common/components/custom-icon";
import useAuth from "src_common/hooks/useAuth";
import { priorityColor } from "../TasksTab";
import CloseIcon from '@mui/icons-material/Close';
import EastIcon from '@mui/icons-material/East';

const TaskCommentSchema = Yup.object().shape({
  comment: Yup.string().required("Comment is required"),
});

const TaskCommentUpdateSchema = Yup.object().shape({
  comment: Yup.string().required("Comment is required"),
});

interface IProps {
  task: Task | null | undefined;
  setCommentPanel: (val: Task | null) => void;
}


export const TaskCommentsList = ({ task, setCommentPanel }: IProps) => {
  const getCommentsAPI = useAPI(getTaskComments);

  const [priority, setPriority] = useState<TaskPriority>(TaskPriority.STANDARD);
  const [status, setStatus] = useState<TaskStatus>(TaskStatus.TO_DO);
  const [notify, setNotify] = useState<boolean>(task?.notify_flag || false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [editComment, setEditComment] = useState<TaskComment | null>(null);
  const { user } = useAuth();

  const handleIsDisabled = () => {
    setIsDisabled(!isDisabled);
  }

  function getIcon(name: string) {
    return <CustomIcon name={name} sx={{ p: 0.1 }} />;
  }

  useEffect(() => {
    const taskId = task ? task._id : '';
    getCommentsAPI.invoke(taskId);
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (task) {
      setPriority(task.priority);
      setStatus(task.status);
    }
  }, [task])

  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm<TaskCommentForm>({
    resolver: yupResolver(TaskCommentSchema),
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const methodsUpdate = useForm<TaskCommentUpdateForm>({
    resolver: yupResolver(TaskCommentUpdateSchema),
  });

  const {
    reset: commentReset,
    handleSubmit: updateSubmit,
    formState: { isSubmitting: isSub },
  } = methodsUpdate;
  const commitTaskChanges = async (event: any) => {
    try {
      event.preventDefault();
      !isDisabled && handleIsDisabled();
      const taskId = task ? task._id : '';
      const body: any = {
        title: task!.title,
        description: task!.description,
        priority,
        status,
        category: task!.category,
        assigned_to: task!.assigned_to._id,
        notify_flag: !notify,
        matter: task!.matter._id,
      };
      await updateTask(body, taskId);
      enqueueSnackbar('Task successfully updated');
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const onSubmit = async (form: TaskCommentForm) => {
    try {
      if (task && task._id) {
        const taskComment = await createTaskComment({
          ...form,
          task: task?._id,
        });

        reset(TaskCommentDefaultValue)
        enqueueSnackbar(`New task comment ${taskComment.comment} is created`);
        const taskId = task._id;
        getCommentsAPI.invoke(taskId);
      }
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    }
  }

  const updateComment = async (form: TaskCommentUpdateForm) => {
    try {
      if (task && task._id && editComment) {
        await updateTaskComment({
          ...form,
          task: task?._id,
        }, editComment._id);

        setEditComment(null);
        commentReset(TaskCommentDefaultValue)
        enqueueSnackbar(`Task comment is updated`);
        const taskId = task._id;
        getCommentsAPI.invoke(taskId);
      }
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    }
  }

  const statusColor: { [key: string]: string } = {
    TO_DO: palette.yao.secondary[6],
    IN_PROGRESS: palette.yao.secondary[4],
    COMPLETED: palette.yao.primary[3],
  };

  const selectCommentForEdit = (comment: TaskComment) => {
    setEditComment(comment);
    methodsUpdate.setValue('comment', comment.comment);
  };

  return (
    <>
      <Box>
        <IconButton onClick={() => setCommentPanel(null)}><CloseIcon /></IconButton>
      </Box>
      <Container sx={{ width: "450px" }}>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <FormControl sx={{ m: 1, ml: 0, minWidth: 120 }} variant="standard">
              <InputLabel id="priority_label"></InputLabel>
              <Select
                className="select-list"
                labelId="priority_label"
                id="priority"
                value={priority}
                label="Priority"
                onChange={(e) => setPriority(e.target.value as TaskPriority)}
                disabled={isDisabled}
                disableUnderline
                sx={{
                  border: "0", pl: "10px", color: priorityColor[priority], background: priorityColor[priority.toLowerCase()],
                }}
              >
                {Object.values(TaskPriority).map((priority) => {
                  return <MenuItem value={priority}>{_.startCase(_.capitalize(priority))}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl sx={{ m: 1, ml: 0, minWidth: 100 }} variant="standard">
              <InputLabel id="status_label"></InputLabel>
              <Select
                className="select-list"
                labelId="status_label"
                id="status"
                value={status}
                label="Status"
                onChange={(e) => setStatus(e.target.value as TaskStatus)}
                disabled={isDisabled}
                disableUnderline
                sx={{
                  border: "1px solid #E1E5EF", color: priorityColor[status], pl: "10px", width: "fit-content", background: "transparent",
                  "&.Mui-disabled": {
                    opacity: 1,
                    color: '#fff',
                  },
                }}
              >
                {Object.values(TaskStatus).map((status) => {
                  return <MenuItem value={status}>{_.startCase(_.capitalize(status))}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4} className="comments-icons">
            <IconButton onClick={handleIsDisabled}>{getIcon("pencil")}</IconButton>
            <IconButton onClick={commitTaskChanges} disabled={isDisabled}>{getIcon("tickCircle")}</IconButton>
          </Grid>
        </Grid>

        <Box textAlign="left">
          <Typography sx={{ fontSize: "24px", backgroundColor: "#FFF" }}>
            {task?.title}
          </Typography>
        </Box>

        <Grid container>
          <Grid item xs={4}>
            <Typography sx={{ px: 0, color: palette.yao.grey[7], fontSize: "11px" }}>
              DESCRIPTION
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Divider sx={{ borderStyle: "solid", borderColor: palette.yao.grey[4], marginTop: "8px" }} />
          </Grid>

          <Typography sx={{ fontSize: "24px" }}>
            {task?.description || 'NA'}
          </Typography>

          <Grid item xs={12} direction="row">
            <Divider sx={{ borderStyle: "solid", borderColor: palette.yao.grey[4], marginTop: "5px" }} />
          </Grid>
        </Grid>

        <Grid container pt="10px">
          <Grid item xs={6}>
            <Typography sx={{ color: palette.yao.grey[7], fontSize: "11px" }}>CATEGORY</Typography>
            <Typography>{task?.category}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography sx={{ color: palette.yao.grey[7], fontSize: "11px" }}>TIME</Typography>
            <Typography>{task?.estimate_time}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography sx={{ color: palette.yao.grey[7], fontSize: "11px" }}>DUE</Typography>
            <Typography sx={{ display: "flex", gap: "5px" }}>
              {task?.due_date && format(new Date(task?.due_date), "d.MM.yy")}
              <Box
                sx={{ fontSize: "8px", padding: "2px", border: "1px solid #E1E5EF", borderRadius: "3px", height: "16px", color: statusColor[task?.status || "TO_DO"], overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
              >
                {task?.status}
              </Box>
            </Typography>
          </Grid>
        </Grid>

        <Grid container pt="10px">
          <Grid item xs={6}>
            <Typography sx={{ color: palette.yao.grey[7], fontSize: "11px" }}>ASSIGNED TO</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                sx={{
                  mr: 1,
                  height: 22,
                  width: 22,
                  borderRadius: "3px",
                  fontWeight: 500,
                  fontSize: 11,
                }}
                alt={task?.assigned_to.name}
              >
                {task?.assigned_to.name.charAt(0).toUpperCase()}
              </Avatar>
              <Typography>{task?.assigned_to.name}</Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Typography sx={{ color: palette.yao.grey[7], fontSize: "11px" }}>AUTHOR</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                sx={{
                  mr: 1,
                  height: 22,
                  width: 22,
                  borderRadius: "3px",
                  fontWeight: 500,
                  fontSize: 11,
                }}
                alt={task?.author.name}
              >
                {task?.author.name.charAt(0).toUpperCase()}
              </Avatar>
              <Typography>{task?.author.name}</Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container pt="10px" >
          <Grid item xs={12} sx={{ display: "flex" }}>
            <Typography sx={{ color: palette.yao.grey[7], fontSize: "11px" }}>COMMENTS</Typography>
          </Grid>

          <Grid item xs={12}>
            <List sx={{ height: '200px', overflow: 'auto' }}>
              {getCommentsAPI.loading ? (
                <ListItem>
                  <CircularProgress size={20} />
                </ListItem>
              ) : (getCommentsAPI.data?.length ? (getCommentsAPI.data.map((r: TaskComment) => (
                <Grid sx={{ pb: 1, display: 'grid' }} justifyContent={r.author._id === user?._id ? 'right' : 'left'}>
                  <Box sx={{ display: 'flex' }}>
                    {r.author._id === user?._id && (
                      <IconButton onClick={() => selectCommentForEdit(r)}>{getIcon("pencil")}</IconButton>
                    )}

                    {r._id === editComment?._id ? (
                      <FormProvider methods={methodsUpdate} onSubmit={updateSubmit(updateComment)}>
                        <Stack sx={{ padding: "0" }}>
                          <RHFTextField
                            name="comment"
                            multiline
                            rows={5}
                            placeholder="Enter comment"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" sx={{ marginTop: '70px', display: 'flex', gap: '5px' }}>
                                  <IconButton onClick={() => setEditComment(null)}>
                                    <CloseIcon />
                                  </IconButton>
                                  <LoadingButton
                                    endIcon={<EastIcon sx={{ position: 'relative', right: '5px' }} />}
                                    type="submit"
                                    variant="contained"
                                    loading={isSub}
                                    sx={{ minWidth: '32px', padding: 0 }}
                                  />
                                </InputAdornment>
                              )
                            }}
                          />
                        </Stack>
                      </FormProvider>
                    ) : (
                      <ListItem
                        sx={{
                          backgroundColor: r.author._id === user?._id ? '#fff' : palette.yao.primary[4],
                          border: r.author._id === user?._id ? '1px solid #D7DEEE' : '0',
                          borderRadius: "4px",
                          padding: '10px',
                        }}
                      >
                        {r.comment}
                      </ListItem>
                    )}
                  </Box>
                  {r.author._id !== user?._id && (
                    <Typography color={palette.yao.primary[3]} fontSize="12px" sx={{ pt: "3px" }}>
                      {r.author.name}, {format(new Date(r.created_at), "d MMM yy")}
                    </Typography>
                  )}
                </Grid>
              ))) : "There are no comments right now.")}
            </List>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{ paddingTop: "20px" }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack style={{ padding: "0px" }}>
            <RHFTextField
              name="comment"
              multiline
              rows={5}
              placeholder="Enter comment"
              InputProps={{
                endAdornment: <LoadingButton
                  endIcon={<EastIcon sx={{ position: 'relative', right: '5px' }} />}
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  sx={{ marginTop: '70px', padding: 0, minWidth: '32px' }}
                />
              }}
            />
          </Stack>

          <Stack sx={{ marginTop: '20px' }}>
            <Typography variant="caption" display="flex" alignItems="center" sx={{ fontSize: '13px' }}>
              <Checkbox
                sx={{ transform: 'scale(1.3)', pl: 0 }}
                checked={notify}
                onClick={(event) => {
                  setNotify(!notify)
                  commitTaskChanges(event)
                }}
              />
              Notifications are turned on
            </Typography>
          </Stack>

          <DialogActions sx={{ paddingTop: "10px" }}>
            <Button variant="outlined" color="inherit" onClick={() => setCommentPanel(null)}>
              Cancel
            </Button>
          </DialogActions>
        </FormProvider>
      </Container>
    </>
  );
}
