import axios from "src_common/utils/axios";
import { formatError } from "src_common/utils/misc";
import { CaseType } from "./case-types";
import { TaskPriority } from "./tasks";
import { MatterStatus } from "./matters";

export enum AssigneeType {
  responsible_lawyer = "responsible_lawyer",
  responsible_supervisor = "responsible_supervisor",
  paralegal = "paralegal",
  secretary = "secretary",
}

export const AssigneeTypeToText = (assignee: AssigneeType): string => {
  return (
    {
      [AssigneeType.responsible_lawyer]: "Responsible Lawyer",
      [AssigneeType.responsible_supervisor]: "Responsible Supervisor",
      [AssigneeType.paralegal]: "Paralegal",
      [AssigneeType.secretary]: "Secretary",
    }[assignee] || ""
  );
};

export enum TaskAction {
  SIMPLE = "SIMPLE",
  WORKFLOW_TRIGGER = "WORKFLOW_TRIGGER",
  PRECEDENT = "PRECEDENT",
  FORM = "FORM",
  EMAIL = "EMAIL",
}

export const TaskActionToText = (action: TaskAction): string => {
  return (
    {
      [TaskAction.SIMPLE]: "Simple",
      [TaskAction.WORKFLOW_TRIGGER]: "Workflow Trigger",
      [TaskAction.PRECEDENT]: "Precedent",
      [TaskAction.FORM]: "Form",
      [TaskAction.EMAIL]: "Email",
    }[action] || ""
  );
};

export interface Workflow {
  _id: string;
  title: string;
  description: string;
  law_firm: string;
  department: string;
  case_type: string;
  author: string;
  last_updated_by?: string;
  created_at: Date;
  updated_at: Date;
}

export interface WorkflowTask {
  _id: string;
  title: string;
  law_firm: string;
  workflow: string;
  section: string;
  previous: string;
  last_updated_by: string;
  assignee: AssigneeType;
  action: TaskAction;
  input_id: string;
  created_at: Date;
  updated_at: Date;
  order: number;
  trigger_due_date_days: number;
  priority: TaskPriority;
  matter_status_trigger?: MatterStatus;
}

export interface WorkflowSection {
  _id: string;
  title: string;
  law_firm: string;
  workflow: string;
  previous?: string;
  last_updated_by: string;
  created_at: Date;
  updated_at: Date;
  order: number;
}

export interface WorkflowSectionList {
  _id: string;
  title: string;
  order: number;
  created_at: Date;
  tasks: WorkflowTask[];
}

export interface WorkflowList {
  case_type: CaseType;
  worflows: Workflow[];
}

export interface WorkflowTemplateCreateDto {
  title: string;
  description: string;
  case_type: string;
}

export interface WorkflowTemplateUpdateSectionDto {
  title: string;
}

export interface WorkflowTemplateCreateSectionDto
  extends WorkflowTemplateUpdateSectionDto {
  workflow: string;
  order: number;
}

export interface WorkflowTemplateTaskUpdateDto {
  title: string;
  assignee: AssigneeType | undefined;
  action: TaskAction;
  input_id: string | undefined;
  section: string;
  trigger_due_date_days: number;
  priority: string;
  matter_status_trigger: MatterStatus | undefined;
}

export interface WorkflowTemplateTaskCreateDto
  extends WorkflowTemplateTaskUpdateDto {
  order: number;
}

export interface MongooseUpdateResponse {
  acknowledged: boolean;
  matchedCount: number;
  modifiedCount: number;
  upsertedCount: number;
  upsertedId: number;
  deletedCount: number;
}

export async function listWorkflowTemplate(): Promise<WorkflowList[]> {
  try {
    const response = await axios.get<WorkflowList[]>("/workflows");
    return response.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function createWorkflowTemplate(
  form: WorkflowTemplateCreateDto
): Promise<Workflow> {
  try {
    const response = await axios.post<Workflow>("/workflows", form);
    return response.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function duplicateWorkflowTemplate(id: string): Promise<Workflow> {
  try {
    const response = await axios.post<Workflow>(
      `/workflows/${id}/duplicate`,
      {}
    );
    return response.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function updateWorkflowTemplate(
  id: string,
  form: WorkflowTemplateCreateDto
): Promise<MongooseUpdateResponse> {
  try {
    const response = await axios.put<MongooseUpdateResponse>(
      `/workflows/${id}`,
      form
    );
    return response.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function findWorkflowTemplateById(id: string): Promise<Workflow> {
  try {
    const response = await axios.get<Workflow>(`/workflows/${id}`);
    return response.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function createWorkflowTemplateSection(
  form: WorkflowTemplateCreateSectionDto
): Promise<WorkflowSectionList> {
  try {
    const response = await axios.post<WorkflowSectionList>(
      "/workflows/sections",
      form
    );
    return { ...response.data, tasks: [] };
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function updateWorkflowTemplateSection(
  id: string,
  form: WorkflowTemplateUpdateSectionDto
): Promise<MongooseUpdateResponse> {
  try {
    const response = await axios.put<MongooseUpdateResponse>(
      `/workflows/sections/${id}`,
      form
    );
    return response.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function deleteWorkflow(
  id: string
): Promise<MongooseUpdateResponse> {
  try {
    const response = await axios.delete<MongooseUpdateResponse>(
      `/workflows/${id}`
    );
    return response.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function deleteWorkflowTemplateSection(
  id: string
): Promise<MongooseUpdateResponse> {
  try {
    const response = await axios.delete<MongooseUpdateResponse>(
      `/workflows/sections/${id}`
    );
    return response.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function findWorkflowTemplateSections(
  id: string
): Promise<WorkflowSectionList[]> {
  try {
    const response = await axios.get<WorkflowSectionList[]>(
      `/workflows/${id}/sections`
    );
    return response.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function createWorkflowTemplateTask(
  form: WorkflowTemplateTaskCreateDto
): Promise<WorkflowTask> {
  try {
    const response = await axios.post<WorkflowTask>("/workflows/tasks", form);
    return response.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function updateWorkflowTemplateTask(
  id: string,
  form: WorkflowTemplateTaskUpdateDto
): Promise<MongooseUpdateResponse> {
  try {
    const response = await axios.put<MongooseUpdateResponse>(
      `/workflows/tasks/${id}`,
      form
    );
    return response.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function deleteWorkflowTemplateTask(
  id: string
): Promise<MongooseUpdateResponse> {
  try {
    const response = await axios.delete<MongooseUpdateResponse>(
      `/workflows/tasks/${id}`
    );
    return response.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function findWorkflowTemplateTaks(
  id: string
): Promise<WorkflowTask[]> {
  try {
    const response = await axios.get<WorkflowTask[]>(`/workflows/${id}/tasks`);
    return response.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}
