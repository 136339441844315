import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import _ from "lodash";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { DialogContent } from "src_common/components/dialog/Dialog";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { FormProvider } from "src_common/components/hook-form";
import {
  YAOFieldAutocomplete,
  YAOFieldAutocompleteMultiple,
  YAOFieldAutocompleteOptionObject,
  YAOFieldDatePicker,
  YAOFieldText,
} from "src_common/components/yao-form-fields";
import { YaoFormFieldLabel } from "src_common/components/yao-form/YaoForm";
import { useAPI } from "src_common/hooks/useAPI";
import { getAttorneys } from "src_lawfirm/api/attorneys";
import palette from "src_common/theme/palette";
import {
  TaskCategory,
  TaskFilter,
  TaskPriority,
  TaskStatus,
} from "src_lawfirm/api/tasks";

export type TaskFilterForm = {
  matter: string[];
  priority: string[];
  status: string[];
  category: string[];
  due_date?: Date;
  estimate_time?: number;
  assigned_to?: YAOFieldAutocompleteOptionObject;
  author?: YAOFieldAutocompleteOptionObject;
  completed_date_start?: Date;
  completed_date_end?: Date;
  search: string;
  sort?: TaskSort
};

export enum TaskSort {
  DUE_DATE = 'DUE_DATE',
  COMPLETED = 'COMPLETED',
}

type TaskFiltersProps = DialogContent & {
  filters: TaskFilter;
};

export const TaskFilters = ({
  filters,
  onFinish,
  onCancel,
}: TaskFiltersProps) => {
  const attorneysAPI = useAPI(getAttorneys);

  const methods = useForm<TaskFilter>({
    defaultValues: filters,
  });

  useEffect(() => {
    attorneysAPI.invoke();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(onFinish)}>
      <Box sx={{ width: "640px" }}>
        <Grid container rowSpacing={1.5} columnSpacing={2.5}>
          <Grid item xs={12}>
            <Typography variant="h2" align="center">
              Filter tasks
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <YaoFormFieldLabel name="priority" label="Priority" />
            <YAOFieldAutocompleteMultiple
              name="priority"
              label=""
              placeholder="Choose priority"
              options={Object.values(TaskPriority)}
              getOptionLabel={(v) => _.startCase(_.lowerCase(v))}
              customClassname="multi-tag"
            />
          </Grid>
          <Grid item xs={6}>
            <YaoFormFieldLabel name="status" label="Status of the task" />
            <YAOFieldAutocompleteMultiple
              name="status"
              label=""
              placeholder="Choose status of the task"
              options={Object.values(TaskStatus)}
              getOptionLabel={(v) => _.startCase(_.lowerCase(v))}
              customClassname="multi-tag"
            />
          </Grid>
          <Grid item xs={3}>
            <YaoFormFieldLabel name="due_date" label="Due Date" />
            <YAOFieldDatePicker
              name="due_date"
              textFieldProps={{
                sx: {
                  svg: {
                    color: palette.yao.grey[7],
                    height: "14px",
                    width: "14px",
                  },
                },
              }}
              datePickerProps={{
                views: ["year", "month", "day"],
                components: {
                  OpenPickerIcon: CalendarTodayOutlinedIcon,
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <YaoFormFieldLabel name="category" label="Category" />
            <YAOFieldAutocompleteMultiple
              name="category"
              label=""
              placeholder="Choose category"
              options={Object.values(TaskCategory)}
              getOptionLabel={(v) => _.startCase(_.lowerCase(v))}
              customClassname="multi-tag"
            />
          </Grid>
          <Grid item xs={3}>
            <YaoFormFieldLabel
              name="estimate_time"
              label="Estimate Time (minutes)"
            />
            <YAOFieldText
              name="estimate_time"
              placeholder="Minutes"
              type="number"
            />
          </Grid>
          <Grid item xs={6} md={6} marginBottom={2}>
            <YaoFormFieldLabel name="assigned_to" label="Assigned to" />
            <YAOFieldAutocomplete
              name="assigned_to"
              label=""
              placeholder="Assigned to"
              options={(attorneysAPI?.data || [])
                .map((a) => ({
                  label: a.name,
                  value: a._id,
                }))
                .concat({
                  label: "All",
                  value: "all",
                })}
            />
          </Grid>
          <Grid item xs={6} md={6} marginBottom={2}>
            <YaoFormFieldLabel name="author" label="Author" />
            <YAOFieldAutocomplete
              name="author"
              label=""
              placeholder="Author"
              options={(attorneysAPI?.data || []).map((a) => ({
                label: a.name,
                value: a._id,
              }))}
            />
          </Grid>
        </Grid>
        <Stack
          direction="row"
          sx={{ mt: 4, justifyContent: "right" }}
          spacing={2}
        >
          <Button color="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="contained" sx={{ minWidth: 100 }} type="submit">
            Apply
          </Button>
        </Stack>
      </Box>
    </FormProvider>
  );
};
