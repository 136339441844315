import axios from 'src_common/utils/axios';
import { formatError } from 'src_common/utils/misc';
import { Attorney } from './attorneys';
import { Matter } from './matters';
import { YAOFieldAutocompleteOptionObject } from 'src_common/components/yao-form-fields/YAOFieldAutocomplete';
import { TaskComment } from './task-comments';
import { Contact } from './contacts';
import {TaskSort} from "../pages/matters/TaskForms/task-filters";

export async function createTask(form: TaskCreateForm): Promise<Task> {
  try {
    const res = await axios.post('/tasks', form);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function updateTask(
  form: TaskUpdateForm,
  id: string
): Promise<Task> {
  try {
    const res = await axios.patch(`/tasks/${id}`, form);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function updateTaskStatus(form: TaskStatusForm): Promise<Task> {
  try {
    const res = await axios.patch(`/tasks/${form.id}/status`, {
      status: form.status.value,
    });
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}
export interface TaskResponse {
  limit: number;
  rows: Task[];
  search: string;
  total: number;
}

export async function getTasks(params: TaskFilter, incrementPage = false): Promise<TaskResponse> {
  const res = await axios.get<TaskResponse>('tasks', {
    params: incrementPage ? { ...params, page: params.page + 1 } : params
  });
  return res.data;
}

export enum TaskPriority {
  URGENT = 'URGENT',
  CRITICAL_DATE = 'CRITICAL_DATE',
  STANDARD = 'STANDARD',
  LOW = 'LOW',
}

export const TaskPriorityToText = (priority: TaskPriority): string => ({
  [TaskPriority.URGENT]: "Urgent",
  [TaskPriority.CRITICAL_DATE]: "Critical date",
  [TaskPriority.STANDARD]: "Standard",
  [TaskPriority.LOW]: "Low",
}[priority] || '')

export enum TaskStatus {
  TO_DO = 'TO_DO',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export enum TaskCategory {
  CALL = 'CALL',
  EMAIL = 'EMAIL',
  DOCUMENT = 'DOCUMENT',
  FORM = 'FORM',
  SIMPLE = 'SIMPLE',
  PRECEDENT = 'PRECEDENT',
  WORKFLOW_TRIGGER = 'WORKFLOW_TRIGGER',
}

export const TaskCategoryToText = (category: TaskCategory): string => ({
  [TaskCategory.CALL]: "Call",
  [TaskCategory.EMAIL]: "Email",
  [TaskCategory.DOCUMENT]: "Document",
  [TaskCategory.FORM]: "Form",
  [TaskCategory.SIMPLE]: "Simple",
  [TaskCategory.PRECEDENT]: "Precedent",
  [TaskCategory.WORKFLOW_TRIGGER]: "Workflow Trigger",
}[category] || '')

export type Task = {
  title: string;
  priority: TaskPriority;
  status: TaskStatus;
  category?: TaskCategory;
  due_date?: Date;
  matter: Matter;
  matter_section?: string;
  clients: Array<Contact>;
  estimate_time?: number;
  assigned_to: Attorney;
  law_firm: string;
  author: Attorney;
  notify_flag: boolean;
  description: string;
  task_comments?: number;
  last_comment?: TaskComment;
  comment_author?: Attorney;
  _id: string;
  created_at: Date;
  updated_at: Date;
  __v: number;
  input_id?: string;
  completed_date?: Date;
};

export type TaskForm = {
  title: string;
  priority: YAOFieldAutocompleteOptionObject;
  status: YAOFieldAutocompleteOptionObject;
  category: YAOFieldAutocompleteOptionObject;
  due_date?: Date;
  estimate_time?: number;
  assigned_to: string;
  description: string;
  matter: string;
  notify_flag: boolean;
};

export type TaskUpdateForm = Partial<
  Pick<
    TaskForm,
    | 'title'
    | 'assigned_to'
    | 'description'
    | 'due_date'
    | 'estimate_time'
    | 'matter'
    | 'notify_flag'
  >
> & {
  priority?: string;
  status?: string;
  category?: string;
};

export type TaskCreateForm = {
  title: string;
  priority: string;
  status: string;
  category: string;
  due_date?: Date;
  estimate_time?: number;
  assigned_to: string;
  description: string;
  matter: string;
  notify_flag: boolean;
};

export type TaskFormList = {
  title: string;
  priority: TaskPriority;
  status: TaskStatus;
  category: TaskCategory;
  due_date?: Date;
  estimate_time?: number;
  assigned_to: string;
  description: string;
  matter: string;
  notify_flag: boolean;
};

export type TaskStatusForm = {
  status: YAOFieldAutocompleteOptionObject;
  id: string;
};

export type TaskFilter = {
  matter: string[];
  priority: string[];
  status: string[];
  category: string[];
  due_date?: Date;
  estimate_time?: number;
  assigned_to?: string;
  author?: string;
  search: string;
  page: number;
  limit: number;
  completed_date_start?: Date;
  completed_date_end?: Date;
  sort?: TaskSort
};
