import SvgIconStyle from "src_common/components/SvgIconStyle";
import { PATH_HOME, PATH_LAW_FIRMS, PATH_YAOERS, PATH_FORMS } from "./paths";

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  user: getIcon("ic_user"),
  dashboard: getIcon("ic_dashboard"),
  banking: getIcon("ic_banking"),
  forms: getIcon("ic_forms")
};

const navConfig = [
  {
    subheader: "",
    items: [
      {
        title: "Home",
        path: PATH_HOME.root,
        icon: ICONS.dashboard,
      },
      {
        title: "YAOers",
        path: PATH_YAOERS.root,
        icon: ICONS.user,
      },
      {
        title: "Law Firms",
        path: PATH_LAW_FIRMS.root,
        icon: ICONS.banking,
      },
      {
        title: "Forms",
        path: PATH_FORMS.root,
        icon: ICONS.forms,
      },
    ],
  },
];

export default navConfig;
