import { Theme } from "@mui/material/styles";
import palette from "../palette";

export default function Input(theme: Theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            "& svg": { color: theme.palette.text.disabled },
          },
          borderRadius: 6,
          fontSize: 13,
          "MuiOutlinedInput-root": {
            padding: 0,
          },
        },
        sizeSmall: {
          height: 32,
          fontSize: 13,
        },
        input: {
          "&::placeholder": {
            opacity: 1,
            color: palette.yao.grey[7],
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          "&:before": {
            borderBottomColor: theme.palette.grey[500_56],
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[500_12],
          "&:hover": {
            backgroundColor: theme.palette.primary.lighter,
          },
          "&.Mui-focused": {
            backgroundColor: theme.palette.primary.lighter,
          },
          "&.Mui-disabled": {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
        underline: {
          "&:before": {
            borderBottomColor: theme.palette.grey[500_56],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: "10.5px 16px",
          borderRadius: 4,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.grey[500_32],
          },
          "&.Mui-disabled": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.action.disabledBackground,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 11,
          fontWeight: 600,
          color: palette.yao.grey[7],
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        },
      },
    },
  };
}
