import {
  Box,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from "@mui/material";
import {ComponentProps, ReactNode, useEffect, useState} from "react";
import useTable from "src_common/hooks/useTable";
import { TableHeadCustom, TableNoData, TableSkeleton } from "../table";
import { Column, Row } from "./types";
import YaoTableRow, { RowOption, RowOptionFn } from "./YaoTableRow";

type ToolbarFn = (selected: string[]) => ReactNode;

export type YaoTableProps = {
  className?: string;
  label?: string;
  labelNoData?: string;
  imgNoData?: string;
  rows: Row[];
  columns: Column[];
  toolbar?: ReactNode | ToolbarFn;
  isLoading?: boolean;
  isPagging?: boolean;
  rowOptions?: RowOption[] | RowOptionFn;
  rowProps?: ComponentProps<typeof YaoTableRow>["rowProps"];
  sx?: { [key: string]: any };
  selectable?: boolean;
  afterRows?: ReactNode | ToolbarFn;
  rowClick?: (row: Row, e?: any) => void;
  displayHeader?: boolean;
  onSelectionChange?: (ids: string[]) => void;
  tableLayoutFixed?: boolean;
  selectorCondition?: (row: Row) => boolean;
};

export default function YaoTable({
  className,
  label,
  labelNoData,
  imgNoData,
  isLoading,
  isPagging,
  rows,
  columns,
  rowOptions,
  toolbar,
  sx,
  afterRows,
  selectable = false,
  rowProps,
  rowClick,
  displayHeader = true,
  onSelectionChange,
  tableLayoutFixed = false,
  selectorCondition
}: YaoTableProps) {
  const {
    dense,
    order,
    orderBy,
    rowsPerPage,
    onSort,
    onSelectRow,
    onSelectAllRows,
    selected,
  } = useTable({
    defaultOrderBy: "createdAt",
  });

  const isNotFound = !isLoading && !isPagging && !rows.length;
  const [allSelected, setAllSelected] = useState<boolean>(false)

  useEffect(() => {
    onSelectionChange && onSelectionChange(selected);
  }, [selected])

  useEffect(() => {
    if(Array.isArray(rows) && onSelectAllRows) {
      onSelectAllRows(
          allSelected,
          rows.map((r) => String(r._id))
      )
    }
  }, [rows])

  return (
    <Box className={className} sx={{ height: "100%", ...sx }}>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
        <Typography sx={{ fontSize: 18, fontWeight: 600 }}>{label}</Typography>
        <Box sx={{ flexGrow: 1 }} />
        {typeof toolbar === "function" ? toolbar(selected) : toolbar}
      </Stack>

      <TableContainer
        sx={{
          position: "relative",
          padding: "1px",
          pb: 1,
          "&::-webkit-scrollbar": {
            height: 3,
          },
          "&::-webkit-scrollbar-track": {
            ml: 3,
            mr: 3,
            backgroundColor: "#D8E4E4",
            borderRadius: 2,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#0186B0",
            borderRadius: 2,
          },
        }}
      >
        <Table
          size={dense ? "small" : "medium"}
          sx={{
            overflow: tableLayoutFixed ? "visible" : "scroll",
            tableLayout: tableLayoutFixed ? "fixed" : "auto",
          }}
        >
          {displayHeader && (
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={columns}
              rowCount={rows.length}
              onSort={onSort}
              selectable={selectable}
              onSelectAllRows={(c) => {
                onSelectAllRows(
                    c,
                    rows.map((r) => String(r._id))
                )
                setAllSelected(c)
              }}
              numSelected={selected.length}
              sx={{
                "& .MuiButtonBase-root": {
                  textTransform: "capitalize !important",
                },
              }}
            />
          )}
          <TableBody>
            {(isLoading ? [...Array(rowsPerPage)] : rows).map((row, index) =>
              row ? (
                <YaoTableRow
                  key={row._id}
                  row={row}
                  columns={columns}
                  rowOptions={rowOptions}
                  rowProps={rowProps}
                  selectable={selectable}
                  checked={selected.includes(String(row._id))}
                  onSelectRow={onSelectRow}
                  rowClick={rowClick}
                  selectorCondition={selectorCondition}
                />
              ) : (
                !isNotFound && <TableSkeleton key={index} />
              )
            )}

            {!isNotFound &&
              isPagging &&
              [...Array(rowsPerPage)].map((_, index) => (
                <TableSkeleton key={index} />
              ))}

            {typeof afterRows === "function" ? afterRows(selected) : afterRows}

            <TableNoData
              isNotFound={isNotFound}
              label={labelNoData}
              img={imgNoData || ''}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
