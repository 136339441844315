export const isImageFile = (fileName: string | undefined) => {
  if (!fileName) return false;
  return /\.(jpe?g|png|gif|tiff|bmp|heic|svg|webp})$/m.test(
    fileName?.toLowerCase()
  );
};

export const extensionMimes = [
  { type: "doc", mime: ["application/msword"] },
  {
    type: "docx",
    mime: [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
  },
  {
    type: "xlsx",
    mime: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
  },
  {
    type: "xls",
    mime: [
      "application/excel",
      "application/vnd.ms-excel",
      "application/msexcel",
    ],
  },
  {
    type: "pptx",
    mime: [
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ],
  },
  {
    type: "ppt",
    mime: ["application/vnd.ms-powerpoint"],
  },
  { type: "pdf", mime: ["application/pdf"] },
  {
    type: "mov",
    mime: ["video/quicktime"],
  },
  {
    type: "mpeg",
    mime: ["video/mpeg"],
  },
  {
    type: "mp4",
    mime: ["video/mp4"],
  },
  {
    type: "mpeg",
    mime: ["audio/mpeg"],
  },
  {
    type: "mp3",
    mime: ["audio/mp3"],
  },
  {
    type: "webm",
    mime: ["audio/webm"],
  },
  {
    type: "jpg",
    mime: ["image/jpeg", "image/jpg"],
  },
  {
    type: "png",
    mime: ["image/png"],
  },
  {
    type: "webp",
    mime: ["image/webp"],
  },
  {
    type: "gif",
    mime: ["image/gif"],
  },
  {
    type: "svg",
    mime: ["image/svg+xml"],
  },
  {
    type: "tiff",
    mime: ["image/tiff"],
  },
  {
    type: "bmp",
    mime: ["image/bmp"],
  },
  { type: "link", mime: ["text/uri-list"] },
];

/**
 * Parameter: mime content-type
 *
 * Return: predefined extension
 */
export const getFileTypeByMime = (mime: string) => {
  let type;
  for (let extension of extensionMimes) {
    if (extension.mime.find((m) => m === mime)) {
      type = extension.type;
      break;
    }
  }
  return type;
};

/**
 * Parameter: fileOrExt: file name or extension with "."
 *
 * Return: icon name for the extension | undefined
 */
export const getAttachmentIconName = (fileOrExt: string | undefined) => {
  if (!fileOrExt) return;
  const ext = fileOrExt.split(".").pop()?.toLocaleLowerCase() || "";
  if (ext === "jpg" || ext === "jpeg") return "jpg";
  if (ext === "png") return "png";
  if (isImageFile(ext)) return "image_type";
  if (ext === "pdf") return "pdf";
  if (ext === "doc" || ext === "docx") return "doc";
  if (ext === "xls" || ext === "xlsx") return "xls";
  return "clip";
};
