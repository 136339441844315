import ClassicEditorBase from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import AutoLink from "@ckeditor/ckeditor5-link/src/autolink";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Code from "@ckeditor/ckeditor5-basic-styles/src/code";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import FindAndReplace from "@ckeditor/ckeditor5-find-and-replace/src/findandreplace";
import Font from "@ckeditor/ckeditor5-font/src/font";
import GeneralHtmlSupport from "@ckeditor/ckeditor5-html-support/src/generalhtmlsupport";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight";
import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";
import ListProperties from "@ckeditor/ckeditor5-list/src/listproperties";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";
import RemoveFormat from "@ckeditor/ckeditor5-remove-format/src/removeformat";
import SelectAll from "@ckeditor/ckeditor5-select-all/src/selectall";
import SourceEditing from "@ckeditor/ckeditor5-source-editing/src/sourceediting";
import SpecialCharacters from "@ckeditor/ckeditor5-special-characters/src/specialcharacters";
import SpecialCharactersArrows from "@ckeditor/ckeditor5-special-characters/src/specialcharactersarrows";
import SpecialCharactersCurrency from "@ckeditor/ckeditor5-special-characters/src/specialcharacterscurrency";
import SpecialCharactersLatin from "@ckeditor/ckeditor5-special-characters/src/specialcharacterslatin";
import SpecialCharactersMathematical from "@ckeditor/ckeditor5-special-characters/src/specialcharactersmathematical";
import SpecialCharactersText from "@ckeditor/ckeditor5-special-characters/src/specialcharacterstext";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import Style from "@ckeditor/ckeditor5-style/src/style";
import Subscript from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import Superscript from "@ckeditor/ckeditor5-basic-styles/src/superscript";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableCaption from "@ckeditor/ckeditor5-table/src/tablecaption";
import TableCellProperties from "@ckeditor/ckeditor5-table/src/tablecellproperties";
import TableColumnResize from "@ckeditor/ckeditor5-table/src/tablecolumnresize";
import TableProperties from "@ckeditor/ckeditor5-table/src/tableproperties";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import WordCount from "@ckeditor/ckeditor5-word-count/src/wordcount";
import ToolbarView from "@ckeditor/ckeditor5-ui/src/toolbar/toolbarview";
import YaoImageCkEditorPlugin from "./ckeditor_yaoimage_plugin";
import {
  YaoCheatSheetCCBrokerPlugin,
  YaoCheatSheetCCEstateAgentPlugin,
  YaoCheatSheetCCLenderPlugin,
  YaoCheatSheetCCSolicitorPlugin,
  YaoCheatSheetClientCompanyPlugin,
  YaoCheatSheetClientPlugin,
  YaoCheatSheetCCCounselPlugin,
  YaoCheatSheetCCExpertPlugin,
  YaoCheatSheetCCInsurerPlugin,
  YaoCheatSheetCCLandlordPlugin,
  YaoCheatSheetCCManagementCompanyPlugin,
  YaoCheatSheetCCLocalAuthorityPlugin,
  YaoCheatSheetCCOtherPartyPlugin,
  YaoCheatSheetCCTenantPlugin,
  YaoCheatSheetCCSearchProviderPlugin,
  YaoCheatSheetCCCourtPlugin,
  YaoCheatSheetMatterDetailsPlugin,
  YaoCheatSheetLawFirmPlugin,
  YaoCheatSheetMRLawyerPlugin,
  YaoCheatSheetMRSupervisorPlugin,
  YaoCheatSheetMRCurrentUserPlugin,
  YaoCheatSheetMRSecretaryPlugin,
  YaoCheatSheetMRParalegalPlugin,
  YaoFormFieldCheatSheetPlugin,
} from "./ckeditor_yaovar_plugin";

export const CKEDITOR_DEFAULT_TOOLBAR: any[] = [
  "bold",
  "italic",
  "underline",
  "strikethrough",
  "|",
  "fontFamily",
  "fontColor",
  "fontBackgroundColor",
  "fontSize",
  "|",
  "alignment:left",
  "alignment:right",
  "alignment:center",
  "alignment:justify",
  "|",
  "bulletedList",
  "numberedList",
  "outdent",
  "indent",
];

export const CKEDITOR_IMAGE_TOOLBAR = [
  ...CKEDITOR_DEFAULT_TOOLBAR,
  "|",
  "yaoImageUpload",
];

export const CKEDITOR_VAR_TOOLBAR = [
  "sourceEditing",
  "|",
  {
    label: "Clients Details",
    withText: true,
    icon: false,
    items: ["yaoCheatSheetClient", "yaoCheatSheetClientCompany"],
  },
  {
    label: "Case Contacts",
    withText: true,
    icon: false,
    items: [
      "yaoCheatSheetCCSolicitor",
      "yaoCheatSheetCCLender",
      "yaoCheatSheetCCBroker",
      "yaoCheatSheetCCEstateAgent",
      "yaoCheatSheetCCCounsel",
      "yaoCheatSheetCCExpert",
      "yaoCheatSheetCCInsurer",
      "yaoCheatSheetCCLandlord",
      "yaoCheatSheetCCManagementCompany",
      "yaoCheatSheetCCLocalAuthority",
      "yaoCheatSheetCCOtherParty",
      "yaoCheatSheetCCTenant",
      "yaoCheatSheetCCSearchProvider",
      "yaoCheatSheetCCCourt",
    ],
  },
  {
    label: "Matter",
    withText: true,
    icon: false,
    items: [
      "yaoCheatSheetMatterDetails",
      "yaoCheatSheetMRLawyer",
      "yaoCheatSheetMRSupervisor",
      "yaoCheatSheetMRCurrentUser",
      "yaoCheatSheetMRSecretary",
      "yaoCheatSheetMRParalegal",
    ],
  },
  "yaoFormFieldCheatSheet",
  "yaoCheatSheetLawFirm",
  "|",
  ...CKEDITOR_DEFAULT_TOOLBAR,
];

class ClassicEditor extends ClassicEditorBase {}

ClassicEditor.builtinPlugins = [
  Alignment,
  Autoformat,
  AutoLink,
  BlockQuote,
  Bold,
  Code,
  CodeBlock,
  Essentials,
  FindAndReplace,
  Font,
  GeneralHtmlSupport,
  Heading,
  Highlight,
  HorizontalLine,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  ListProperties,
  MediaEmbed,
  Paragraph,
  PasteFromOffice,
  RemoveFormat,
  SelectAll,
  SourceEditing,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Strikethrough,
  Style,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  Underline,
  WordCount,
  ToolbarView,
  YaoImageCkEditorPlugin,
  YaoCheatSheetClientPlugin,
  YaoCheatSheetClientCompanyPlugin,
  YaoCheatSheetCCSolicitorPlugin,
  YaoCheatSheetCCLenderPlugin,
  YaoCheatSheetCCBrokerPlugin,
  YaoCheatSheetCCEstateAgentPlugin,
  YaoCheatSheetCCCounselPlugin,
  YaoCheatSheetCCExpertPlugin,
  YaoCheatSheetCCInsurerPlugin,
  YaoCheatSheetCCLandlordPlugin,
  YaoCheatSheetCCManagementCompanyPlugin,
  YaoCheatSheetCCLocalAuthorityPlugin,
  YaoCheatSheetCCOtherPartyPlugin,
  YaoCheatSheetCCTenantPlugin,
  YaoCheatSheetCCSearchProviderPlugin,
  YaoCheatSheetCCCourtPlugin,
  YaoCheatSheetMatterDetailsPlugin,
  YaoCheatSheetLawFirmPlugin,
  YaoCheatSheetMRLawyerPlugin,
  YaoCheatSheetMRSupervisorPlugin,
  YaoCheatSheetMRCurrentUserPlugin,
  YaoCheatSheetMRSecretaryPlugin,
  YaoCheatSheetMRParalegalPlugin,
  YaoFormFieldCheatSheetPlugin,
];

ClassicEditor.defaultConfig = {
  toolbar: {
    items: CKEDITOR_DEFAULT_TOOLBAR,
    shouldNotGroupWhenFull: false,
  },
  fontFamily: {
    options: [
      "default",
      "Arial, sans-serif",
      "Courier new, Courier, monospace",
      "Georgia, serif",
      "Helvetica, sans-serif",
      "Tahoma, Verdana, Segoe, sans-serif",
      "Times New Roman, serif",
      "Verdana, sans-serif",
    ],
    supportAllValues: true,
  },
  fontSize: {
    options: ["default", 15, 18, 20, 22, 24, 26, 30, 36],
    supportAllValues: true,
  },
  link: {
    defaultProtocol: "https://",
    decorators: {
      addTargetToExternalLinks: {
        mode: "automatic",
        callback: (url: string) => /^(https?:)?\/\//.test(url),
        attributes: {
          target: "_blank",
          rel: "noopener noreferrer",
        },
      },
    },
  },
};

export default ClassicEditor;
