import axios from "../../src_common/utils/axios";

export interface Dashboard {
    data: {
        url: string
    }
}

export async function getDashboard(): Promise<Dashboard> {
    return await axios.get(`dashboard/yao`);
}
