import {
  Autocomplete,
  AutocompleteChangeReason,
  Checkbox,
  Chip,
  CircularProgress,
  createFilterOptions,
  TextField,
  Box,
} from "@mui/material";
import { SyntheticEvent, useState, useEffect } from "react";
import {
  RegisterOptions,
  useController,
  useFormContext,
} from "react-hook-form";
import Iconify from "../Iconify";
import "./YAOFieldInputOverride.css";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

export type YAOFieldAutocompleteOptionObject = {
  label?: string;
  value?: any;
  [key: string]: any;
};

export type YAOFieldAutocompleteOption =
  | YAOFieldAutocompleteOptionObject
  | string;

type YAOFieldAutocompleteProps<T> = {
  name: string;
  options: T[];
  defaultValue?: T | null;
  noOptionsText?: string;
  label?: string;
  rules?: Omit<
    RegisterOptions<Record<string, any>, string>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  margin?: "dense" | "normal" | "none";
  variant?: "filled" | "outlined" | "standard";
  loading?: boolean;
  placeholder?: string;
  disabled?: boolean;
  clearIcon?: React.ReactNode;
  getOptionLabel?: (option: T) => string;
  customOnChange?: (val: string) => void;
  isOptionEqualToValue?: (o: T, v: T) => boolean;
  hideErrorMessage?: boolean;
};

export function YAOFieldAutocomplete<T extends YAOFieldAutocompleteOption>({
  name,
  options,
  defaultValue = null,
  noOptionsText = "No options",
  label = "",
  placeholder = "",
  rules,
  margin = "none",
  variant = "outlined",
  loading = false,
  disabled = false,
  getOptionLabel = undefined,
  isOptionEqualToValue = undefined,
  customOnChange,
  clearIcon = <Iconify icon="akar-icons:trash-can" />,
  hideErrorMessage = false
}: YAOFieldAutocompleteProps<T>) {
  const { control } = useFormContext();
  const {
    field: { value, onChange, ...inputProps },
    fieldState: { error },
  } = useController({
    control,
    name,
    defaultValue,
    rules,
  });

  return (
    <Autocomplete
      {...inputProps}
      fullWidth
      id={name}
      options={options}
      value={value}
      onChange={(_: SyntheticEvent, v: YAOFieldAutocompleteOption | null) => {
        onChange(v);
        if (v && typeof v !== "string" && customOnChange) {
          v.changeVal ? customOnChange(v.changeVal) : customOnChange(v.value);
        }
      }}
      noOptionsText={noOptionsText}
      loading={loading}
      disabled={disabled}
      isOptionEqualToValue={(o, v) => {
        if (isOptionEqualToValue) {
          return isOptionEqualToValue(o, v);
        }
        if (typeof o === "string" && typeof v === "string") {
          return o === v;
        }
        if (typeof o === "object" && typeof v === "object") {
          return ["id", "_id", "value"].reduce(
            (prev, cur) => prev || (o[cur] && v[cur] && o[cur] === v[cur]),
            false
          );
        }
        return false;
      }}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          margin={margin}
          error={!!error}
          helperText={ hideErrorMessage ? null : error?.message}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={16} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

type YAOFieldAutocompleteMultipleProps<T> = Omit<
  YAOFieldAutocompleteProps<T>,
  "defaultValue"
> & {
  defaultValue?: YAOFieldAutocompleteOption[] | null;
  filterSelectedOptions?: boolean;
  customClassname?: string;
};

export function YAOFieldAutocompleteMultiple<
  T extends YAOFieldAutocompleteOption
>({
  name,
  options,
  defaultValue = [],
  noOptionsText = "No options",
  label = "Select an option",
  placeholder = "",
  rules,
  margin = "none",
  variant = "outlined",
  loading = false,
  filterSelectedOptions = true,
  customClassname,
  getOptionLabel = undefined,
  isOptionEqualToValue = undefined,
  clearIcon = <Iconify icon="akar-icons:trash-can" />,
}: YAOFieldAutocompleteMultipleProps<T>) {
  const { control } = useFormContext();
  const {
    field: { value, onChange, ...inputProps },
    fieldState: { error },
  } = useController({
    control,
    name,
    defaultValue,
    rules,
  });
  const [inputValue, setInputValue] = useState<string>("");

  return (
    <Autocomplete
      {...inputProps}
      fullWidth
      id={name}
      options={options}
      value={value}
      onChange={(_: SyntheticEvent, v: YAOFieldAutocompleteOption[] | null) =>
        onChange(v)
      }
      inputValue={inputValue}
      onInputChange={(_: SyntheticEvent, v: string) => setInputValue(v || "")}
      noOptionsText={noOptionsText}
      filterSelectedOptions={filterSelectedOptions}
      multiple={true}
      getOptionLabel={getOptionLabel}
      className={customClassname}
      clearIcon={clearIcon}
      isOptionEqualToValue={(o, v) => {
        if (isOptionEqualToValue) {
          return isOptionEqualToValue(o, v);
        }
        if (typeof o === "string" && typeof v === "string") {
          return o === v;
        }
        if (typeof o === "object" && typeof v === "object") {
          return ["id", "_id", "value"].reduce(
            (prev, cur) => prev || (o[cur] && v[cur] && o[cur] === v[cur]),
            false
          );
        }
        return false;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          margin={margin}
          error={error !== null && error !== undefined}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={16} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      loading={loading}
    />
  );
}

type YAOFieldAutocompleteTagProps<T> = Omit<
  YAOFieldAutocompleteProps<T>,
  "defaultValue" | "options" | "loading"
> & {
  options: string[];
  defaultValue?: string[] | null;
  filterSelectedOptions?: boolean;
  textFieldType?: string;
};

export function YAOFieldAutocompleteTagInput<
  T extends YAOFieldAutocompleteOption
>({
  name,
  options = [],
  defaultValue = [],
  noOptionsText = "No options",
  label = "Select an option",
  placeholder = "",
  rules,
  margin = "none",
  variant = "outlined",
  filterSelectedOptions = false,
  textFieldType = "text",
}: // clearIcon = <Iconify icon="akar-icons:trash-can" />,
YAOFieldAutocompleteTagProps<T>) {
  const { control } = useFormContext();
  const {
    field: { value, onChange, ...inputProps },
    fieldState: { error },
  } = useController({
    control,
    name,
    defaultValue,
    rules,
  });
  const [inputValue, setInputValue] = useState<string>("");

  return (
    <Autocomplete
      {...inputProps}
      fullWidth
      id={name}
      options={options}
      value={value}
      onChange={(_: SyntheticEvent, v: string[] | null) => onChange(v)}
      inputValue={inputValue}
      onInputChange={(_: SyntheticEvent, v: string) => setInputValue(v || "")}
      noOptionsText={noOptionsText}
      multiple={true}
      freeSolo={true}
      filterSelectedOptions={filterSelectedOptions}
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option: string, index: number) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          margin={margin}
          error={error !== null && error !== undefined}
          placeholder={placeholder}
          type={textFieldType}
        />
      )}
      className="yao-tags-override"
    />
  );
}

export function YAOFieldAutocompleteMultipleWithSelectAll<
  T extends YAOFieldAutocompleteOption
>({
  name,
  options,
  defaultValue = [],
  noOptionsText = "No options",
  label = "Select an option",
  placeholder = "",
  rules,
  margin = "none",
  variant = "outlined",
  loading = false,
  filterSelectedOptions = true,
  customClassname,
  getOptionLabel = (option: YAOFieldAutocompleteOption) =>
    `${(option as YAOFieldAutocompleteOptionObject).label}`,
  isOptionEqualToValue = undefined,
  clearIcon = <Iconify icon="akar-icons:trash-can" />,
}: YAOFieldAutocompleteMultipleProps<T>) {
  const { control } = useFormContext();
  const {
    field: { value, onChange, ...inputProps },
    fieldState: { error },
  } = useController({
    control,
    name,
    defaultValue,
    rules,
  });
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedOptions, setSelectedOptions] = useState<
    YAOFieldAutocompleteOption[]
  >([]);
  const filter = createFilterOptions();

  useEffect(() => {
    if (Array.isArray(value) && value.length > 0) {
      setSelectedOptions(value);
    }
  }, []);

  return (
    <Autocomplete
      {...inputProps}
      fullWidth
      id={name}
      options={options}
      value={value}
      multiple={true}
      inputValue={inputValue}
      disableCloseOnSelect
      getOptionLabel={(option: YAOFieldAutocompleteOption) =>
        `${(option as YAOFieldAutocompleteOptionObject).label}`
      }
      noOptionsText={noOptionsText}
      filterOptions={(options: any, params: any) => {
        const filtered = filter(options, params);
        return [{ label: "Select all", value: "select-all" }, ...filtered];
      }}
      onChange={(
        event: SyntheticEvent,
        values: YAOFieldAutocompleteOption[] | null,
        reason: AutocompleteChangeReason
      ) => {
        if (!values) {
          setSelectedOptions([]);
          return onChange(values);
        }
        if (["selectOption", "removeOption"].indexOf(reason) !== -1) {
          if (
            values.find(
              (o) =>
                (o as YAOFieldAutocompleteOptionObject).value === "select-all"
            )
          ) {
            const next =
              options.length === selectedOptions.length ? [] : options;
            setSelectedOptions(next);
            return onChange(
              next.filter(
                (o) =>
                  (o as YAOFieldAutocompleteOptionObject).value !== "select-all"
              )
            );
          } else {
            setSelectedOptions(values);
            return onChange(values);
          }
        } else if (reason === "clear") {
          setSelectedOptions([]);
        }
      }}
      renderOption={(props: any, option: any, state: any) => {
        const selectAllProps =
          (option as YAOFieldAutocompleteOptionObject).value === "select-all"
            ? { checked: options.length === selectedOptions.length }
            : {};
        return (
          <Box {...props}>
            <Checkbox
              color="primary"
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={state.selected}
              {...selectAllProps}
            />
            {getOptionLabel(option as any)}
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          margin={margin}
          error={error !== null && error !== undefined}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={16} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onInputChange={(_: SyntheticEvent, v: string) => setInputValue(v || "")}
      isOptionEqualToValue={(
        o: YAOFieldAutocompleteOption,
        v: YAOFieldAutocompleteOption
      ) =>
        (o as YAOFieldAutocompleteOptionObject).value ===
        (v as YAOFieldAutocompleteOptionObject).value
      }
      className={customClassname}
      loading={loading}
      disableClearable
    />
  );
}
