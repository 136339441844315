import {
  FormTemplate,
  FormTemplateDto,
  FormTemplateDeleteDto,
  FormTemplateQueryDto,
  FormField,
  Form,
  FormAnswer,
  FormAnswersDto, FormTemplateDuplicateDto,
} from "src_common/@interfaces/forms";
import { formatError } from "src_common/utils/misc";
import axios from "src_common/utils/axios";

export async function createFormTemplate(
  form: FormTemplateDto
): Promise<FormTemplate> {
  try {
    const res = await axios.post("/forms/templates", form);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function updateFormTemplate(
  form: FormTemplateDto,
  id: string
): Promise<FormTemplate> {
  try {
    const res = await axios.put(`/forms/templates/${id}`, form);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function duplicateFormTemplate(
    id: string,
    form: FormTemplateDuplicateDto
): Promise<FormTemplate> {
  try {
    const res = await axios.patch(`/forms/templates/${id}/duplicate`, form);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function deleteFormTemplate(
  id: string,
  form: FormTemplateDeleteDto
): Promise<FormTemplate> {
  try {
    const res = await axios.patch(`/forms/templates/${id}/delete`, form);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function getFormTemplates(
  params: FormTemplateQueryDto | undefined
): Promise<FormTemplate[]> {
  const res = await axios.get("/forms/templates", { params });
  return res.data;
}

export interface FindFormQueryDto {
  matter: string;
}

export interface CreateFormDto {
  matter: string;
  title: string;
  description?: string;
  template: string;
}



export interface FormPublicResponse {
  _id: string;
  title: string;
  description: string;
  fields: FormField[];
  contact: string;
  isFilled: boolean;
}

export async function searchForms(payload: FindFormQueryDto): Promise<Form[]> {
  const res = await axios.post("/forms/search", payload);
  return res.data;
}

export async function createForm(payload: CreateFormDto): Promise<Form> {
  try {
    const res = await axios.post("/forms", payload);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function replyForm(payload: FormAnswersDto): Promise<Form> {
  try {
    const res = await axios.patch("/forms", payload);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function autoSaveForm(payload: FormAnswersDto): Promise<Form> {
  try {
    const res = await axios.patch("/forms/auto-save", payload);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function formsShareUpdateContact(id: string, contact: string | null): Promise<Form> {
  try {
    const res = await axios.patch(`/forms/${id}/contact`, { contact });
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function formsPublicDetails(id: string): Promise<FormPublicResponse> {
  try {
    const res = await axios.get(`/forms/${id}/public`);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function formsPublicGenerateCode(id: string): Promise<boolean> {
  try {
    const res = await axios.patch(`/forms/${id}/code`, {});
    return res.data?.success || false;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function formsPublicReply(id: string, payload: { answers: FormAnswer[], update_code: string | undefined  }): Promise<Form> {
  try {
    const res = await axios.patch(`/forms/${id}/public`, payload);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}
