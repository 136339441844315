import axios from "src_common/utils/axios";
import {base64ToBlob, formatError} from "src_common/utils/misc";
import {saveAs} from "file-saver";

export async function createTimeEntry(params: Partial<TimeEntryFormData>) {
  const res = await axios.post("/time-entries", {
    do_not_bill: false,
    ...params,
  });

  return res?.data;
}

export async function deleteTimeEntry(id: string) {
  const res = await axios.delete(`/time-entries/${id}`);

  return res?.data;
}

export async function updateTimeEntry(
  id: string,
  params: Partial<TimeEntryFormData>
) {
  const res = await axios.put(`/time-entries/${id}`, params);

  return res?.data;
}

export async function searchTimeEntries({
  page = 0,
  size = 10,
  ...params
}): Promise<{
  result: TimeEntry[];
  next: number;
}> {
  try {
    const res = await axios.post("/time-entries/search", {
      page,
      size,
      ...params,
    });
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function getTotalTimeEntries(matterId: string): Promise<{
  invoiced: number;
  uninvoiced: number;
}> {
  try {
    const res = await axios.get(`/time-entries/${matterId}/total`);
    return (
      res.data || {
        invoiced: 0,
        uninvoiced: 0,
      }
    );
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function downloadTimeEntries(
    matter: string,
    invoiced: InvoicedFilter,
): Promise<void> {
  try {
    const response = await axios.patch(
        '/time-entries/download',
        {
          matter,
          invoiced,
        },
        {
          responseType: 'text',
          timeout: Number.MAX_SAFE_INTEGER,
        }
    )
    const blob = base64ToBlob(response.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    saveAs(blob, 'time-entries.xlsx')
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export type TimeEntry = {
  _id: string;
  id: string;
  rate: number;
  units: number;
  duration_minutes: number;
  description: string;
  billable: number;
  matter: string;
  law_firm: string;
  invoice: string;
  assignee: {
    name: string;
    _id: string;
  };
  date: string;
  work_type: WorkType;
  correspondence: string;
  created_at: string;
  updated_at: string;
};

export enum WorkType {
  Drafting = "Drafting",
  Reviewing = "Reviewing",
  Attendance = "Attendance",
  Preparation = "Preparation",
  Telephone = "Telephone",
  Letter = "Letter",
  Email = "Email",
  Research = "Research",
  Various = 'Various',
  NonChargeable = 'Non_Chargeable',
}

export const WorkTypeLabel: Record<WorkType, string> = {
  [WorkType.Drafting]: "Drafting",
  [WorkType.Reviewing]: "Reviewing",
  [WorkType.Attendance]: "Attendance",
  [WorkType.Preparation]: "Preparation",
  [WorkType.Telephone]: "Telephone",
  [WorkType.Letter]: "Letter",
  [WorkType.Email]: "Email",
  [WorkType.Research]: "Research",
  [WorkType.Various]: "Various",
  [WorkType.NonChargeable]: "Non-Chargeable"
};

export enum InvoicedFilter {
  All = "All",
  Wip = "Wip",
  Invoiced = "Invoiced",
}

export type TimeEntryFormData = {
  assignee?: string;
  rate?: number;
  do_not_bill?: boolean;
  duration_minutes: number;
  matter: string;
  description?: string;
  work_type?: WorkType;
  date: string;
  correspondence?: string;
};
