import { Avatar, Box, Typography } from "@mui/material";
import createAvatar from "src_common/utils/createAvatar";
import { CellProps } from "../types";
import palette from "src_common/theme/palette";

export default function AvatarCell({ data, formatter }: CellProps) {
  const name = (formatter ? formatter(data.name) : data.name) as string;
  const avatar = createAvatar(name);
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Avatar
        sx={{
          mr: 1,
          height: 22,
          width: 22,
          borderRadius: "3px",
          fontWeight: 500,
          fontSize: 11,
        }}
        alt={name}
      >
        {avatar.name}
      </Avatar>
      <Typography sx={{ fontSize: 13, fontWeight: 400 }} noWrap>
        {name}
      </Typography>
    </Box>
  );
}

type CleanAvatarCellProps = {
  removeAvatar?: boolean;
  onClick?: (...args: any) => void;
} & CellProps;

export function CleanAvatarCell({
  data,
  formatter,
  removeAvatar,
  onClick,
}: CleanAvatarCellProps) {
  const name = (formatter ? formatter(data) : data) as string;
  const avatar = createAvatar(name);
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {!removeAvatar && (
        <Avatar
          sx={{
            mr: 1,
            height: 22,
            width: 22,
            borderRadius: "3px",
            fontWeight: 500,
            fontSize: 11,
          }}
          alt={name}
        >
          {avatar.name}
        </Avatar>
      )}
      {onClick ? (
        <Typography
          sx={{
            fontSize: 13,
            fontWeight: 400,
            color: palette.yao.primary[1],
            ":hover": { textDecoration: "underline" },
          }}
          noWrap
        >
          <span onClick={onClick}>{name}</span>
        </Typography>
      ) : (
        <Typography sx={{ fontSize: 13, fontWeight: 400 }} noWrap>
          {name}
        </Typography>
      )}
    </Box>
  );
}
