import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { clockTimeTrack, TimeTrack } from "src_lawfirm/api/time-track";

export function useTimer(timeTrack: TimeTrack) {
  const queryClient = useQueryClient();

  const clock = useMutation(clockTimeTrack, {
    onSuccess: () => {
      queryClient.invalidateQueries(["time-trackers"]);
    },
  });

  const diffFromNow =
    (new Date().valueOf() - new Date(timeTrack.updated_at).valueOf()) / 1000;
  const sumSeconds = timeTrack.sum / 1000;

  const getTimer = () => {
    if (timeTrack.status === "Paused") {
      return sumSeconds;
    } else {
      return sumSeconds + diffFromNow;
    }
  };

  const [timer, setTimer] = useState(0);

  const toggle = useCallback(async () => {
    clock.mutate(timeTrack._id);
  }, [timeTrack]);

  useEffect(() => {
    let timerId: NodeJS.Timer | null;
    setTimer(getTimer());

    if (timeTrack.status === "Running")
      timerId = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000);
    return () => {
      timerId && clearInterval(timerId);
    };
  }, [timeTrack]);

  return {
    timer,
    toggle,
  };
}
