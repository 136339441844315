import {formatError} from "src_common/utils/misc";
import axios from "src_common/utils/axios";
import {AxiosResponse} from "axios";

export enum GptPromptType {
    explain = 'explain',
    bulletPoint = 'bullet-point',
}

export async function explainCorrespondence(id: string, prompt_type: GptPromptType): Promise<{ text: string }> {
    try {
        const response: AxiosResponse<{ text: string }> = await axios.get(`correspondence/${id}/ai/${prompt_type}`);
        return response.data
    } catch (e) {
        throw new Error(formatError(e))
    }
}
