import {
  useController,
  useFormContext,
  RegisterOptions,
} from "react-hook-form";
import { TextField, TextFieldProps } from "@mui/material";
import "./YAOFieldInputOverride.css";
import { TimePicker, TimePickerProps } from "@mui/lab";

type YAOFieldTimePickerProps = {
  name: string;
  rules?: Omit<
    RegisterOptions<Record<string, any>, string>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  defaultValue?: string;
  timePickerProps?: Omit<TimePickerProps, "onChange" | "value" | "renderInput">;
  textFieldProps?: Omit<
    TextFieldProps,
    | "name"
    | "fullWidth"
    | "error"
    | "helperText"
    | "className"
    | "label"
    | "placeholder"
  >;
  inputFormat?: string;
};

export function YAOFieldTimePicker({
  name,
  rules,
  defaultValue,
  timePickerProps = {},
  textFieldProps = {},
}: YAOFieldTimePickerProps) {
  const { control } = useFormContext();
  const {
    field: { value, onChange, ...inputProps },
    fieldState: { error },
  } = useController({
    control,
    name,
    defaultValue,
    rules,
  });

  return (
    <TimePicker
      {...timePickerProps}
      {...inputProps}
      value={value}
      onChange={(newValue: any) => {
        onChange(newValue.getTime());
      }}
      renderInput={(params) => (
        <TextField
          {...textFieldProps}
          {...params}
          id={name}
          fullWidth
          error={!!error}
          helperText={error?.message}
          className="yao-input-override"
        />
      )}
    />
  );
}
