import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormProvider } from "src_common/components/hook-form";
import {
  YAOFieldAutocomplete,
  YAOFieldText,
} from "src_common/components/yao-form-fields";
import { YaoFormFieldLabel } from "src_common/components/yao-form/YaoForm";
import { formatError } from "src_common/utils/misc";
import { WorkType, WorkTypeLabel } from "src_lawfirm/api/time-entries";
import { useMutation } from "@tanstack/react-query";
import { getMatter } from "../../../api/matters";
import { YAOFieldCurrency } from "../../../../src_common/components/yao-form-fields/YAOFieldCurrency";
import { LawFirmContext } from "../../law-firm/LawFirmContext";
import { getAttorneys } from "../../../api/attorneys";
import useAuth from "../../../../src_common/hooks/useAuth";

type ClockTimeEntryProps = {
  onFinish: (clockInfo: ClockTimeEntryData) => void;
  onCancel: () => void;
  case_name: string;
  matterId: string;
};

type ClockTimeEntryModel = {
  work_type: {
    label: string;
    value: string;
  };
  description: string;
  rate?: number;
};

export type ClockTimeEntryData = {
  work_type?: string;
  description?: string;
  rate?: number;
};

const ClockTimeEntry: FC<ClockTimeEntryProps> = ({
  onFinish,
  onCancel,
  case_name,
  matterId,
}) => {
  const lawFirmContext = useContext(LawFirmContext);
  const [loader, setLoader] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const attorneyMutation = useMutation(() => getAttorneys({}), {
    onSuccess: (data) => {
      const found = data.find((a) => `${a._id}` === `${user?._id}`);
      if (found && found.rate && !isNaN(Number(found.rate))) {
        methods.setValue("rate", Number(found.rate));
      }
    },
  });

  const matterMutation = useMutation(() => getMatter(matterId), {
    onSuccess: (data) => {
      if (data.rate && !isNaN(Number(data.rate))) {
        methods.setValue("rate", Number(data.rate));
      } else {
        attorneyMutation.mutate();
      }
    },
  });

  const methods = useForm<ClockTimeEntryModel>({
    defaultValues: {
      work_type: undefined,
      description: "",
      rate: 0,
    },
  });

  useEffect(() => {
    matterMutation.mutate();
  }, []);

  useEffect(() => {
    const subscription = methods.watch((values, { name, type, ...rest }) => {
      if (
        name === "work_type" &&
        values.work_type?.value === WorkType.NonChargeable
      ) {
        methods.setValue("rate", 0);
      }
    });
    return () => subscription.unsubscribe();
  }, [methods.watch("work_type")]);

  const handleCancel = () => onCancel();

  const onSubmit: SubmitHandler<ClockTimeEntryModel> = async (data) => {
    try {
      setLoader(true);
      onFinish({
        work_type: data.work_type?.value || undefined,
        description: data.description,
        rate: data.rate || undefined,
      });
    } catch (error) {
      enqueueSnackbar(formatError(error), { variant: "error" });
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container rowSpacing={1.5} columnSpacing={2.5}>
          <Grid item xs={12}>
            <Typography variant="h2" align="center">
              Time clock details
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2" align="center">
              {case_name}
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <YaoFormFieldLabel name="work_type" label="Type" />
            <YAOFieldAutocomplete
              name="work_type"
              label=""
              placeholder="Choose time entry type"
              options={Object.keys(WorkTypeLabel).map((wt) => ({
                label: WorkTypeLabel[wt as WorkType],
                value: wt,
              }))}
            />
          </Grid>

          <Grid item xs={4}>
            <YaoFormFieldLabel name="rate" label="Override default rate" />

            <YAOFieldCurrency
              name="rate"
              label=""
              type="text"
              placeholder="Enter amount"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {lawFirmContext.getCurrencySymbol()}
                  </InputAdornment>
                ),
              }}
              disabled={methods.getValues('work_type.value') === WorkType.NonChargeable }
            />
          </Grid>

          <Grid item xs={12}>
            <YaoFormFieldLabel name="description" label="Description" />
            <YAOFieldText
              name="description"
              label=""
              placeholder="e.g. Attendance at court"
            />
          </Grid>
        </Grid>

        <Stack
          direction="row"
          sx={{
            mt: 4,
            justifyContent: "right",
          }}
          spacing={2}
        >
          <Button color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="contained" sx={{ minWidth: 100 }} type="submit">
            Add
          </Button>
        </Stack>
      </FormProvider>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ClockTimeEntry;
