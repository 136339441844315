import { Theme } from "@mui/material/styles";
import palette from "../palette";

export default function Avatar(theme: Theme) {
  return {
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          color: palette.yao.primary[1],
          backgroundColor: palette.yao.primary[4],
          height: 32,
          width: 32,
          borderRadius: 6,
          fontSize: 13,
          fontWeight: 600,
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
          fontFamily: "Inter, sans-serif",
          "&:first-of-type": {
            fontSize: 12,
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.lighter,
          },
        },
      },
    },
  };
}
