import {createContext, FC} from "react";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {getLawFirm, LawFirm} from "../../api/law-firms";
import useAuth from "src_common/hooks/useAuth";

export type CurrencyInfo = { locale: string; currency: string; symbol: string };

export type LawFirmContextProps = {
    law_firm: UseQueryResult<LawFirm | undefined, any> | undefined;
    query_key: string;
    getCurrencySymbol: () => string;
    getCurrency: () => CurrencyInfo;
    getTaxName: () => string;
    getTaxPercentage: () => number;
    isUkCompany: () => boolean;
}

const query_key = 'law-firm-provider';

const DEFAULT_VALUES = {
    law_firm: undefined,
    query_key,
    getCurrencySymbol: () => '£',
    getCurrency: () => ({ locale: 'en-GB', currency: 'GBP', symbol: '£' }),
    getTaxName: () => 'VAT',
    getTaxPercentage: () => 20,
    isUkCompany: () => false,
}

const LawFirmContext = createContext<LawFirmContextProps>(DEFAULT_VALUES);

const LawFirmContextProvider: FC = ({ children }) => {

    const { user  } = useAuth()

    const law_firm = useQuery(
        [query_key, user?._id],
        () => !user ? Promise.resolve(undefined) : getLawFirm(),
        {
            enabled: !!user,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            cacheTime: 60 * (60 * 1000),
        }
    )

    const getCurrencySymbol = (): string => law_firm?.data?.country?.currency_symbol || '£'

    const getCurrency = (): CurrencyInfo => ({
        locale: law_firm?.data?.country?.country_code || 'en-GB',
        currency: law_firm?.data?.country?.currency_code || 'GBP',
        symbol: getCurrencySymbol(),
    })

    const getTaxName = (): string => (law_firm?.data?.country?.tax_name || 'VAT').toUpperCase();

    const getTaxPercentage = (): number => !isNaN(Number(law_firm?.data?.country?.tax_percentage)) && Number(law_firm?.data?.country?.tax_percentage) >= 0 ? Number(law_firm?.data?.country?.tax_percentage) : 20;

    const isUkCompany = () => getCurrency().currency === 'GBP';

    return <LawFirmContext.Provider value={{
        law_firm,
        query_key,
        getCurrencySymbol,
        getCurrency,
        getTaxName,
        getTaxPercentage,
        isUkCompany,
    }}>
        {children}
    </LawFirmContext.Provider>
}

export {
    LawFirmContext,
    LawFirmContextProvider,
}