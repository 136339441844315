import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import customAxios from "src_common/utils/axios";
import { useSnackbar } from "notistack";
import { SupportedFileTypes } from "./YAOS3Uploader";
import { AxiosResponse } from "axios";
import axiosLib from "axios";
import {AwsPreSignedResponse, CorrespondenceCategory} from "src_lawfirm/api/correspondence";

interface Props {
  onSuccess?: (fileData: { key: string; title: string }) => void;
  onSuccessPromise?: (fileData: { key: string; title: string, category?: CorrespondenceCategory }) => Promise<void>;
  multiple?: boolean;
  accept?: string;
  name?: string;
  customFileHandlerFiles?: SupportedFileTypes[];
  uploadingExternal?: boolean;
}

export const useDownloadFileFromS3 = (backoffice: boolean = false, contact: boolean = false,) => {
  const { enqueueSnackbar } = useSnackbar();

  const download = async (key: string): Promise<string | null> => {
    try {
      const { data } = await customAxios.post(`/uploads/file/pre-signed${backoffice ? '/backoffice' : contact ? '/contact' : ''}`, {
        key
      });
      return data;
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
      return null;
    }
  }

  const downloadContentAsBlob = async (key: string, type: string): Promise<Blob | null> => {
    try {
      const url = await download(key);
      const content = await axiosLib.get(url as string, {
        responseType: 'arraybuffer'
      });
      return new Blob([content.data], { type })
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
      return null
    }
  }

  const downloadContent = async (key: string, type: string): Promise<string | null> => {
    try {
      const url = await download(key);
      const content = await axiosLib.get(url as string, {
        responseType: 'arraybuffer'
      });
      const blob = new Blob([content.data], { type })
      return blob.text();
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
      return null
    }
  }

  const directDownload = async (key: string): Promise<void> => {
    (async() => {
      if(!key?.length){
        return;
      }
      const href = await download(key);
      if(!href?.length){
        return;
      }
      let link = document.createElement('a');
      link.href = href;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })();
  }

  return {download, directDownload, downloadContent, downloadContentAsBlob}

}

export const useUploadFileToS3 = (backoffice: boolean = false, isPublic: boolean = false) => {
  const { enqueueSnackbar } = useSnackbar();

  const upload = async (file: File) => {
    try {
      const extension = file.name.split('.').pop()?.toLowerCase() as SupportedFileTypes;
      if (!Object.values(SupportedFileTypes).includes(extension)) {
        throw new Error("File type not supported");
      }

      const config: { extension: string; is_public?: boolean } = {
        extension,
        is_public: isPublic
      }

      const presigned: AxiosResponse<AwsPreSignedResponse> = await customAxios.post(`/uploads${backoffice ? '/backoffice' : ''}`, config);

      if(!presigned || !presigned.data){
        throw new Error("Try again");
      }

      const formData = new FormData();
      if (![SupportedFileTypes.EML, SupportedFileTypes.MSG].includes(extension)) {
        formData.append('Content-Type', file.type);
      }

      Object.entries(presigned.data.fields).forEach(([k, v]) => {
        formData.append(k, v);
      });
      formData.append("file", file);
      await axiosLib.post(presigned.data.url, formData, {
        headers: {'Content-Type': 'multipart/form-data'},
      });

      return {
        key: presigned.data.fields.Key,
        title: file.name,
        path: `${presigned.data.url}/${presigned.data.fields.Key}`
      };
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  return upload;
};

export function YAOUploader({
  onSuccess, onSuccessPromise, multiple, accept, name = 'Upload', customFileHandlerFiles,
  uploadingExternal = false,
}: Props) {
  const [loading, setLoading] = useState(false);
  const uploadFileToS3 = useUploadFileToS3();

  const _uploadFileToS3 = async (file: File) => {
    const res = await uploadFileToS3(file);
    if (res) {
      if (onSuccess) {
        onSuccess(res);
      }
      if (onSuccessPromise) {
        const category = customFileHandlerFiles?.includes(file.name.split('.').pop()?.toLowerCase() as SupportedFileTypes) ? CorrespondenceCategory.EMAIL : CorrespondenceCategory.DOCUMENT
        await onSuccessPromise({
          ...res,
          category,
        });
      }
    }
  };

  const onChange = async (fileList: FileList | null) => {
    const files = Array.from(fileList || []);
    if (files?.length === 0) return;

    setLoading(true);
    await Promise.all(
      files.map(_uploadFileToS3)
    );
    setLoading(false);
  }

  const defaultAcceptedValues = Object.values(SupportedFileTypes).map((ft) => `.${ft}`).join(',');

  return (
    <LoadingButton loading={uploadingExternal || loading} variant="contained" component="label">
      {name}
      <input
        hidden
        accept={accept || defaultAcceptedValues}
        onChange={(e) => onChange(e.target.files)}
        multiple={multiple}
        type="file"
      />
    </LoadingButton>
  );
}
