import { Outlet, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, CircularProgress } from "@mui/material";
import MainHeader from "./MainHeader";
import { Suspense, useContext, useEffect, useState } from "react";
import useCollapseDrawer from "src_common/hooks/useCollapseDrawer";
import NavbarVertical from "../navbar/NavbarVertical";
import {
  HEADER,
  NAVBAR,
  TAWKTO_PROPERTY_ID,
  TAWKTO_WIDGET_ID,
} from "src_common/config";
import { NavConfigProps } from "src_common/components/nav-section/type";
import palette from "src_common/theme/palette";
import AddMenuContext from "src_common/contexts/AddMenuContext";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { MenuContext } from "../../components/menu/menu-context";
import { JWTContextType } from "../../@types/auth";

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled("main", {
  shouldForwardProp: (prop) => prop !== "collapseClick",
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT,
  paddingBottom: HEADER.MOBILE_HEIGHT,
  paddingLeft: 16,
  paddingRight: 16,
  background: palette.yao.grey[3],
  minHeight: "100vh",
  [theme.breakpoints.up("lg")]: {
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 16,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 16,
    paddingLeft: 16,
    paddingRight: 16,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create("margin-left", {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

type MainLayoutProps = {
  navConfig?: NavConfigProps;
};

export default function MainLayout({ navConfig }: MainLayoutProps) {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const addMenuCtx = useContext(AddMenuContext);
  const menuCtx = useContext(MenuContext);

  useEffect(() => {
    const inMatter = RegExp("^(/matters/\\w+/\\w+)*").test(
      location?.pathname || ""
    );
    addMenuCtx.setInMatter(inMatter);
    if (addMenuCtx.matterId !== undefined && !inMatter) {
      addMenuCtx.setMatterId(undefined);
    }
  }, [location]);

  return (
    <Box
      sx={{
        display: { lg: "flex" },
        minHeight: { lg: 1 },
      }}
    >
      <MainHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

      <NavbarVertical
        navConfig={navConfig || []}
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />

      <MainStyle collapseClick={collapseClick}>
        <Suspense
          fallback={
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={20} sx={{ color: "#B3B3B3" }} />
            </Box>
          }
        >
          <Outlet />
        </Suspense>
      </MainStyle>
      {(TAWKTO_PROPERTY_ID || "").trim().length > 0 &&
      (TAWKTO_WIDGET_ID || "").trim().length > 0 ? (
        <TawkMessengerReact
          ref={menuCtx.tawkToRef}
          propertyId={TAWKTO_PROPERTY_ID.trim()}
          widgetId={TAWKTO_WIDGET_ID.trim()}
          onLoad={() => {
            menuCtx.tawkToRef?.current?.hideWidget();
          }}
        />
      ) : null}
    </Box>
  );
}
