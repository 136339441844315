import {
  Autocomplete,
  Box,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Select,
  MenuItem, Divider,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { HTMLAttributes, SyntheticEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useDebounce from "src_common/hooks/useDebounce";
import palette from "src_common/theme/palette";
import { formatError } from "src_common/utils/misc";
import { Contact, ContactType, getContacts } from "src_lawfirm/api/contacts";
import { Matter, getMatters } from "src_lawfirm/api/matters";
import { useContacts } from "src_lawfirm/pages/contacts/useContacts";
import Iconify from "./Iconify";
import axios, {CancelTokenSource} from "axios";

let source: CancelTokenSource;

const MatterSearchBar = () => {
  const { debounce, reset: clearDebounce } = useDebounce();
  const { enqueueSnackbar } = useSnackbar();
  const contactsApi = useContacts();
  const navigate = useNavigate();
  const [origin, setOrigin] = useState<"contact" | "matter" | "matter-number">(
    "contact"
  );
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    clearDebounce();
    if(source && source.cancel){
      source.cancel()
    }
    if (!inputValue.length) {
      setOpen(false);
      setOptions([]);
      setLoading(false);
      return;
    }
    setLoading(true);
    debounce(async () => {
      source = axios.CancelToken.source()
      let response: any[] = [];
      switch (origin) {
        case "matter":
          response = await getMatters({
            search: inputValue,
          }, source.token);
          break;
        case "matter-number":
          response = await getMatters({
            number: Number(inputValue) || 0,
          }, source.token);
          break;
        default:
          response = await getContacts({
            search: inputValue,
            limit: 50,
            last_id: "",
            is_archived: false,
            type: undefined,
          }, source.token);
          break;
      }
      setOptions(response);
      setLoading(false);
    }, 100);
  }, [inputValue]);

  const handleClick = (_: SyntheticEvent, value: any) => {
    if (!value) {
      return;
    }
    switch (origin) {
      case "contact":
        contactsApi.contactCard((value as Contact)._id);
        break;
      case "matter":
        case "matter-number":
        navigate(`/matters/${(value as Matter)._id}/overview`);
        break;
    }
  };

  const getContactName = (option: Contact | null | undefined) => {
    if (!option) {
      return "";
    }
    if(option.display_name?.length > 0){
      return option.display_name
    }
    if (option?.type === ContactType.Company) {
      return option.company_name;
    }
    const names = [];
    if (option.first_name?.length > 0) {
      names.push(option.first_name);
    }
    if (option.middle_name?.length > 0) {
      names.push(option.middle_name);
    }
    if (option.last_name?.length > 0) {
      names.push(option.last_name);
    }
    return names.join(" ");
  };

  const getOptionLabel = (option: Matter | Contact): string => {
    if (origin === "matter") {
      return (option as Matter)?.case_name || "";
    }
    return getContactName(option as Contact);
  };

  const renderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: Matter | Contact
  ) => {
    const containerStyle = {
      "&&": {
        borderRadius: 0,
        borderBottomWidth: "1px",
        borderBottomColor: palette.yao.primary[4],
        borderBottomStyle: "solid",
      },
    };

    if (origin === "matter" || origin === "matter-number") {
      return (
        <Stack
          {...props}
          direction="column"
          component="li"
          key={option._id}
          sx={containerStyle}
          spacing={0}
        >
          <Stack direction="row" spacing={1} width="100%">
            <Typography
              textAlign="left"
              color={palette.yao.primary[2]}
              fontSize="12px"
              lineHeight="24px"
              fontWeight={600}
            >
              #{(option as Matter).number} - {(option as Matter).case_name}
            </Typography>
            <Box
              sx={{
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: palette.yao.grey[5],
                borderRadius: "3px",
                height: "20px",
                padding: "2px",
                marginTop: "-4px!important",
                marginLeft: "4px!important",
              }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography color="#be6cda" fontSize={10}>
                {(option as Matter).status}
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row" spacing={1} width="100%" alignItems="center" mb={0}>
            <Iconify icon="clarity:user-line" color={palette.yao.grey[6]} />
            <Typography
              variant="caption"
              color={palette.yao.primary[2]}
              fontSize="12px"
              lineHeight="32px"
              fontWeight={400}
            >
              {(option as Matter).responsible_lawyer?.name}
            </Typography>
          </Stack>
          { Array.isArray((option as Matter).clients) && (option as Matter).clients.length > 0 ? (
              <Stack direction="row" spacing={1} width="100%" alignItems="center" mt={0}>
                <Iconify icon="clarity:users-line" color={palette.yao.grey[6]} />
                <Typography
                    variant="caption"
                    color={palette.yao.primary[2]}
                    fontSize="12px"
                    lineHeight="32px"
                    fontWeight={400}
                >
                  {(option as Matter).clients.map(c => getContactName(c.contact as Contact)).join(', ')}
                </Typography>
              </Stack>
          ) : null }

        </Stack>
      );
    }
    const name = getContactName(option as Contact);
    return (
      <Stack
        {...props}
        direction="column"
        component="li"
        key={option._id}
        sx={containerStyle}
      >
        <Stack direction="row" spacing={1} width="100%">
          <Typography variant="h5" flexGrow={1} flexShrink={1} textAlign="left">
            {name}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const notFoundText =
    origin === "contact" ? "Contact not found" : "Matter not found";
  const searchText =
    origin === "contact" ? "Search contact name" : origin === "matter-number" ? "Search matter number" : "Search matter name";

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      padding={1}
      sx={{
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: palette.yao.grey[5],
        borderRadius: 0.75,
        height: "32px",
      }}
    >
      <Autocomplete
        id="field1989"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(
          option: Matter | Contact,
          value: Matter | Contact
        ) => option._id === value._id}
        filterOptions={(x) => x}
        options={options}
        loading={loading}
        value={null}
        blurOnSelect={true}
        onChange={handleClick}
        inputValue={inputValue}
        onInputChange={(_: SyntheticEvent, value: string) =>
          setInputValue(value)
        }
        noOptionsText={
          inputValue.length > 0 ? notFoundText : "Type anything to search"
        }
        disableClearable
        forcePopupIcon={false}
        clearOnEscape={true}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={searchText}
            fullWidth
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password1989",
            }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                </>
              ),
            }}
            variant="standard"
          />
        )}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        sx={{
          width: 300,
        }}
      />
      <Select
        value={origin}
        onChange={(e) => setOrigin(e.target.value as any)}
        sx={{
          padding: 0,
          border: 0,
          "&& .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "&& .MuiSelect-select": {
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
        MenuProps={{
          sx: {
            "&& .Mui-selected": {
              backgroundColor: "white",
            },
          },
        }}
      >
        <MenuItem
          value="contact"
          sx={{
            color: palette.yao.primary[3],
            opacity: origin === "contact" ? 0.7 : 1,
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          Contacts
        </MenuItem>
        <MenuItem
          value="matter"
          sx={{
            color: palette.yao.primary[3],
            opacity: origin === "matter" ? 0.7 : 1,
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          Matter name
        </MenuItem>
        <MenuItem
          value="matter-number"
          sx={{
            color: palette.yao.primary[3],
            opacity: origin === "matter-number" ? 0.7 : 1,
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          Matter number
        </MenuItem>
      </Select>
      <Iconify
        icon="eva:search-fill"
        color={palette.yao.primary[1]}
        height={22}
      />
    </Stack>
  );
};

export default MatterSearchBar;
