import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import {
  Correspondence,
  CorrespondenceCategory,
  CorrespondenceForm,
  createCorrespondence,
} from "src_lawfirm/api/correspondence";
import * as Yup from "yup";
import { FileForm, Types } from "./FileForm.component";

const FileSchema = Yup.object().shape({
  title: Yup.string().required("File name is required"),
});

export const AddCorrespondenceModal = NiceModal.create<{
  matterId: string;
  selected?: Correspondence;
}>(({ selected, matterId }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const modal = useModal();

  const handleCloseForm = useCallback(() => {
    modal.hide();
  }, [modal]);

  const methods = useForm<CorrespondenceForm>({
    resolver: yupResolver(FileSchema),
  });

  const onSubmit = async (form: CorrespondenceForm) => {
    try {
      const file = await createCorrespondence({
        ...form,
        matter: matterId,
        parent_folder:
          selected?.category === CorrespondenceCategory.FOLDER
            ? selected?._id
            : undefined,
        category: CorrespondenceCategory.FOLDER,
      });
      enqueueSnackbar(`${file.title} created successfully!`);
      methods.reset();

      queryClient.invalidateQueries({
        queryKey: [matterId, "correspondences"],
      });

      modal.resolve(file);
      modal.hide();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <FileForm
      visible={modal.visible}
      methods={methods}
      onSubmit={onSubmit}
      handleClose={handleCloseForm}
      type={Types.FOLDER}
      buttonTitle="Add"
      title="Add New Folder"
    />
  );
});
