import { useFormContext, Controller } from "react-hook-form";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControlLabelProps,
} from "@mui/material";
import CustomIcon from "../custom-icon";
import palette from "src_common/theme/palette";

interface RHFCheckboxProps extends Omit<FormControlLabelProps, "control"> {
  name: string;
}

export function RHFCheckbox({ name, ...other }: RHFCheckboxProps) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Checkbox
              {...field}
              checked={field.value}
              icon={
                <CustomIcon
                  name="checkbox"
                  sx={{ p: 0.1, ml: 0.2 }}
                  color={palette.yao.primary[1]}
                />
              }
              checkedIcon={
                <CustomIcon
                  name="checkboxTicked"
                  sx={{ p: 0.1, ml: 0.2 }}
                  color={palette.yao.primary[1]}
                />
              }
            />
          )}
        />
      }
      {...other}
    />
  );
}

interface RHFMultiCheckboxProps
  extends Omit<FormControlLabelProps, "control" | "label"> {
  name: string;
  options: {
    label: string;
    value: any;
  }[];
}

export function RHFMultiCheckbox({
  name,
  options,
  ...other
}: RHFMultiCheckboxProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const onSelected = (option: string) =>
          field.value.includes(option)
            ? field.value.filter((value: string) => value !== option)
            : [...field.value, option];

        return (
          <FormGroup>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    icon={
                      <CustomIcon
                        name="checkbox"
                        sx={{ p: 0.1, ml: 0.2 }}
                        color={palette.yao.primary[1]}
                      />
                    }
                    checkedIcon={
                      <CustomIcon
                        name="checkboxTicked"
                        sx={{ p: 0.1, ml: 0.2 }}
                        color={palette.yao.primary[1]}
                      />
                    }
                    checked={field.value?.includes(option.value)}
                    onChange={() => field.onChange(onSelected(option.value))}
                  />
                }
                label={option.label}
                {...other}
              />
            ))}
          </FormGroup>
        );
      }}
    />
  );
}
