import { useFormContext, RegisterOptions, Controller } from "react-hook-form";
import { TextField, TextFieldProps } from "@mui/material";
import "./YAOFieldInputOverride.css";

type YAOFieldTextProps = Omit<
  TextFieldProps,
  "name" | "fullWidth" | "className" | "error" | "helperText"
> & {
  name: string;
  rules?: Omit<
    RegisterOptions<Record<string, any>, string>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
};

export function YAOFieldText({
  name,
  rules,
  defaultValue,
  variant = "outlined",
  margin = "none",
  type = "text",
  ...fieldProps
}: YAOFieldTextProps) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({
        field: { value = "", onChange, ...props },
        fieldState: { error },
      }) => (
        <TextField
          fullWidth
          {...fieldProps}
          {...props}
          value={value}
          type={type}
          onChange={(e) => onChange(e.target.value)}
          variant={variant}
          margin={margin}
          id={name}
          error={!!error}
          helperText={error?.message}
          className="yao-input-override"
        />
      )}
    />
  );
}
