import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import {
  createDropdown,
  Model,
  addListToDropdown,
  addToolbarToDropdown,
  LabeledFieldView,
  createLabeledInputText,
} from "@ckeditor/ckeditor5-ui";
import { Collection } from "@ckeditor/ckeditor5-utils";
import { getFormTemplates } from "src_lawfirm/api/forms";
import { FormTemplate } from "src_common/@interfaces/forms";
import DropdownView from "@ckeditor/ckeditor5-ui/src/dropdown/dropdownview";
import { Editor } from "@ckeditor/ckeditor5-core";
import { EditorWithUI } from "@ckeditor/ckeditor5-core/src/editor/editorwithui";

const sanitizeFormsTemplate = (text: string): string => {
  const removedSpecialCharStr = text.replace(/[^a-zA-Z0-9-]/g, "");
  const replacedDotWithHifenStr = removedSpecialCharStr.replace(/\./g, "-");
  return replacedDotWithHifenStr;
};

function prepareFields(template: FormTemplate) {
  const preparedFields = [];
  const { fields } = template;
  if (fields) {
    for (let field of fields) {
      const sanitezedTemplateLabel = sanitizeFormsTemplate(template.label);
      const sanitezedFieldLabel = sanitizeFormsTemplate(field.label);
      const fieldStringId = `forms.${sanitezedTemplateLabel}.${sanitezedFieldLabel}`;
      preparedFields.push({
        type: "button",
        model: new Model({
          id: `{${fieldStringId}}`,
          withText: true,
          label: field.name,
        }),
      });
    }
  }
  return preparedFields;
}

function createFieldsDropdownViews(
  templates: FormTemplate[],
  locale: any,
  editor: Editor | EditorWithUI
) {
  const views: DropdownView[] = [];

  for (const template of templates) {
    if (template.fields && template.fields?.length > 0) {
      const dropdownView = createDropdown(locale);
      dropdownView.set({ id: template.title });

      dropdownView.buttonView.set({
        id: template.title,
        label: template.title,
        withText: true,
        tooltip: true,
      });
      const items = new Collection();
      const preparedFields = prepareFields(template);
      preparedFields.forEach((element) => {
        items.add(element);
      });
      // @ts-ignore
      addListToDropdown(dropdownView, items);

      dropdownView.on("execute", (e) => {
        const { id } = e.source;
        if (!`${id || ""}`.length) {
          return;
        }

        editor.model.change((writer) => {
          const selection = editor.model.document.selection;
          const currentAttrs = selection.getAttributes();
          const position = selection.focus;

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          writer.insertText(id, currentAttrs, position);
        });
      });

      views.push(dropdownView);
    }
  }
  return views;
}

class YaoFormFieldCheatSheetPlugin extends Plugin {
  private async list() {
    try {
      const response = await getFormTemplates(undefined);
      if (response) {
        return response;
      }
      return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async init() {
    const editor = this.editor;
    const formTemplates = await this.list();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    editor.ui.componentFactory.add(
      "yaoFormFieldCheatSheet",
      // @ts-ignore
      (locale) => {
        const dropdownTemplates = createDropdown(locale);
        const dropdownFields = createDropdown(locale);

        const templateButtons = createFieldsDropdownViews(
          formTemplates,
          locale,
          editor
        );

        dropdownFields.buttonView.set({
          label: "Form fields",
          withText: true,
          tooltip: true,
        });

        dropdownTemplates.buttonView.set({
          label: "Templates",
          withText: true,
          tooltip: true,
        });

        const textInput = new LabeledFieldView(locale, createLabeledInputText);
        const textField = textInput.fieldView;
        textField.on("input", (e) => {
          const { value } = textField?.element as HTMLInputElement;
          templateButtons.forEach((item) => {
            if (
              !item?.id?.toLocaleLowerCase().includes(value.toLocaleLowerCase())
            ) {
              item.buttonView.set({ isVisible: false, isEnabled: false });
            } else {
              item.buttonView.set({ isVisible: true, isEnabled: true });
            }
          });
        });

        textField.bind("input").to(dropdownTemplates, "isOpen", (value) => {
          dropdownTemplates.set({ isOpen: true, isFocused: false });
          textField.set({ isFocused: true });
        });
        textInput.set({
          id: "filter-form",
          label: "Filter form templates",
          value: "Form templates",
        });
        // @ts-ignore
        addToolbarToDropdown(dropdownTemplates, templateButtons);
        // @ts-ignore
        addToolbarToDropdown(dropdownFields, [textInput, dropdownTemplates]);

        return dropdownFields;
      }
    );
  }
}

export default YaoFormFieldCheatSheetPlugin;
