import { useCallback, useEffect, useState, useRef } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Contact } from "src_lawfirm/api/contacts";
import { DialogAnimate } from "src_common/components/animate";
import { TextField, Stack, Button, Typography } from "@mui/material";
import Iconify from "src_common/components/Iconify";
import { Buffer } from "buffer";
import { useSnackbar } from "notistack";
import "./contact.css";

type ContactDialogProps = {
  contact: Contact;
};

const ContactFillDialog = NiceModal.create<ContactDialogProps>(
  ({ contact }) => {
    const modal = useModal();
    const [url, setUrl] = useState<string>("");
    const inputRef = useRef<HTMLInputElement>();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
      const token = Buffer.from(contact._id || "", "utf8").toString("base64");
      setUrl(
        `${window.location.origin}/forms/contact/${encodeURIComponent(token)}`
      );
    }, [contact]);

    const handleCloseForm = useCallback(() => {
      modal.hide();
    }, [modal]);

    const handleCopy = () => {
      if (!navigator.clipboard) {
        if (inputRef === undefined || inputRef.current === undefined) {
          return;
        }
        inputRef.current.select();
        inputRef.current.focus();
        document.execCommand("copy");
        enqueueSnackbar("URL copied to clipboard", { variant: "success" });
      } else {
        navigator.clipboard.writeText(url).then(() => {
          enqueueSnackbar("URL copied to clipboard", { variant: "success" });
        });
      }
    };

    return (
      <DialogAnimate
        maxWidth="sm"
        open={modal.visible}
        onClose={handleCloseForm}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
        sx={{ p: 2 }}
      >
        <Stack>
          <Typography>
            Invite {contact?.first_name} to fill in the missing information,
            copy and send the link below
          </Typography>
          <TextField
            inputRef={inputRef}
            disabled
            aria-readonly={true}
            value={url}
            className="contact-input-override"
            InputProps={{
              endAdornment: (
                <Button
                  onClick={handleCopy}
                  startIcon={<Iconify icon="charm:copy" />}
                >
                  copy
                </Button>
              ),
            }}
          />
        </Stack>
        <Stack
          direction="row"
          sx={{
            mt: 1,
            justifyContent: "right",
          }}
          spacing={1}
        >
          <Button color="secondary" onClick={handleCloseForm}>
            close
          </Button>
        </Stack>
      </DialogAnimate>
    );
  }
);

export default ContactFillDialog;
