import { styled } from "@mui/material/styles";
import { Box, AppBar, Toolbar, Stack } from "@mui/material";
import cssStyles from "src_common/utils/cssStyles";
import { HEADER, NAVBAR } from "src_common/config";
import useOffSetTop from "src_common/hooks/useOffSetTop";
import useResponsive from "src_common/hooks/useResponsive";
import { IconButtonAnimate } from "src_common/components/animate";
import Iconify from "src_common/components/Iconify";
import AccountPopover from "./AccountPopover";
import palette from "src_common/theme/palette";
import AddItemPopover from "./AddItemPopover";
import TimerPopover from "./TimerPopover";
import MatterSearchBar from "src_common/components/MatterSearchBar";
import NotificationPopover from 'src_common/components/notifications/NotificationPopover'
import React, {useMemo} from "react";

import Logo from 'src_common/components/Logo'
import ClientLogo from 'src_common/components/ClientLogo'

type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== "isCollapse" && prop !== "isOffset" && prop !== "verticalLayout",
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: "none",
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(
    ["width", "height", "background", "boxShadow"],
    {
      duration: theme.transitions.duration.shorter,
    }
  ),
  [theme.breakpoints.up("lg")]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    background: palette.yao.grey[3],
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      background: palette.yao.grey[1],
      boxShadow: `4px 4px 45px ${palette.yao.grey[8_12]}`,
    }),
    ...(verticalLayout && {
      width: "100%",
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
  },
}));

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
};

export default function MainHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: Props) {
  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive("up", "lg");

  const appEnv  = useMemo<"backoffice" | "lawfirm" | "client">(() => {
    return process.env.REACT_APP_NAME as any
  }, [process.env.REACT_APP_NAME])

  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
    >
      <Toolbar
        sx={{
          minHeight: "100% !important",
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && appEnv !== "client" && <Logo sx={{ mr: 2.5 }} />}
        {isDesktop && verticalLayout && appEnv === "client" && <ClientLogo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        {process.env.REACT_APP_NAME === "lawfirm" && <MatterSearchBar />}

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 1, sm: 2.5 }}>
          {appEnv === "lawfirm" && <TimerPopover />}
          {appEnv === "lawfirm" && <AddItemPopover />}
          {appEnv === "lawfirm" && <NotificationPopover />}
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
