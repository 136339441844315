import { Box, IconButton } from "@mui/material";
import Iconify from "../Iconify";
import MenuPopover from "../MenuPopover";

type Props = {
  actions: React.ReactNode;
  open?: HTMLElement | null;
  onClose?: VoidFunction;
  onOpen?: (event: React.MouseEvent<HTMLElement>) => void;
};

export default function TableMoreMenu({
  actions,
  open,
  onClose,
  onOpen,
}: Props) {
  return (
    <Box
      className="TableMoreMenu"
      sx={{
        position: "absolute",
        right: 16,
        display: "flex",
        alignItems: "center",
        height: "inherit",
        opacity: 0,
        transition: "opacity 200ms",
      }}
    >
      <IconButton onClick={onOpen}>
        <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={onClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 160,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
            "& svg": { mr: 2, width: 20, height: 20 },
          },
        }}
      >
        {actions}
      </MenuPopover>
    </Box>
  );
}
