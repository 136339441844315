import { useState } from "react";
import { useSnackbar } from "notistack";
import {
  archiveContact,
  Contact,
  ContactType,
  getContacts,
  sendSmsCode,
} from "src_lawfirm/api/contacts";
import { formatError } from "src_common/utils/misc";
import NiceModal from "@ebay/nice-modal-react";
import ContactDialog from "./ContactDialog";
import ContactFillDialog from "./ContactFillDialog";
import ContactCard from "./ContactCard";

export type UseContactsHook = {
  loading: boolean;
  endReached: boolean;
  invoke: (
    search?: string,
    limit?: number,
    isArchived?: boolean,
    type?: ContactType | undefined,
    tag?: string,
    ids_filter?: string[]
  ) => Promise<void>;
  data: Contact[];
  error: object | null;
  createDialog: (isCompany?: boolean) => Promise<Contact | null>;
  editDialog: (contact: Contact) => Promise<Contact | null>;
  inviteDialog: (contact: Contact) => void;
  requestSmsCode: (id: string) => Promise<boolean>;
  contactCard: (id: string, view_only?: boolean, author?: string) => void;
  reset: () => void;
  archive: (id: string) => void;
};

export const useContacts = (): UseContactsHook => {
  const [data, setData] = useState<Contact[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [endReached, setEndReached] = useState<boolean>(false);
  const [error, setError] = useState<object | null>(null);
  const [lastSearch, setLastSearch] = useState<string>("");
  const [lastType, setLastType] = useState<ContactType | undefined>(undefined);
  const [lastTag, setLastTag] = useState<string | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();

  const invoke = async (
    search = "",
    limit = 50,
    isArchived = false,
    type: ContactType | undefined = undefined,
    tag = undefined,
    ids_filter = []
  ) => {
    if (loading) {
      return;
    }
    let lastRow = data.length > 0 ? data[data.length - 1]._id : "";
    if (search !== lastSearch || type !== lastType || tag !== lastTag) {
      setLastSearch(search);
      setLastType(type);
      setLastTag(tag);
      setData([]);
      setEndReached(false);
      setError(null);
      lastRow = "";
    }

    setLoading(true);
    try {
      const response = await getContacts({
        search,
        limit,
        last_id: lastRow,
        is_archived: isArchived,
        type,
        tag,
        ids_filter,
      });
      const converted = response.map((r) => ({
        ...r,
        name:
          r.type === ContactType.Company
            ? r.company_name
            : `${r.first_name} ${r.last_name}`,
      }));
      setData((old) => [...old, ...converted]);
      setEndReached(!response.length);
      setError(null);
    } catch (e) {
      setError(e);
      enqueueSnackbar(formatError(e), { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const createDialog = (isCompany = false) => {
    return new Promise<Contact | null>((resolve, reject) => {
      NiceModal.show<Contact>(ContactDialog, {
        isCompany,
      })
        .then((respone) => {
          if (respone !== null && respone !== undefined) {
            setData((old) => [
              {
                ...respone,
                name:
                  respone.type === ContactType.Company
                    ? respone.company_name
                    : `${respone.first_name} ${respone.last_name}`,
              },
              ...old,
            ]);
          }
          resolve(respone || null);
        })
        .catch(reject);
    });
  };

  const editDialog = (contact: Contact, view_only: boolean) => {
    return new Promise<Contact | null>((resolve, reject) => {
      NiceModal.show<Contact>(ContactDialog, {
        contact,
      })
        .then((respone) => {
          if (respone !== null && respone !== undefined) {
            setData((old) =>
              old.map((o) =>
                o._id === respone._id
                  ? {
                      ...o,
                      ...respone,
                      name:
                        respone.type === ContactType.Company
                          ? respone.company_name
                          : `${respone.first_name} ${respone.last_name}`,
                    }
                  : o
              )
            );
          }
          resolve(respone || null);
        })
        .catch(reject);
    });
  };

  const inviteDialog = (contact: Contact) => {
    NiceModal.show(ContactFillDialog, { contact });
  };

  const requestSmsCode = (id: string) => {
    return new Promise<boolean>((resolve, reject) => {
      sendSmsCode(id)
        .then((success) => {
          resolve(success);
        })
        .catch(reject);
    });
  };

  const contactCard = (id: string, view_only: boolean, author?: string) => {
    NiceModal.show(ContactCard, { id, view_only, author });
  };

  const reset = () => {
    setData([]);
    setLoading(false);
    setEndReached(false);
    setError(null);
    setLastSearch("");
    setLastType(undefined);
    setLastTag(undefined)
  }

  const archive = async (id: string) => {
    try {
      const response = await archiveContact(id);
      if(response){
        setData(old => old.filter(d => d._id !== id));
      } else {
        enqueueSnackbar("Try again later", { variant: "error" });
      }
    } catch (e) {
      enqueueSnackbar(formatError(e), { variant: "error" });
    }
  }

  return {
    loading,
    endReached,
    invoke,
    data,
    error,
    createDialog,
    editDialog,
    inviteDialog,
    requestSmsCode,
    contactCard,
    reset,
    archive,
  };
};

export function contactNameResolver(contact: Contact, fullName = false) {
  if (!contact) {
    return "";
  }
  if (contact?.type === ContactType.Company) {
    return contact.company_name;
  }
  if (fullName) {
    return `${contact.first_name} ${
      contact.middle_name?.length > 0 ? contact.middle_name + " " : " "
    }${contact.last_name}`;
  }
  return (contact.display_name || '').trim().length > 0 ? contact.display_name : `${contact.first_name} ${contact.last_name}`;
}
