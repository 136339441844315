import { formatError } from "src_common/utils/misc";
import axios from "src_common/utils/axios";

export enum BankAccountTypes {
  CLIENT = "CLIENT",
  OFFICE = "OFFICE",
  EXTERNAL = "EXTERNAL",
  MATTER = 'MATTER'
}

export async function createBankAccount(
  form: BankAccountFormData
): Promise<BankAccount> {
  try {
    const res = await axios.post("bank-accounts/", form);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function editBankAccount(
  id: string,
  form: BankAccountFormData
): Promise<BankAccount> {
  try {
    const res = await axios.patch(`bank-accounts/${id}`, form);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function getBankAccount(type: any): Promise<BankAccount[]> {
  const res = await axios.get("bank-accounts/", { params: { type } });
  return res.data;
}

export async function getIntegrationAccounts(): Promise<XeroBankAccount[]> {
  const res = await axios.get("bank-accounts/integration/");
  return res.data;
}

export async function patchIntegrationAccounts(payload: Array<object>): Promise<any> {
  const res = await axios.patch("bank-accounts/integration/", payload);
  return res.data;
}

export async function getOAuthUri(): Promise<OAuthUri> {
  const res = await axios.get("law-firms/oauth/uri", {});
  return res.data;
}

export async function oauthCallback(code: string): Promise<void> {
  const res = await axios.patch("law-firms/oauth/callback", {
    code
  });
  return res.data;
}

export type BankAccountFormData = {
  name: string;
  account_number: string;
  sorting_code: string;
  type: string;
  iban: string;
  swift_code: string;
};

export type BankAccount = {
  _id: string;
  name: string;
  type: string;
  account_number: string;
  sorting_code: string;
  law_firm: string;
  created_at: string;
  integration_account_id: string;
  integration_account_code: string;
  iban: string;
  swift_code: string;
};

export type OAuthUri = {
  uri: string
}

export type XeroBankAccount = {
  _id: string;
  code: string;
  name: string;
  description: string;
  class: string;
  type: string;
}