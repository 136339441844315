import * as Yup from "yup";
import { DatePicker, LoadingButton } from "@mui/lab"
import { Button, DialogActions, Grid, IconButton, ListSubheader, TextField, Select, MenuItem, FormControl, Typography } from "@mui/material"
import { FormProvider, RHFTextArea, RHFTextField } from "src_common/components/hook-form"
import { createTask, TaskCategory, TaskForm, TaskPriority, TaskStatus } from "src_lawfirm/api/tasks";
import { useForm } from "react-hook-form";
import { Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import palette from "src_common/theme/palette";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import useAuth from "src_common/hooks/useAuth";
import CloseIcon from '@mui/icons-material/Close';
import { YaoFormFieldLabel } from 'src_common/components/yao-form/YaoForm';
import { YAOFieldAutocomplete } from 'src_common/components/yao-form-fields';
import { Attorney, getAttorneys } from "src_lawfirm/api/attorneys";
import { useAPI } from "src_common/hooks/useAPI";
import { getMatter } from "src_lawfirm/api/matters";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';


const TaskSchema = Yup.object().shape({
  title: Yup.string().required("Heading is required"),
  assigned_to: Yup.string().required("Assigned to is required"),
});

interface IProps {
  matterId: string;
  setAddForm: (val: boolean) => void;
  callTasksAPI: () => void;
}

export const AddNewTask = ({ matterId, callTasksAPI, setAddForm }: IProps) => {
  const { user } = useAuth();
  const [date, setDate] = useState<Date | undefined>(new Date());
  const { enqueueSnackbar } = useSnackbar();

  const attorneysAPI = useAPI(getAttorneys);
  const matterAPI = useAPI(getMatter);


  useEffect(() => {
    attorneysAPI.invoke();
  }, [])

  useEffect(() => {
    matterAPI.invoke(matterId);
  }, [matterId])

  const methods = useForm<TaskForm>({
    resolver: yupResolver(TaskSchema),
    defaultValues: {
      priority: {
        label: _.startCase(_.lowerCase(TaskPriority.STANDARD)),
        value: TaskPriority.STANDARD
      },
      status: {
        label: _.startCase(_.lowerCase(TaskStatus.TO_DO)),
        value: TaskStatus.TO_DO
      },
      assigned_to: user?._id,
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (form: TaskForm) => {
    try {

      const task = await createTask({
        ...form,
        priority: form.priority.value,
        status: form.status.value,
        category: form.category ? form.category.value : undefined,
        due_date: date,
        matter: matterId,
        notify_flag: form.notify_flag || false,
      });
      enqueueSnackbar(`New task ${task.title} is created`);
      setAddForm(false);
      callTasksAPI();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    }
  }

  const allAttorneys = attorneysAPI.data || [];
  const matterData = matterAPI.data;

  const matterMembers = allAttorneys.filter((a) =>
    a._id === matterData?.responsible_lawyer?._id
    || a._id === matterData?.responsible_supervisor?._id
    || a._id === matterData?.paralegal?._id
    || a._id === matterData?.secretary?._id);

  const otherMembers = allAttorneys.filter((a) => !matterMembers.some(d => d._id === a._id));

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ maxWidth: "400px", p: 2 }}>
          <IconButton onClick={() => setAddForm(false)}><CloseIcon /> </IconButton>
          <Grid item xs={12} sx={{ display: "flex", alignItems: "start", flexFlow: "column", gap: "5px" }}>
            <Typography sx={{ fontSize: "24px", textAlign: "left", color: palette.yao.primary[2], fontWeight: '600' }}>
              Add new task
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <YaoFormFieldLabel name="task_heading_label" label="Task heading" required />
            <RHFTextField name="title" placeholder="Enter task heading" />
          </Grid>
          <Grid item xs={6}>
            <YaoFormFieldLabel name="priority_label" label="Priority" />
            <YAOFieldAutocomplete
              name="priority"
              label=""
              placeholder="Select priority"
              options={(Object.values(TaskPriority) || []).map(
                (priority) => ({
                  value: priority,
                  label: _.startCase(_.lowerCase(priority)),
                })
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <YaoFormFieldLabel name="status_label" label="Status" />
            <YAOFieldAutocomplete
              name="status"
              label=""
              placeholder="Select status"
              options={(Object.values(TaskStatus) || []).map(
                (status) => ({
                  value: status,
                  label: _.startCase(_.lowerCase(status)),
                })
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <YaoFormFieldLabel name="category_label" label="Category" />
            <YAOFieldAutocomplete
              name="category"
              label=""
              placeholder="Select category"
              options={(Object.values(TaskCategory) || []).map(
                (category) => ({
                  value: category,
                  label: _.startCase(_.lowerCase(category)),
                })
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <YaoFormFieldLabel name="due_date_label" label="Due date" />
            <DatePicker
              value={date || null}
              onChange={(newValue) => {
                setDate(newValue || undefined);
              }}
              InputProps={{ style: { height: "40px", paddingLeft: 0 } }}
              inputFormat="dd/MMM/yyyy"
              disablePast
              renderInput={(params) => <TextField sx={{ svg: { color: palette.yao.grey[7], height: '18px', width: '20px' } }} {...params} />}
              disableMaskedInput
              components={{
                OpenPickerIcon: CalendarTodayOutlinedIcon
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <YaoFormFieldLabel name="estimate_time_label" label="Estimate Time" />
            <RHFTextField
              name="estimate_time"
              type="number"
              placeholder="Choose time"
            />
          </Grid>
          <Grid item xs={12}>
            <YaoFormFieldLabel name="assigned_to_label" label="Assigned to" required />
            <Controller
              name="assigned_to"
              control={methods.control}
              render={({ field, fieldState: { error } }) => (
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    {...field}
                    error={!!error}
                    sx={{ height: "40px", p: 0 }}
                  >
                    <ListSubheader>Responsible Persons</ListSubheader>
                    {(matterMembers || []).map((option: Attorney) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.name} {option.surname}
                      </MenuItem>
                    ))}
                   <ListSubheader>Others</ListSubheader>
                    {(otherMembers || []).map((option: Attorney) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.name} {option.surname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <YaoFormFieldLabel name="description" label="Description" />
            <RHFTextArea
              style={{
                padding: "10px",
                borderColor: "rgba(145, 158, 171, 0.16)",
                width: "100%",
                fontFamily: "inherit"
              }}
              name="description"
              minRows={5}
              placeholder="Enter a description"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: 11, color: palette.yao.primary[3] }}>
              <span style={{ color: palette.yao.secondary[2], fontWeight: 600 }}>
                *
              </span>{" "}
              Mandatory fields
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
            <DialogActions>
              <Button variant="outlined" color="inherit" onClick={() => setAddForm(false)}>
                Cancel
              </Button>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                Add
              </LoadingButton>
            </DialogActions>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  )
}