import NiceModal from "@ebay/nice-modal-react";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { formatError } from "src_common/utils/misc";
import { TimeEntry } from "src_lawfirm/api/time-entries";
import type {
  Invoice,
  InvoiceQuery,
  InvoiceSearchParams,
} from "../../api/invoices";
import { invoiceSearch } from "../../api/invoices";
import { InvoiceDialog } from "./InvoiceDialog";

type UseInvoicesHook = {
  loading: boolean;
  endReached: boolean;
  invoke: (params: InvoiceQuery) => Promise<void>;
  data: Invoice[];
  error: object | null;
  createDialog: (
    matter: string,
    entries?: TimeEntry[],
    allWip?: boolean
  ) => Promise<Invoice | null>;
};

export const useInvoices = (): UseInvoicesHook => {
  const [data, setData] = useState<Invoice[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [endReached, setEndReached] = useState<boolean>(false);
  const [error, setError] = useState<object | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState<number>(0);
  const [lastQuery, setLastQuery] = useState<InvoiceQuery>();

  const invoke = async (params: InvoiceQuery) => {
    if (loading) {
      return;
    }
    if (!_.isEqual(params, lastQuery)) {
      setLastQuery(params);
      setData([]);
      setEndReached(false);
      setError(null);
      setPage(0);
    }
    setLoading(true);
    try {
      const response = await invoiceSearch({
        page,
        size: 50,
        ...params,
      } as InvoiceSearchParams);
      setData((old) => [...old, ...response]);
      setEndReached(!response.length);
      setError(null);
      setPage((p) => p + 1);
    } catch (e) {
      setError(e);
      enqueueSnackbar(formatError(e), { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const createDialog = (matter: string, entries: TimeEntry[] = [], allWip = false) => {
    return new Promise<Invoice | null>((resolve, reject) => {
      NiceModal.show<Invoice>(InvoiceDialog, { matter, entries, allWip })
        .then((respone) => {
          if (respone !== null && respone !== undefined) {
            setData((old) => [respone, ...old]);
          }
          resolve(respone || null);
        })
        .catch(reject);
    });
  };

  return {
    loading,
    endReached,
    invoke,
    data,
    error,
    createDialog,
  };
};
