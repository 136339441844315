import { createRef, FC, RefObject } from "react";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor, {
  CKEDITOR_DEFAULT_TOOLBAR,
  CKEDITOR_IMAGE_TOOLBAR,
} from "src_common/utils/ckeditor";
import "./ckEditor.css";
import { Box } from "@mui/material";
import ReactDOM from "react-dom";

type YaoCKEditorProps = {
  name: string;
  height?: number;
  rules?: Omit<
    RegisterOptions<Record<string, any>, string>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  defaultValue?: string;
  imageUpload?: boolean;
};

export const YAOCKEditor: FC<YaoCKEditorProps> = ({
  name,
  height = 250,
  rules = {},
  defaultValue = "",
  imageUpload = false,
}) => {
  const { control } = useFormContext();
  const toolbarRef: RefObject<HTMLDivElement> = createRef();
  const inputFileRef: RefObject<HTMLInputElement> = createRef();

  return (
    <>
      <div ref={toolbarRef}></div>
      <Box
        sx={{
          borderColor: "#ccced1",
          borderStyle: "solid",
          borderLeftWidth: 1,
          borderBottomWidth: 1,
          borderRightWidth: 1,
          borderTopWidth: 0,
        }}
      >
        <Controller
          control={control}
          name={name}
          rules={rules}
          defaultValue={defaultValue}
          render={({
            field: { value = "", onChange, onBlur, ref, ...rest },
          }) => (
            <CKEditor
              editor={ClassicEditor}
              data={value}
              id={name}
              onChange={(event, editor) => {
                onChange(editor.getData());
              }}
              onBlur={() => onBlur()}
              config={{
                toolbar: imageUpload
                  ? CKEDITOR_IMAGE_TOOLBAR
                  : CKEDITOR_DEFAULT_TOOLBAR,
                yaoImageUpload: {
                  fileRef: inputFileRef,
                },
              }}
              onReady={(editor) => {
                const el = ReactDOM.findDOMNode(
                  toolbarRef.current as HTMLDivElement
                );
                if (el instanceof Element) {
                  el.appendChild(editor.ui.view.toolbar.element);
                }

                editor.editing.view.change((writer: any) => {
                  writer.setStyle(
                    "height",
                    `${height}px`,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    editor.editing.view.document.getRoot()
                  );
                });

                editor.execute("fontFamily", {
                  value: "Tahoma, Verdana, Segoe, sans-serif",
                });
                editor.execute("fontSize", { value: "15px" });
              }}
            />
          )}
        />
      </Box>
      <input
        type="file"
        ref={inputFileRef}
        accept="image/png, image/jpeg"
        style={{ display: "none", visibility: "hidden" }}
      />
    </>
  );
};
