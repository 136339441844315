import { format, getTime, formatDistanceToNow } from "date-fns";

export function fDateWithPattern(date: Date | string | number, pattern = 'dd MMM yy') {
  if (!date) return "-";
  return format(new Date(date), pattern);
}

export function fDate(date: Date | string | number) {
  if (!date) return "-";
  return format(new Date(date), 'dd MMM yy');
}

export function fTime(date: Date | string | number) {
  if (!date) return "-";
  return format(new Date(date), "p");
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), "dd MMM yyyy p");
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function fFullDate(date: Date | string | number) {
  if (!date) return "-";
  return format(new Date(date), "dd.MM.yyyy");
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function fSeconds(sec: number) {
  let s: any = sec % 60;
  s = `0${Math.floor(s)}`.slice(-2);

  return `${fMinutes(sec)}:${s}`;
}

export function fMinutes(sec: number) {
  let h: any = sec / 3600;
  h = `0${Math.floor(h)}`.slice(-2);

  let m: any = (sec % 3600) / 60;
  m = `0${Math.floor(m)}`.slice(-2);

  return `${h}:${m}`;
}

export const getFormattedDateSafe = (value: any, fmt: string): string => {
  try {
      return format(
          new Date(value),
          fmt
      );
  } catch (err) {
      return ""
  }
}