import { Theme } from "@mui/material/styles";
import {
  Box,
  SxProps,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
} from "@mui/material";

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
} as const;

type Props = {
  order?: "asc" | "desc";
  orderBy?: string;
  headLabel: any[];
  rowCount?: number;
  numSelected?: number;
  onSort?: (id: string) => void;
  sx?: SxProps<Theme>;
  selectable?: boolean;
  onSelectAllRows: (checked: boolean) => void;
};

export default function TableHeadCustom({
  order,
  orderBy,
  rowCount = 0,
  headLabel,
  numSelected = 0,
  onSort,
  selectable,
  onSelectAllRows,
  sx,
}: Props) {
  const headLabelSortable = headLabel.map((label) => {
    label.sortable = label.sortable ?? false;
    return label;
  });
  return (
    <TableHead sx={sx}>
      <TableRow>
        {selectable && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onSelectAllRows(event.target.checked)
              }
            />
          </TableCell>
        )}

        {headLabelSortable.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={ headCell.alignOverride ? headCell.alignOverride :
              headCell.align || i === headLabelSortable.length - 1
                ? "right"
                : "left"
            }
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ paddingRight: i === headLabelSortable.length - 1 ? "2em !important" : "0.5em", ...headCell.sx }}
          >
            {headCell.sortable && onSort ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => onSort(headCell.id)}
              >
                {headCell.label}

                {orderBy === headCell.id ? (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <TableSortLabel
                hideSortIcon
                active={false}
                sx={{ cursor: "default" }}
              >
                {headCell.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
