import {Fragment, SyntheticEvent, useContext, useMemo, useRef, useState} from "react";
import {Badge, Box, Divider, IconButton, List, ListItem, ListItemButton, ListItemText, Typography} from "@mui/material";
import CustomIcon from "src_common/components/custom-icon";
import palette from "src_common/theme/palette";
import MenuPopover from "../MenuPopover";
import {HistoryStatus, NotificationContext} from "./NotificationContext";
import {useInfiniteScrollQuery} from "../../hooks/useInfiniteScrollQuery";
import Iconify from "../Iconify";
import {fDateTime} from "../../utils/formatTime";
import {useNavigate} from "react-router-dom";


const NotificationPopover = () => {
    const navigate = useNavigate();
    const contextNotification = useContext(NotificationContext)

    const bellRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState<HTMLElement | null>(null);
    const infiniteScrollRef = useInfiniteScrollQuery({
        fetch: !contextNotification.notifications?.isFetching && contextNotification.notifications?.hasNextPage ? contextNotification.notifications.fetchNextPage : undefined,
    })

    const showNotifications = (event: SyntheticEvent<HTMLElement>) => {
        if (!contextNotification.badge?.data) {
            contextNotification.refresh()
        }
        setOpen(event.currentTarget);
    }

    const hideNotifications = () => {
        setOpen(null);
    }

    const notifications = useMemo(() => (contextNotification.notifications?.data?.pages || []).flatMap(c => c), [contextNotification.notifications?.data])

    return (<>
        <Box ref={bellRef}>
            <IconButton onClick={showNotifications}>
                {(contextNotification.badge?.data || 0) > 0 ? (
                    <Badge badgeContent={contextNotification.badge?.data || 0} color="primary">
                        <CustomIcon name="bell" color={palette.yao.grey[6]}/>
                    </Badge>) : (<CustomIcon name="bell" color={palette.yao.grey[6]}/>)}

            </IconButton>
        </Box>
        <MenuPopover open={Boolean(open)} anchorEl={open} onClose={hideNotifications} sx={{
            width: 400, p: 0, mt: 1.5, ml: 0.75, "& .MuiMenuItem-root": {
                typography: "body2", borderRadius: 0.75,
            },
        }}>
            <Box sx={{height: '200px', overflowX: 'hide', overflowY: 'auto'}}>
                <List sx={{m: 0, p: 0}}>
                    {notifications.map((notification) => (<Fragment>
                            <ListItem key={notification._id}
                                      alignItems="flex-start"
                                      secondaryAction={notification.status === HistoryStatus.UNREAD ? (
                                          <IconButton edge="end" aria-label="hide"
                                                      disabled={contextNotification.maskNotificationAsRead?.isLoading}
                                                      onClick={(event: SyntheticEvent) => {
                                                          event.stopPropagation();
                                                          event.preventDefault();
                                                          contextNotification.maskNotificationAsRead?.mutate(notification._id)
                                                      }}>
                                              <Iconify
                                                  icon="fluent:eye-hide-24-regular"
                                                  fontSize={12}/>
                                          </IconButton>) : undefined}>
                                <ListItemButton onClick={(event: SyntheticEvent) => {
                                    hideNotifications()
                                    navigate(`/matters/${notification.matter._id}/overview`);
                                }}>
                                    <ListItemText primary={notification.description} secondary={<Fragment>
                                        <Typography
                                            sx={{display: 'inline'}}
                                            component="span"
                                            variant="caption"
                                            color="text.primary"
                                        >
                                            {fDateTime(new Date(notification.created_at))}
                                        </Typography>
                                        {" on "} {notification.matter.case_name}
                                    </Fragment>} secondaryTypographyProps={{
                                        variant: 'caption', fontSize: 10,
                                    }}/>
                                </ListItemButton>
                            </ListItem>
                            <Divider variant="fullWidth" component="li"/>
                        </Fragment>))}
                </List>
                <Box ref={infiniteScrollRef.ref} sx={{height: 0}}/>
            </Box>
        </MenuPopover>
    </>)

}

export default NotificationPopover