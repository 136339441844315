import { Avatar, AvatarProps } from "@mui/material";
import useAuth from "../hooks/useAuth";
import createAvatar from "../utils/createAvatar";

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useAuth();

  return (
    <Avatar src={user?.photoURL} alt={user?.name} {...other}>
      {createAvatar(user?.name).name}
    </Avatar>
  );
}
