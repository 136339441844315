import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {Correspondence} from "../../../api/correspondence";
import {AppBar, Box, Button, Dialog, DialogContent, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import React, {useContext, useEffect, useRef, useState} from "react";
import {WopiContextProps} from "src_common/contexts/WopiContext";
import {WOPI_API} from "src_common/config";
import Iconify from "src_common/components/Iconify";
import {SupportedFileTypes} from "src_common/components/yao-form-fields";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import palette from "../../../../src_common/theme/palette";
import CustomIcon from "../../../../src_common/components/custom-icon";

type InputProps = {
    correspondence: Correspondence,
    wopiContext: WopiContextProps,
}

const CorrespondenceFileEditComponent = NiceModal.create<InputProps>(({ correspondence, wopiContext }) => {
    const modal = useModal();
    const formRef = useRef<HTMLFormElement>();
    const [color, setColor] = useState<string>("#ffffff")

    useEffect(() => {
        const ext = correspondence?.key?.split(".").pop() || "";
        setColor([SupportedFileTypes.DOC, SupportedFileTypes.DOCX,].indexOf(ext as SupportedFileTypes) !== -1 ? '#195abd' : '#107c42')
        const complement = ext.endsWith('x') ? 'edit' : 'open';

        // @ts-ignore
        const actionUrl = wopiContext.routes[ext.toLowerCase()][complement];
        const divider = actionUrl.includes('?') ? '&' : '?';
        const origin = encodeURIComponent(`${WOPI_API}/wopi/files/${correspondence?.key || ''}`)
        const url = `${actionUrl}${divider}WOPISrc=${origin}&access_token=${correspondence?.wopi_token || ''}&access_token_ttl=0`;
        setTimeout(() => {
            if(formRef.current) {
                formRef.current.setAttribute('action', url)
                formRef.current.submit();
            }
        }, 1000)
    }, []);




    return <Dialog open={modal.visible}
                   sx={{
                       "& .MuiDialog-container": {
                           height: '100%',
                           "& .MuiPaper-root": {
                               width: "50vw",
                               maxWidth: "75vw",
                               height: '100%',
                               margin: 0
                           },
                       },
                   }}
                   disableEscapeKeyDown={true}
                   onClose={() => modal.hide()}
                   TransitionProps={{
                       onExited: () => modal.remove(),
                   }}>
        <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" sx={{ bgcolor: color }}>
            <Button endIcon={<Iconify icon="carbon:close-outline" color="#ffffff" />} onClick={() => modal.hide()} sx={{ color: '#ffffff' }}>
                close
            </Button>
        </Stack>
        <DialogContent sx={{ p: 0 }}>
            <form ref={formRef} id="office_form" name="office_form" target="office_frame" action="" method="post"></form>
            <iframe sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox"
                    id="office_frame"
                    name="office_frame"
                    title="Office Frame"
                    allowFullScreen={true}
                    allowTransparency={true}
                    style={{ width: '100%', height: '100%', border: 0 }}

            />
        </DialogContent>
        <Stack direction="row" p={1} spacing={1} justifyContent="flex-end" alignItems="center" sx={{ bgcolor: color }}>
            <WarningAmberIcon sx={{ fontSize: 12, color: palette.yao.grey[1] }} />
            <Tooltip title={<Typography display="block">This is a temporary Microsoft Issue (usually updates). You can still access your document by opening in a new window <CustomIcon name="open_url" size={14} color="#000000" sx={{ display: 'inline-block' }} /> or downloading <CustomIcon name="download" size={18} color="#000000" sx={{ display: 'inline-block' }}  />.</Typography>}>
                <Typography fontSize={12} sx={{ cursor: 'pointer', textDecoration: 'underline', color: palette.yao.grey[1] }}>My document is not loading?</Typography>
            </Tooltip>
        </Stack>

    </Dialog>

})

export default CorrespondenceFileEditComponent
