import { Theme } from "@mui/material/styles";

export default function FormControlLabel(theme: Theme) {
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontSize: 12,
        },
        label: {
          fontSize: "inherit",
        },
      },
    },
  };
}
