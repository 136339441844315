import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {Correspondence, CorrespondenceCategory} from "src_lawfirm/api/correspondence";
import {Box, Drawer, Stack} from "@mui/material";
import {useCallback, useState} from "react";
import {
    Button,
    ChatContainer,
    ConversationHeader,
    Loader,
    MainContainer,
    Message,
    MessageList,
    MessageSeparator
} from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {fDateWithPattern} from "src_common/utils/formatTime";
import useAuth from "src_common/hooks/useAuth";
import {explainCorrespondence, GptPromptType} from "../../api/yao-gpt";
import {formatError} from "src_common/utils/misc";

type InputProps = {
    correspondence: Correspondence
}

const YaoCorrespondenceGptDialog = NiceModal.create<InputProps>(({correspondence}) => {

    const modal = useModal();
    const {user} = useAuth();
    const [agreement, setAgreement] = useState<boolean>(false);
    const [time, setTime] = useState<Date[]>([new Date()]);
    const [loading, setLoading] = useState<boolean>(false);
    const [response, setResponse] = useState<{
        message: string | undefined;
        error: string | undefined;
    }>({message: undefined, error: undefined})

    const handleClose = useCallback(() => {
        modal.hide();
        modal.remove();
    }, [modal]);

    const handleAgree = () => {
        setTime(t => [...t, new Date()])
        setLoading(true);
        setAgreement(true);
        (async () => {
            try {
                const response = await explainCorrespondence(correspondence._id,
                    correspondence.category === CorrespondenceCategory.DOCUMENT
                        ? GptPromptType.explain
                        : GptPromptType.bulletPoint
                );
                if (typeof response?.text == 'string' && response.text.length > 0) {
                    setResponse({
                        message: response.text,
                        error: undefined,
                    });
                } else {
                    setResponse({
                        message: undefined,
                        error: 'No response found, try again later.'
                    });
                }
            } catch (error) {
                setResponse({
                    message: undefined,
                    error: formatError(error)
                });
            } finally {
                setLoading(false);
            }
        })();
    }

    const renderAgreement = () => {
        const message = `Before proceeding, you must authorize our platform to transmit the data in this ${correspondence.category === CorrespondenceCategory.DOCUMENT ? "document" : "note"} to be read and processed.`

        if (!agreement) {
            return (<Message model={{
                position: 'single',
                direction: "incoming"
            }}>
                <Message.CustomContent>
                    <p>{message}  Please select on one of the options below </p>
                    <br/>
                    <Stack>
                        <Button onClick={handleClose} style={{
                            color: "#fff",
                            backgroundColor: "#dc3545",
                            borderColor: "#dc3545",
                        }}>Cancel</Button>
                        <Button onClick={handleAgree} border style={{
                            color: "#fff",
                            backgroundColor: "#28a745",
                            borderColor: "#28a745"
                        }}>Agree</Button>
                    </Stack>
                </Message.CustomContent>
                <Message.Footer sender="YaoGPT" sentTime={fDateWithPattern(time[0], 'p')}/>
            </Message>)
        }

        return (
            <>
                <Message model={{
                    position: 'single',
                    direction: "incoming"
                }}>
                    <Message.CustomContent>
                        <p>{message}</p>
                    </Message.CustomContent>
                    <Message.Footer sender="YaoGPT" sentTime={fDateWithPattern(time[0], 'p')}/>
                </Message>
                <Message model={{
                    position: 'single',
                    direction: 'outgoing'
                }}>
                    <Message.TextContent text="I agree!"/>
                    <Message.Footer sender={user?.name} sentTime={fDateWithPattern(time[1], 'p')}/>
                </Message>
            </>
        )
    };

    const renderResponse = () => {
        if (!agreement) {
            return null;
        }

        const now = fDateWithPattern(new Date(), 'p');

        if(loading) {
            return (
                <Message model={{
                    position: 'single',
                    direction: "incoming"
                }}>
                    <Message.CustomContent>
                        <p>analyzing your document</p>
                        <Loader />
                    </Message.CustomContent>
                    <Message.Footer sender="YaoGPT" sentTime={now}/>
                </Message>
            )
        }

        if (`${response.error || ''}`.length > 0) {
            return (
                <>
                    <Message model={{
                        position: 'single',
                        direction: "incoming"
                    }}>
                        <Message.CustomContent>
                            <p>We were unable to perform your analysis at this time.</p>
                            <small>code: {response.error || ''}</small>
                        </Message.CustomContent>
                        <Message.Footer sender="YaoGPT" sentTime={now}/>
                    </Message>
                    <MessageSeparator>
                        <Button border onClick={handleClose}>close</Button>
                    </MessageSeparator>
                </>
            )
        }

        return (
            <>
                <Message model={{
                    position: 'single',
                    direction: "incoming"
                }}>
                    <Message.CustomContent>
                        { correspondence.category === CorrespondenceCategory.DOCUMENT ? (
                            <p>You can explain it like this:</p>
                        ) : null }
                        <p>{response.message || ''}</p>
                    </Message.CustomContent>
                    <Message.Footer sender="YaoGPT" sentTime={now}/>
                </Message>
                <MessageSeparator>
                    <Button border onClick={handleClose}>close</Button>
                </MessageSeparator>
            </>
        )
    }


    return <Drawer anchor="right"
                   open={modal.visible}
                   onClose={handleClose}
                   sx={{
                       zIndex: (theme) => theme.zIndex.modal + 1,
                       '& .MuiDrawer-paper': {
                           width: 500,
                       },
                   }}>
        <Stack direction="column" sx={{height: '100vh'}}>
            <Box>
                <ConversationHeader>
                    <ConversationHeader.Back onClick={handleClose}/>
                    <ConversationHeader.Content userName={correspondence.title}
                                                info={correspondence.category === CorrespondenceCategory.DOCUMENT ? "Explain this document to my client" : "Summarize this note to my client"}/>
                </ConversationHeader>
            </Box>
            <Box flexGrow={1} position="relative">
                <MainContainer>
                    <ChatContainer>
                        <MessageList>
                            <MessageSeparator>
                                welcome {user?.name}
                            </MessageSeparator>
                            {renderAgreement()}
                            {renderResponse()}
                        </MessageList>
                    </ChatContainer>
                </MainContainer>

            </Box>
        </Stack>

    </Drawer>

})

export default YaoCorrespondenceGptDialog;
