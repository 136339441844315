import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Tooltip(theme: Theme) {
  const isLight = theme.palette.mode === "light";

  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "white",
          color: theme.palette.grey[isLight ? 800 : 700],
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        },
        arrow: {
          color: "white",
        },
      },
    },
  };
}
