import { LoadingButton } from "@mui/lab"
import { Autocomplete, Button, DialogActions, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material"
import { useEffect } from "react";
import { Attorney, getAttorneys } from "src_lawfirm/api/attorneys";
import { useAPI } from "src_common/hooks/useAPI";
import { defaultFilters } from "../TasksTab";
import { DialogAnimate } from "src_common/components/animate";
import { CorrespondenceCategory } from "src_lawfirm/api/correspondence";
import { useCorrespondenceContext } from "src_common/hooks/useCorrespondenceApi";
import palette from "src_common/theme/palette";
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
  visible: boolean;
  handleClose: () => void;
  matterId: string;
}

export const CorrespondenceFilters = ({ visible, matterId, handleClose }: IProps) => {
  const attorneysAPI = useAPI(getAttorneys);

  const { filters, setFilters, fetchCorrespondences } = useCorrespondenceContext(matterId);

  useEffect(() => {
    attorneysAPI.invoke();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DialogAnimate
      open={visible}
      onClose={handleClose}
      className="cs_corresponding-modal"
    >
      <IconButton onClick={handleClose} sx={{ display: "flex", justifyContent: "start", width: "fit-content" }}>
        <CloseIcon />
      </IconButton>

      <Typography color={palette.yao.primary[2]} fontSize="21px" fontWeight="600" textAlign="center" pt="10px">
        Filter correspondences
      </Typography>

      <Stack px="40px" pt="15px" pb="20px">
        <Autocomplete
          multiple
          options={Object.values(CorrespondenceCategory).map((category) => category)}
          filterOptions={(o) => o}
          value={filters.category}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={`Choose category`}
            />
          )}
          onChange={(_, newValue) => setFilters({
            ...filters,
            category: newValue ? newValue.map((v) => v) : []
          })}
        />
      </Stack>

      <Stack px="40px" pb="20px">
        <InputLabel sx={{ pb: "10px" }}>Author</InputLabel>
        <Select
          id="author"
          labelId="author-to-select"
          label="Author"
          onChange={(e) => setFilters({ ...filters, author: e.target.value })}
          defaultValue={filters?.author}
        >
          {(attorneysAPI?.data || []).map((attorney: Attorney) => (
            <MenuItem key={attorney._id} value={attorney._id}>{attorney.name}</MenuItem>
          ))}
        </Select>
      </Stack>

      <DialogActions style={{ marginRight: "16px", paddingTop: "0" }}>
        <Button variant="outlined" color="inherit" onClick={handleClose}>
          Cancel
        </Button>

        <Button variant="outlined" color="success" onClick={() => setFilters(defaultFilters)}>
          Reset
        </Button>

        <LoadingButton type="button" variant="contained" onClick={() => fetchCorrespondences(undefined, handleClose)}>
          Apply
        </LoadingButton>
      </DialogActions>
    </DialogAnimate>
  )
}