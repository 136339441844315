import { ROOT_HOME } from "src_common/routes/paths";

const ROOTS_YAOERS = "/yaoers";
const ROOTS_LAW_FIRMS = "/law-firms";
const ROOTS_FORMS = "/forms"

export const PATH_HOME = {
  root: ROOT_HOME,
};

export const PATH_YAOERS = {
  root: ROOTS_YAOERS,
};

export const PATH_LAW_FIRMS = {
  root: ROOTS_LAW_FIRMS,
};

export const PATH_FORMS = {
  root: ROOTS_FORMS,
}