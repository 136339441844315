import {
  createContext,
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useRef,
  useState,
} from "react";
import { TawkAPI } from "@tawk.to/tawk-messenger-react";

export type PropsMenuContext = {
  postOffice: number;
  toSignDocuments: number;
  setPostOffice: Dispatch<SetStateAction<number>>;
  tawkToRef: MutableRefObject<TawkAPI | undefined>;

  forms: number;
  setForms: Dispatch<SetStateAction<number>>;
  setToSignDocuments: Dispatch<SetStateAction<number>>;
};

const DEFAULT_VALUE = {
  postOffice: 0,
  toSignDocuments: 0,
  setPostOffice: () => {},
  setToSignDocuments: () => {},
  tawkToRef: undefined,
  forms: 0,
  setForms: () => {},
};

// @ts-ignore
const MenuContext = createContext<PropsMenuContext>(DEFAULT_VALUE);

const MenuContextProvider: FC = ({ children }) => {
  const [postOffice, setPostOffice] = useState<number>(
    DEFAULT_VALUE.postOffice
  );
  const [toSignDocuments, setToSignDocuments] = useState<number>(
    DEFAULT_VALUE.toSignDocuments
  );
  const [forms, setForms] = useState<number>(DEFAULT_VALUE.postOffice);
  const tawkToRef = useRef<TawkAPI>();

  return (
    <MenuContext.Provider
      value={{
        postOffice,
        setPostOffice,
        tawkToRef,
        forms,
        setForms,
        toSignDocuments,
        setToSignDocuments,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export { MenuContext, MenuContextProvider };
