import { Button, Grid, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {FC, useContext, useEffect, useMemo} from "react";
import { AvatarCell } from "src_common/components/yao-table/custom-cells";
import { useAPI } from "src_common/hooks/useAPI";
import { fCurrencyWithFormat } from "src_common/utils/formatNumber";
import { fFullDate, fMinutes } from "src_common/utils/formatTime";
import { getMatter } from "src_lawfirm/api/matters";
import { TimeEntry } from "src_lawfirm/api/time-entries";
import {LawFirmContext} from "../../law-firm/LawFirmContext";

type TimeEntryAddedByClockProps = {
  timeEntry: TimeEntry;
  onFinish: () => void;
};

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

const TimeEntryAddedByClock: FC<TimeEntryAddedByClockProps> = ({
  timeEntry,
  onFinish,
}) => {
  const lawFirmContext = useContext(LawFirmContext);
  const getMatterAPI = useAPI(getMatter);

  const currInfo = useMemo(() => lawFirmContext.getCurrency(), [lawFirmContext]);

  useEffect(() => {
    getMatterAPI.invoke(timeEntry.matter);
  }, []);

  return (
    <>
      <Grid container rowSpacing={4} columnSpacing={2.5}>
        <Grid item xs={12}>
          <Typography
            variant="h2"
            align="center"
            paragraph
            style={{ marginTop: 16 }}
          >
            Time Entry is added
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ pt: "0px !important" }}>
          <Typography variant="subtitle2" align="center">
            {getMatterAPI?.data?.case_name}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Label variant="body2" paragraph>
            TIME
          </Label>
          <Typography>{fMinutes(timeEntry.duration_minutes * 60)}</Typography>
        </Grid>

        <Grid item xs={3}>
          <Label variant="body2" paragraph>
            DATE POSTED
          </Label>
          <Typography>{fFullDate(timeEntry.date)}</Typography>
        </Grid>

        <Grid item xs={3}>
          <Label variant="body2" paragraph>
            TYPE
          </Label>
          <Typography>{timeEntry.work_type}</Typography>
        </Grid>

        <Grid item xs={3}>
          <Label variant="body2" paragraph>
            DESCRIPTION
          </Label>
          <Typography>{timeEntry.description}</Typography>
        </Grid>

        <Grid item xs={3}>
          <Label variant="body2" paragraph>
            USER
          </Label>
          <AvatarCell data={{ name: timeEntry.assignee.name }} />
        </Grid>

        <Grid item xs={3}>
          <Label variant="body2" paragraph>
            UNITS
          </Label>
          <Typography>{timeEntry.units}</Typography>
        </Grid>

        <Grid item xs={3}>
          <Label variant="body2" paragraph>
            UNIT VALUE
          </Label>
          <Typography>
            {fCurrencyWithFormat(Math.ceil(timeEntry.rate / 10), currInfo.locale, currInfo.currency, currInfo.symbol)}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Label variant="body2" paragraph>
            WORK VALUE
          </Label>
          <Typography>{fCurrencyWithFormat(timeEntry.billable, currInfo.locale, currInfo.currency, currInfo.symbol)}</Typography>
        </Grid>
      </Grid>

      <Stack
        direction="row"
        sx={{
          mt: 4,
          justifyContent: "right",
        }}
        spacing={2}
      >
        <Button
          variant="text"
          sx={{ minWidth: 100 }}
          onClick={() => onFinish()}
        >
          Close
        </Button>
        <Button
          variant="contained"
          sx={{ minWidth: 100 }}
          href={`/matters/${timeEntry.matter}/time`}
        >
          Go to Matter
        </Button>
      </Stack>
    </>
  );
};

export default TimeEntryAddedByClock;
