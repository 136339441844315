export function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

export function formatError(e: any): string {
  let message = ""
  if(typeof  e === "string"){
    message = e
  }
  else if (typeof e?.message === "string") {
    message = e.message
  }
  else if (e?.message?.length > 0 && typeof e.message[0] === "string") {
    message = e.message[0]
  }
  return !message.length || message.trim().toLowerCase() === "internal server error" ? "Oops. A problem occurred, we are looking into it" : message
}

export function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function base64ToBlob(base64Data: string, contentType: string, sliceSize = 512) {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, {type: contentType});
}
