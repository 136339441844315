import { Link as RouterLink } from "react-router-dom";
import { Box, BoxProps } from "@mui/material";
import {useContext, useMemo} from "react";
import {DomainContext} from "../../src_client/providers/domain-provider";

interface Props extends BoxProps {
    disabledLink?: boolean;
}

export default function ClientLogo({ disabledLink = false, sx }: Props) {

    const logoUrl = useMemo(() => {
        const url = localStorage.getItem('logo_url') || '';
        return url?.trim()?.length > 0 ? url.trim() : '/assets/logo.svg'
    }, [localStorage.getItem('logo_url')])

    const logo = (
        <Box display="flex">
            <Box component="img" src={logoUrl} m={1} maxWidth="125px" />
        </Box>
    );

    if (disabledLink) {
        return <>{logo}</>;
    }

    return (
        <RouterLink to="/" style={{ textDecoration: "none" }}>
            {logo}
        </RouterLink>
    );
}
