import NiceModal from "@ebay/nice-modal-react";
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    IconButton,
    Link,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useSnackbar} from "notistack";
import {SyntheticEvent, useEffect, useMemo, useRef, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import CustomIcon from "src_common/components/custom-icon";
import Dialog from "src_common/components/dialog/Dialog";
import MenuPopover from "src_common/components/MenuPopover";
import {YaoFormFieldLabel} from "src_common/components/yao-form/YaoForm";
import {useAPI} from "src_common/hooks/useAPI";
import {useTimer} from "src_common/hooks/useTimer";
import palette from "src_common/theme/palette";
import {fSeconds} from "src_common/utils/formatTime";
import {formatError} from "src_common/utils/misc";
import {getMatters, Matter} from "src_lawfirm/api/matters";
import {createTimeTrack, getTimeTracks, stopTimeTrack, TimeTrack,} from "src_lawfirm/api/time-track";
import {
    MatterAutoCompleteFilterHelper,
    MatterAutoCompleteRenderOptionHelper,
} from "src_lawfirm/pages/matters/MatterAutoCompleteHelper";
import ClockTimeEntry, {ClockTimeEntryData,} from "src_lawfirm/pages/matters/TimeEntry/ClockTimeEntry";
import TimeEntryAddedByClock from "src_lawfirm/pages/matters/TimeEntry/TimeEntryAddedByClock";
import useDebounce from "../../hooks/useDebounce";
import {Contact} from "src_lawfirm/api/contacts";
import {contactNameResolver} from "src_lawfirm/pages/contacts/useContacts";

const CLOCK_MIN_WIDTH = 220;

function Timer({
                   timeTrack,
                   onStop,
                   main = false,
               }: {
    timeTrack: TimeTrack;
    onStop: Function;
    main?: boolean;
}) {
    const timer = useTimer(timeTrack);
    const handleToggle = () => {
        timer.toggle();
    };

    const isRunning = timeTrack.status === "Running";
    const isRunningOrMain = isRunning || main;
    const clientsNames = timeTrack?.matter?.clients?.map((c) => contactNameResolver(c.contact as Contact)).filter(c => `${c}`.length > 0);
    const clients = !clientsNames?.length ? "No record" : clientsNames.join(", ");

    return (
        <Box
            sx={{
                filter: `drop-shadow(0px 8px 16px rgba(43, 169, 175, 0.24))`,
                position: "relative",
                color: palette.yao.primary[3],
                backgroundColor: isRunningOrMain ? palette.yao.secondary[1] : "#259FA5",
                display: "grid",
                gridTemplateColumns: "12px 12px 1fr",
                gap: 1,
                py: 0,
                pl: 0.5,
                pr: 0,
                height: 32,
                alignItems: "center",
            }}
        >
            <IconButton sx={{p: 0}} onClick={handleToggle}>
                <CustomIcon
                    name={isRunning ? "pause" : "start"}
                    color={palette.yao.grey[2]}
                    size={12}
                />
            </IconButton>
            <IconButton sx={{p: 0}} onClick={() => onStop()}>
                <CustomIcon name="stop" color={palette.yao.grey[2]} size={12}/>
            </IconButton>
            <Tooltip
                arrow
                placement="right"
                componentsProps={{
                    tooltip: {
                        sx: {
                            p: 2.5,
                            maxWidth: 200,
                            marginLeft: "4px !important",
                        },
                    },
                }}
                title={
                    <Box>
                        <Typography variant="caption">CLIENTS</Typography>
                        <Typography variant="body2" paragraph>
                            {clients}
                        </Typography>
                        <Typography variant="caption">MATTER</Typography>
                        <Typography variant="body2">
                            {timeTrack.matter.case_name}
                        </Typography>
                    </Box>
                }
            >
                <Link
                    href={`/matters/${timeTrack.matter._id}/time`}
                    underline="none"
                    sx={{
                        color: isRunningOrMain ? "white" : "#B9EEF0",
                        cursor: "pointer",
                        pr: 0.5,
                        pl: 1,
                        width: "-webkit-fill-available",
                        fontSize: 12,
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                    }}
                >
          <span
              style={{
                  maxWidth: "100px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  display: "inline-block",
                  whiteSpace: "nowrap",
              }}
          >
            {clients}&nbsp;
          </span>
                    <span
                        style={{
                            width: "60px",
                            maxWidth: "60px",
                            minWidth: "60px",
                        }}
                    >
            {fSeconds(timer.timer) || "-"}
          </span>
                </Link>
            </Tooltip>
        </Box>
    );
}

export default function TimerPopover() {
    const {matterId} = useParams();
    const {debounce, reset: clearDebounce} = useDebounce();
    const {enqueueSnackbar} = useSnackbar();
    const location = useLocation();
    const rootRef = useRef<HTMLDivElement>(null);

    const [listOpen, setListOpen] = useState<HTMLElement | null>(null);
    const [showMatterSelector, setShowMatterSelector] =
        useState<HTMLElement | null>(null);
    const [selectedMatter, setSelectedMatter] = useState<Matter | undefined>(undefined);
    const [inputValue, setInputValue] = useState<string>("");
    const [open, setOpen] = useState<boolean>(false);
    const queryClient = useQueryClient();

    const stopTracking = useMutation(stopTimeTrack, {
        onSuccess: (timeEntry) => {
            queryClient.invalidateQueries(["time-trackers"]);
            queryClient.invalidateQueries([matterId, "time-entries"]);
            NiceModal.show(Dialog, {
                Content: TimeEntryAddedByClock,
                params: {timeEntry},
            });
        },
        onError: (error) => {
            enqueueSnackbar(formatError(error), {variant: "error"});
            queryClient.invalidateQueries(["time-trackers"]);
            queryClient.invalidateQueries([matterId, "time-entries"]);
        },
    });

    const startTracking = useMutation(createTimeTrack, {
        onSuccess: () => {
            queryClient.invalidateQueries(["time-trackers"]);
        },
    });

    const getTimeTracksAPI = useQuery(["time-trackers"], getTimeTracks);
    const getMattersAPI = useAPI(getMatters);

    const timeTracks = getTimeTracksAPI.data || [];

    const query = useMemo(
        () => {
            let n: number | undefined = undefined;
            try {
                n = parseInt(inputValue, 10);
                if (isNaN(n) || !n) {
                    n = undefined
                }
            } catch (e) {
                n = undefined
            }
            let s: string | undefined = inputValue.length > 0 ? inputValue : undefined;
            if (!!n) {
                s = undefined
            }
            return {
                search: s,
                number: n,
                limit: 10,
            };
        },
        [inputValue]
    );

    useEffect(() => {
        getMattersAPI.invoke(query, (data) => {
            setTimeout(() => {
                loadDefaultForMatter(matterId);
            }, 500)
        });
    }, []);

    useEffect(() => {
        clearDebounce();
        debounce(async () => {
            try {
                getMattersAPI.invoke(query);
            } catch (error) {
                enqueueSnackbar(formatError(error), {variant: "error"});
            } finally {
                clearDebounce();
            }
        }, 250);
    }, [query]);

    useEffect(() => {
        if(`${matterId || ''}`.length > 0) {
            loadDefaultForMatter(matterId);
        } else {
            setSelectedMatter(undefined)
        }
    }, [matterId])

    const loadDefaultForMatter = (mId: string = '') => {
        if (!Array.isArray(getMattersAPI.data) || !`${mId || ''}`.length || selectedMatter?._id === mId) {
            return;
        }
        const indexOf = getMattersAPI.data.findIndex(i => i._id === mId);
        if (indexOf !== -1) {
            setSelectedMatter(extractMatterOption(getMattersAPI.data[indexOf] as Matter));
            return;
        }
        getMattersAPI.invoke({
            ids: [mId],
        }, (data) => {
            if(Array.isArray(data) && data.length > 0) {
                setSelectedMatter(extractMatterOption(data[0] as Matter));
            }
        })

    }

    const handleMatterSelectorOpen = () => {
        setShowMatterSelector(rootRef.current);
    };

    const handleListOpen = () => {
        setListOpen(rootRef.current);
    };

    const handleMatterSelectorClose = () => {
        setShowMatterSelector(null);
    };

    const handleListClose = () => {
        setListOpen(null);
    };

    const handleStartTracking = async () => {
        if (!selectedMatter) return;
        startTracking.mutate(selectedMatter?._id);
        handleMatterSelectorClose();
    };

    const handleTimerStop = async (timeTrack: TimeTrack) => {
        NiceModal.show<ClockTimeEntryData>(Dialog, {
            Content: ClockTimeEntry,
            params: {
                case_name: timeTrack.matter.case_name,
                matterId: timeTrack.matter._id,
            },
        }).then((data) => {
            stopTracking.mutate({timeTrackId: timeTrack._id, data});
            handleListClose();
        });
    };

    const extractMatterOption = (m: Matter) => {
        return ({
            ...m,
            label: m.case_name,
            value: m._id,
        })
    }

    if (getTimeTracksAPI.isLoading) return null;


    return (
        <div ref={rootRef}>
            {timeTracks.length > 0 ? (
                Boolean(listOpen) ? (
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        sx={{
                            background: palette.yao.primary[4],
                            borderRadius: 1,
                            pr: 0.5,
                            pl: 1,
                            minHeight: 32,
                            minWidth: CLOCK_MIN_WIDTH,
                        }}
                    >
                        <Typography
                            variant="caption"
                            sx={{fontWeight: 600, color: palette.yao.primary[3], px: 2}}
                        >
                            Current Recordings
                        </Typography>

                        <Button
                            onClick={handleListOpen}
                            endIcon={
                                <CustomIcon
                                    name="arrow"
                                    sx={{p: 0.55}}
                                    color={palette.yao.grey[6]}
                                />
                            }
                            sx={{
                                background: "white",
                                height: 26,
                                minWidth: "unset",
                                "&:hover": {background: "white"},
                            }}
                        >
                            {timeTracks.length}
                        </Button>
                    </Stack>
                ) : (
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        sx={{
                            background: palette.yao.secondary[1],
                            borderRadius: 1,
                            pr: 0.5,
                            pl: 1,
                            minHeight: 32,
                            minWidth: CLOCK_MIN_WIDTH,
                        }}
                    >
                        <Timer
                            timeTrack={timeTracks[0]}
                            onStop={() => handleTimerStop(timeTracks[0])}
                            main
                        />

                        <Button
                            onClick={handleListOpen}
                            endIcon={
                                <CustomIcon
                                    name="arrow"
                                    sx={{p: 0.55}}
                                    color={palette.yao.grey[6]}
                                />
                            }
                            sx={{
                                background: "white",
                                height: 26,
                                minWidth: "unset",
                                "&:hover": {background: "white"},
                            }}
                            style={{marginLeft: 0}}
                        >
                            {timeTracks.length}
                        </Button>
                    </Stack>
                )
            ) : (
                <Button
                    onClick={() => {
                        handleMatterSelectorOpen();
                    }}
                    disabled={!!showMatterSelector}
                    startIcon={
                        <CustomIcon
                            name="timer"
                            color={palette.yao.grey[2]}
                            sx={{position: "absolute", left: 8, top: 5, p: 0.1}}
                        />
                    }
                    variant="contained"
                    sx={{
                        textAlign: "left",
                        width: 110,
                        pl: 3.5,
                        ...(showMatterSelector && {
                            "&:before": {
                                zIndex: 1,
                                content: "''",
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                            },
                        }),
                    }}
                >
                    <span style={{marginTop: 3}}>0:00</span>
                </Button>
            )}

            <MenuPopover
                open={Boolean(showMatterSelector)}
                anchorEl={showMatterSelector}
                onClose={handleMatterSelectorClose}
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    width: 300,
                    "& .MuiMenuItem-root": {
                        typography: "body2",
                        borderRadius: 0.75,
                    },
                }}
            >
                <Box sx={{py: 1, px: 2, color: palette.yao.primary[3]}}>
                    <YaoFormFieldLabel label="Matter"/>
                    <Autocomplete
                        id="timer-matter"
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        isOptionEqualToValue={(o, v) => o._id === v._id}
                        filterOptions={MatterAutoCompleteFilterHelper}
                        options={(getMattersAPI?.data || []).map(extractMatterOption)}
                        loading={getMattersAPI.loading}
                        value={selectedMatter}
                        blurOnSelect={true}
                        onChange={(_, newValue: Matter) => {
                            setSelectedMatter(newValue);
                        }}
                        inputValue={inputValue}
                        onInputChange={(_: SyntheticEvent, value: string) => {
                            setInputValue(value);
                        }}
                        noOptionsText={
                            inputValue.length > 0 ? "Matter not found" : "Type anything to search"
                        }
                        disableClearable
                        forcePopupIcon={false}
                        clearOnEscape={true}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Choose matter"
                                fullWidth
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password1989",
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    endAdornment: (
                                        <>
                                            {getMattersAPI.loading ? (
                                                <CircularProgress color="inherit" size={20}/>
                                            ) : null}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        getOptionLabel={(o: Matter) => o.case_name}
                        renderOption={MatterAutoCompleteRenderOptionHelper}
                    />
                    <Stack
                        direction="row"
                        justifyContent="right"
                        spacing={1}
                        sx={{mt: 2.5}}
                    >
                        <Button
                            color="secondary"
                            onClick={() => setShowMatterSelector(null)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            disabled={!selectedMatter}
                            onClick={handleStartTracking}
                        >
                            Start
                        </Button>
                    </Stack>
                </Box>
            </MenuPopover>

            <MenuPopover
                open={Boolean(listOpen)}
                anchorEl={listOpen}
                onClose={handleListClose}
                disabledArrow
                sx={{
                    p: 0,
                    borderRadius: 1,
                    overflow: "hidden",
                    width: "max-content",
                    minWidth: CLOCK_MIN_WIDTH,
                }}
            >
                {timeTracks.length > 1 &&
                    timeTracks.map((t) => (
                        <Box sx={{pl: 0.5, background: "#259FA5"}}>
                            <Timer
                                key={t._id}
                                timeTrack={t}
                                onStop={() => handleTimerStop(t)}
                            />
                        </Box>
                    ))}

                <MenuItem
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "12px 12px 1fr",
                        gap: 1,
                        p: 1,
                    }}
                    onClick={(e) => {
                        handleListClose();
                        handleMatterSelectorOpen();
                    }}
                >
                    <CustomIcon name="timer" sx={{p: 0}} size={12}/>
                    <div/>
                    <Typography
                        variant="caption"
                        color={palette.yao.primary[1]}
                        noWrap
                        sx={{pl: 2, fontWeight: 700}}
                    >
                        Start new
                    </Typography>
                </MenuItem>
            </MenuPopover>
        </div>
    );
}
