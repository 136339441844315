import cookie from "js-cookie";
import jwtDecode from "jwt-decode";
import { PATH_AUTH } from "../routes/paths";
import axios from "./axios";

const isValidToken = (accessToken: string | null | undefined) => {
  if (!accessToken) {
    return false;
  }

  const { exp, iat } = jwtDecode<{ exp: number; iat: number }>(accessToken);

  const currentTime = Date.now() / 1000;

  return exp ? exp > currentTime : iat < currentTime;
};

const handleTokenExpired = (exp: number) => {
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  const timeLeft = currentTime + 900000 - currentTime; // ~10s
  // const timeLeft = exp * 100000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    // eslint-disable-next-line no-alert
    alert("Token expired");

    cookie.remove("accessToken");

    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

const setSession = (accessToken: string | null | undefined) => {
  if (accessToken) {
    cookie.set("accessToken", accessToken, { expires: 7 });
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode<{ exp: number; iat: number }>(accessToken);
    if (exp) handleTokenExpired(exp);
  } else {
    cookie.remove("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession };
