import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Popover,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack, SxProps, Theme } from "@mui/material";
import {
  ChangeEvent,
  FC,
  MouseEvent,
  useContext,
  useState,
  useEffect,
} from "react";
import { IconButtonAnimate } from "../animate";
import Iconify from "../Iconify";
import YaoSelectorContext from "./yao-selector.context";
import palette from "src_common/theme/palette";
import { CaseType } from "src_lawfirm/api/case-types";
import { useInView } from "react-intersection-observer";
import { YaoSelectorItem } from "./yao-selector.types";
import { useSnackbar } from "notistack";
import { TableNoData } from "../table";
import { normalizeText } from "src_lawfirm/pages/workflow/canvas-helper";
import CustomIcon from "../custom-icon";
import { FormFieldType } from "src_common/@interfaces/forms";
import { DatePicker } from "@mui/lab";

export const YaoSelectorGrid: FC<{ sx?: SxProps<Theme> }> = ({
  children,
  sx = {},
}) => {
  return (
    <Box
      sx={sx}
      display="grid"
      gridTemplateColumns="300px 1fr"
      gridTemplateRows="1fr"
      gap="0px 16px"
    >
      {children}
    </Box>
  );
};

export const YaoSelectorContainedScroll: FC<{ sx?: SxProps<Theme> }> = ({
  children,
  sx = {},
}) => {
  return (
    <Box
      sx={{
        ...sx,
        height: "100%",
        padding: 1,
        margin: 0,
        listStyle: "none",
        overflowX: "hidden",
        overflowY: "auto",
        scrollbarColor: "#0186B0 #EBEFF8",
        scrollbarWidth: "thin",
        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
          backgroundColor: "#EBEFF8",
          width: "10px",
        },
        "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
          backgroundColor: "#EBEFF8",
        },
        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
          borderRadius: 8,
          backgroundColor: "#0186B0",
          minHeight: 20,
          border: "3px solid #EBEFF8",
        },
        "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
          {
            backgroundColor: "#959595",
          },
        "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
          {
            backgroundColor: "#959595",
          },
        "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
          {
            backgroundColor: "#959595",
          },
        "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
          backgroundColor: "#2b2b2b",
        },
      }}
    >
      {children}
    </Box>
  );
};

export const YaoSelectorClose: FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <Box>
      <IconButtonAnimate
        size="small"
        disableRipple
        onClick={onClose}
        sx={{ p: 0 }}
      >
        <Iconify icon={"eva:close-fill"} fontSize={36} />
      </IconButtonAnimate>
    </Box>
  );
};

export const YaoSelectorHeader: FC = ({ children }) => {
  return (
    <Stack direction="row" spacing={1}>
      {children}
    </Stack>
  );
};

export const YaoSelectorTitle: FC = () => {
  const selectorCtx = useContext(YaoSelectorContext);
  return (
    <Box flexGrow={1} flexShrink={1}>
      <Typography
        fontWeight={700}
        fontSize="24px"
        lineHeight="36px"
        sx={{ color: "#0D394D" }}
      >
        {selectorCtx.title}
      </Typography>
    </Box>
  );
};

export const YaoSelectorFilterDepartment: FC = () => {
  const selectorCtx = useContext(YaoSelectorContext);

  if (
    !selectorCtx ||
    !selectorCtx.filtersEnabled.department ||
    (!selectorCtx.departmentsLoading && !selectorCtx.departments.length)
  ) {
    return null;
  }

  if (selectorCtx.departmentsLoading) {
    return (
      <Stack
        direction="column"
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress size={12} />
        <Typography>loading departments</Typography>
      </Stack>
    );
  }

  return (
    <>
      <Typography
        color="#0D394C"
        fontSize={14}
        fontWeight={500}
        lineHeight="22px"
        width="240px"
      >
        Department
      </Typography>
      <List>
        {selectorCtx.departments.map((d) => (
          <ListItemButton
            key={d._id}
            selected={selectorCtx.filter.department === d._id}
            onClick={() => {
              const { case_type, department } = selectorCtx.filter;
              let clearCaseType = true;
              if (case_type.length > 0) {
                const caseTypeItem = selectorCtx.caseTypes.find(
                  (c) => c._id === case_type && c.department?._id === d._id
                );
                if (caseTypeItem) {
                  clearCaseType = false;
                }
              }
              selectorCtx.setFilter((filter) => ({
                ...filter,
                department: department === d._id ? "" : d._id,
                case_type: clearCaseType ? "" : filter.case_type,
              }));
            }}
            disableRipple
            sx={{
              padding: "0 0 0 10px",
              color: "#646D76",
              "&:hover": {
                color: "#0D394C",
                backgroundColor: "transparent!important",
              },
              "&.Mui-selected": {
                color: "#0186B0",
                backgroundColor: "transparent",
              },
            }}
          >
            <ListItemText
              primary={d.title}
              primaryTypographyProps={{
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "28px",
              }}
            />
          </ListItemButton>
        ))}
      </List>
    </>
  );
};

export const YaoSelectorFilterCaseType: FC = () => {
  const selectorCtx = useContext(YaoSelectorContext);
  const [options, setOptions] = useState<CaseType[]>([]);

  useEffect(() => {
    setOptions(
      selectorCtx.caseTypes.filter((c) =>
        selectorCtx.filter.department.length > 0
          ? c.department?._id === selectorCtx.filter.department
          : true
      )
    );
  }, [selectorCtx.caseTypes, selectorCtx.filter.department]);

  if (
    !selectorCtx ||
    !selectorCtx.filtersEnabled.case_type ||
    !selectorCtx.caseTypes.length
  ) {
    return null;
  }

  return (
    <Box>
      <Select
        labelId="case-type-selector-label"
        id="case-type-selector"
        label=""
        placeholder={selectorCtx.filtersPlaceholder.case_type}
        value={selectorCtx.filter.case_type}
        onChange={(e: SelectChangeEvent) =>
          selectorCtx.setFilter((filter) => ({
            ...filter,
            case_type: e.target.value,
          }))
        }
        sx={{ p: 0, width: 200 }}
        renderValue={(selected) => {
          if (selected) {
            return selectorCtx.caseTypes.find((c) => c._id === selected)?.title;
          }
          return (
            <em style={{ color: "#95A1AE" }}>
              {selectorCtx.filtersPlaceholder.case_type}
            </em>
          );
        }}
        displayEmpty
        disabled={!options.length}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {options.map((c) => (
          <MenuItem key={c._id} value={c._id}>
            {c.title}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export const YaoSelectorFilterText: FC = () => {
  const selectorCtx = useContext(YaoSelectorContext);

  if (!selectorCtx || !selectorCtx.filtersEnabled.text) {
    return null;
  }

  return (
    <Box>
      <TextField
        id="filter-term"
        value={selectorCtx.filter.text}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          selectorCtx.setFilter((filter) => ({
            ...filter,
            text: e.target.value,
          }))
        }
        InputProps={{
          sx: { p: 0, width: 400 },
          endAdornment: (
            <InputAdornment position="end">
              <Iconify
                icon="eva:search-fill"
                color={palette.yao.primary[1]}
                height={22}
                fontSize={22}
                sx={{ mr: 2 }}
              />
            </InputAdornment>
          ),
        }}
        placeholder={selectorCtx.filtersPlaceholder.text}
      />
    </Box>
  );
};

export const YaoSelectorListContainer: FC<{
  onClose: (data: any) => void;
  onContinue: (selection: YaoSelectorItem[]) => Promise<boolean>;
}> = ({ children, onClose, onContinue }) => {
  const { enqueueSnackbar } = useSnackbar();
  const selectorCtx = useContext(YaoSelectorContext);

  const { ref: footerRef, inView } = useInView({
    threshold: 0,
    initialInView: false,
  });

  const handleConfirm = async () => {
    try {
      selectorCtx.setLoading(true);
      const { items, selection } = selectorCtx;
      const response = await onContinue(
        items.filter((i) => selection.indexOf(i.id) !== -1)
      );
      onClose(!!response ? response : true);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      selectorCtx.setLoading(false);
    }
  };

  const renderConfirm = (offView: boolean = false) => {
    if (selectorCtx.loading || !selectorCtx.items.length) {
      return null;
    }

    return (
      <Paper
        elevation={8}
        sx={{
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          justifyContent: "flex-end",
          alignItems: "center",
          display: "flex",
          p: 1,
          mt: 1,
        }}
        className={offView ? "yao-afix-footer" : ""}
      >
        <Typography
          fontSize={11}
          fontWeight={400}
          lineHeight="24px"
          color="#646D76"
        >
          Click to select certain item
        </Typography>
        <Box flexGrow={1} flexShrink={1} />
        <Button
          variant="text"
          color="secondary"
          onClick={() => onClose(undefined)}
        >
          cancel
        </Button>
        <Button
          variant="contained"
          disabled={!selectorCtx.selection.length}
          onClick={handleConfirm}
        >
          Continue
        </Button>
      </Paper>
    );
  };

  return (
    <>
      <Container maxWidth={false}>
        {children}
        <div ref={footerRef}>{renderConfirm()}</div>
      </Container>
      {!inView && renderConfirm(true)}
    </>
  );
};

const YaoSelectorCard: FC<{ item: YaoSelectorItem }> = ({ item }) => {
  const selectorCtx = useContext(YaoSelectorContext);
  const [caseTypes, setCaseTypes] = useState<
    { type: string; department: string }[]
  >([]);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!Array.isArray(item.case_types) || !item.case_types.length) {
      setCaseTypes([]);
      return;
    }
    const next = item.case_types
      .map((c) => {
        const ct = selectorCtx.caseTypes.find((t) => t._id === c);
        const dp = selectorCtx.departments.find(
          (d) => d._id === ct?.department?._id
        );
        return {
          type: ct?.title || "",
          department: dp?.title || "",
        };
      })
      .filter((i) => i.type.length > 0 || i.department.length > 0);
    setCaseTypes(next);
  }, [item, selectorCtx.caseTypes, selectorCtx.departments]);

  const renderSampleType = (type: FormFieldType) => {
    switch (type) {
      case FormFieldType.TEXT:
        return (
          <TextField
            fullWidth
            type="text"
            className="yao-input-override"
            placeholder="paragraph field sample"
          />
        );
      case FormFieldType.PARAGRAPH:
        return (
          <TextField
          fullWidth
          multiline
          minRows={3}
          maxRows={5}
          className="yao-input-override"
          placeholder="multine text field sample"
        />
        );
      case FormFieldType.NUMBER:
        return (
          <TextField
            fullWidth
            type="number"
            className="yao-input-override"
            placeholder="number field sample"
          />
        );
      case FormFieldType.YES_NO:
        return (
          <Select fullWidth sx={{ p: 0 }} value="empty">
            <MenuItem value="empty" disabled selected>
              Yes/No field sample
            </MenuItem>
            <MenuItem value="yes">Yes</MenuItem>
            <MenuItem value="no">No</MenuItem>
          </Select>
        );
      case FormFieldType.DATE:
        return (
          <DatePicker
            openTo="day"
            views={["day"]}
            value={null}
            onChange={() => {}}
            inputFormat="dd/MM/yyyy"
            disableMaskedInput
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                className="yao-input-override"
                placeholder="date field sample"
              />
            )}
          />
        );
    }
  };

  const fields = (item.fields || []).slice(0, 5);
  const list = (item.list || []).slice(0, 5);
  const open = Boolean(anchorEl);
  const id = open ? `popover-item-${item.id}` : undefined;

  return (
    <>
      <Box
        sx={{
          height: "auto",
          overflow: "hidden",
          borderRadius: "8px",
          padding: "10px",
          cursor: "pointer",
          borderWidth: "2px",
          borderStyle: "solid",
          borderColor:
            selectorCtx.selection.indexOf(item.id) !== -1
              ? "#0186B0"
              : "#D8E4E4",
        }}
        className="prent-box"
        onClick={() => {
          selectorCtx.toggleSelection(item.id);
        }}
      >
        <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
          {!item.icon ? null : (
            <Box
              sx={{
                width: "32px!important",
                height: "32px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                backgroundColor:
                  palette.dark.forms[item.icon_color_index || 0].background,
                flexBasis: "32px",
              }}
            >
              <CustomIcon
                name="form"
                size={12}
                sx={{
                  width: "32px!important",
                  color: palette.dark.forms[item.icon_color_index || 0].text,
                }}
              />
            </Box>
          )}
          <Typography
            fontSize={14}
            fontWeight={500}
            lineHeight="16px"
            sx={{ color: "#0D394D" }}

          >
            {item.title}
          </Typography>
        </Stack>
        <Typography
          fontSize={12}
          fontWeight={400}
          lineHeight="18px"
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 4,
            marginTop: "15px",
            height: "74px",
          }}
        >
          {item.description}
        </Typography>

        {fields.length > 0 ? (
          <ul style={{ listStyleType: "circle", marginLeft: "24px" }}>
            {fields.map((f, i) => (
              <li key={i}>
                <Stack direction="row" spacing={1}>
                  <Typography
                    fontSize={12}
                    fontWeight={400}
                    lineHeight="19px"
                    sx={{ color: "#0D394D" }}
                    noWrap
                    flexGrow={1}
                    flexShrink={1}
                  >
                    {f.name}
                  </Typography>
                  {fields.length - 1 === i ? (
                    <Box
                      sx={{
                        width: "20px!important",
                        height: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexBasis: "20px",
                      }}
                    >
                      <Iconify
                        color={palette.yao.primary[1]}
                        fontSize={20}
                        sx={{ cursor: "pointer", width: "20px!important" }}
                        icon="akar-icons:plus"
                        aria-describedby={id}
                        onClick={(event: MouseEvent<HTMLDivElement>) => {
                          event.stopPropagation();
                          setAnchorEl(event.currentTarget);
                        }}
                      />
                    </Box>
                  ) : null}
                </Stack>
              </li>
            ))}
          </ul>
        ) : null}

        {list.length > 0 ? (
          <ul style={{ listStyleType: "circle", marginLeft: "24px" }}>
            {list.map((l, i) => (
              <li key={i}>
                <Stack direction="row" spacing={1}>
                  <Typography
                    fontSize={12}
                    fontWeight={400}
                    lineHeight="19px"
                    sx={{ color: "#0D394D" }}
                    noWrap
                    flexGrow={1}
                    flexShrink={1}
                  >
                    {l}
                  </Typography>
                  {list.length - 1 === i ? (
                    <Box
                      sx={{
                        width: "20px!important",
                        height: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexBasis: "20px",
                      }}
                    >
                      <Iconify
                        color={palette.yao.primary[1]}
                        fontSize={20}
                        sx={{ cursor: "pointer", width: "20px!important" }}
                        icon="akar-icons:plus"
                        aria-describedby={id}
                        onClick={(event: MouseEvent<HTMLDivElement>) => {
                          event.stopPropagation();
                          setAnchorEl(event.currentTarget);
                        }}
                      />
                    </Box>
                  ) : null}
                </Stack>
              </li>
            ))}
          </ul>
        ) : null}

        {!caseTypes.length ? null : (
          <Typography
            fontSize={12}
            fontWeight={600}
            lineHeight="24px"
            textTransform="uppercase"
            color="#0D394D"
            sx={{
              marginTop: "12px",
              overflow: "hidden",
              lineClamp: 3,
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "3",
              WebkitBoxOrient: "vertical",
            }}
          >
            {caseTypes
              .map((c) =>
                [c.department, c.type].filter((i) => i.length > 0).join(" / ")
              )
              .join(", ")}
          </Typography>
        )}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            padding: 2,
            width: "500px",
          },
        }}
      >
        <IconButtonAnimate
          size="small"
          onClick={() => setAnchorEl(null)}
          sx={{ p: 0.5 }}
        >
          <Iconify icon={"eva:close-fill"} fontSize={22} />
        </IconButtonAnimate>
        <Typography
          fontSize={16}
          fontWeight={600}
          lineHeight="28px"
          sx={{ color: "#0D394D" }}
          textAlign="center"
        >
          {item.title}
        </Typography>
        <Typography
          fontSize={12}
          fontWeight={400}
          lineHeight="18px"
          textAlign="center"
        >
          {item.description}
        </Typography>

        {fields.length > 0 ? (
          <Grid container rowSpacing={1} columnSpacing={1} mt={1}>
            {(item.fields || []).map((f, idx) => (
              <Grid item xs={12} sx={{ p: 0 }} key={idx}>
                <Grid container sx={{ p: 0 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography fontWeight={600} fontSize={9}>
                      {f.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {renderSampleType(f.type)}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        ) : null}

        {list.length > 0 ? (
          <Grid container rowSpacing={1} columnSpacing={1} mt={1}>
            {(item.list || []).map((l, idx) => (
              <Grid item xs={12} sx={{ p: 0 }} key={idx}>
                <Typography fontWeight={600} fontSize={9}>
                  {l}
                </Typography>
              </Grid>
            ))}
          </Grid>
        ) : null}

{!caseTypes.length ? null : (
          <Typography
            fontSize={12}
            fontWeight={600}
            lineHeight="24px"
            textTransform="uppercase"
            color="#0D394D"
            sx={{
              marginTop: "12px",
            }}
          >
            {caseTypes
              .map((c) =>
                [c.department, c.type].filter((i) => i.length > 0).join(" / ")
              )
              .join(", ")}
          </Typography>
        )}
      </Popover>
    </>
  );
};

export const YaoSelectorList: FC = () => {
  const selectorCtx = useContext(YaoSelectorContext);

  const [list, setList] = useState<YaoSelectorItem[]>([]);

  useEffect(() => {
    const next = selectorCtx.items.filter((i) => {
      const termMatch = !selectorCtx.filter.text.length
        ? true
        : normalizeText(i.title).includes(
            normalizeText(selectorCtx.filter.text)
          );
      const departmentMatch = !selectorCtx.filter.department.length
        ? true
        : !Array.isArray(i.departments)
        ? false
        : i.departments.indexOf(selectorCtx.filter.department) !== -1;
      const caseTypeMatch = !selectorCtx.filter.case_type.length
        ? true
        : !Array.isArray(i.case_types)
        ? false
        : i.case_types.indexOf(selectorCtx.filter.case_type) !== -1;
      return termMatch && departmentMatch && caseTypeMatch;
    });
    setList(next);
  }, [
    selectorCtx.items,
    selectorCtx.filter.case_type,
    selectorCtx.filter.department,
    selectorCtx.filter.text,
  ]);

  if (!selectorCtx.items.length) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "calc(100vh - 130px)",
        }}
      >
        <TableNoData isNotFound={true} label="No items found" img={""} />
      </Box>
    );
  }

  return (
    <Box sx={{ minHeight: "calc(100vh - 150px)" }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
          gridTemplateRows: "auto",
          gap: "16px",
        }}
      >
        {list.map((item) => (
          <YaoSelectorCard key={item.id} item={item} />
        ))}
      </Box>
    </Box>
  );
};
