import { useCallback, useState, useEffect } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Contact, ContactType } from "src_lawfirm/api/contacts";
import ContactForm from "./ContactForm";
import { Dialog } from "@mui/material";

type ContactDialogProps = {
  contact?: Contact;
  isCompany?: boolean;
};

const ContactDialog = NiceModal.create<ContactDialogProps>(
  ({ contact, isCompany }) => {
    const modal = useModal();
    const [type, setType] = useState<"create" | "update">("create");

    useEffect(() => {
      if (contact !== null && contact !== undefined) {
        setType("update");
      }
    }, [contact]);

    const handleCloseForm = useCallback(() => {
      modal.hide();
    }, [modal]);

    const handleFinishForm = (created: Contact) => {
      modal.resolve(created);
      modal.hide();
    };

    return (
      <Dialog
        maxWidth="md"
        open={modal.visible}
        onClose={handleCloseForm}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <div style={{ padding: 16 }}>
          <ContactForm
            type={type}
            contact={contact}
            onCancel={handleCloseForm}
            onFinish={handleFinishForm}
            confirmText={type === "create" ? "Add" : "Save"}
            company={
              (type === "create" && isCompany) ||
              (type === "update" && contact?.type === ContactType.Company)
            }
          />
        </div>
      </Dialog>
    );
  }
);

export default ContactDialog;
