import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Dialog, IconButton } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import Iconify from "src_common/components/Iconify";
import { useAPI } from "src_common/hooks/useAPI";
import { getMatter } from "src_lawfirm/api/matters";
import { TimeEntry } from "src_lawfirm/api/time-entries";
import TimeEntryForm from "./TimeEntryForm";

type TimeEntryDialogProps = {
  matterId: string;
  timeEntry?: TimeEntry;
};

const TimeEntryDialog = NiceModal.create<TimeEntryDialogProps>(
  ({ timeEntry, matterId }) => {
    const modal = useModal();
    const [type, setType] = useState<"create" | "update">("create");
    const getMatterAPI = useAPI(getMatter);

    useEffect(() => {
      if (timeEntry !== null && timeEntry !== undefined) {
        setType("update");
      }
    }, [timeEntry]);

    useEffect(() => {
      getMatterAPI.invoke(matterId);
    }, []);

    const handleCloseForm = useCallback(() => {
      modal.hide();
    }, [modal]);

    const handleFinishForm = useCallback(
      (created: TimeEntry) => {
        modal.resolve(created);
        modal.hide();
      },
      [modal]
    );

    return (
      <Dialog
        maxWidth="md"
        open={modal.visible}
        onClose={handleCloseForm}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        {!getMatterAPI.loading && getMatterAPI?.data && (
          <>
            <IconButton
              onClick={handleCloseForm}
              style={{
                position: "absolute",
                margin: 8,
              }}
            >
              <Iconify
                icon="eva:close-fill"
                onClick={handleCloseForm}
                style={{
                  justifyContent: "flex-start",
                  alignSelf: "flex-start",
                }}
              />
            </IconButton>
            <div style={{ padding: 40 }}>
              <TimeEntryForm
                type={type}
                matter={getMatterAPI.data}
                timeEntry={timeEntry}
                onCancel={handleCloseForm}
                onFinish={handleFinishForm}
              />
            </div>
          </>
        )}
      </Dialog>
    );
  }
);

export default TimeEntryDialog;
