import { Link as RouterLink } from "react-router-dom";
import { Box, BoxProps } from "@mui/material";

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const logo = (
    <Box display="flex">
      <Box component="img" src="/assets/logo.svg" m={1} />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <RouterLink to="/" style={{ textDecoration: "none" }}>
      {logo}
    </RouterLink>
  );
}
