import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Container, Stack } from "@mui/material";
import useResponsive from "../hooks/useResponsive";
import Page from "../components/Page";
// @ts-ignore
import authBg from "../../public/assets/new_mask_group.svg";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: 730,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#0D394D",
  backgroundImage: `url(${authBg})`,
  color: "white",
  fontFamily: "Manrope",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  width: "100%",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

type AuthLayoutProps = {
  label: string;
  logo?: string;
  suffix?: string;
};

export default function AuthLayout({
  label,
  logo = "",
  suffix = " | YAO",
}: AuthLayoutProps) {
  const mdUp = useResponsive("up", "md");

  return (
    <Page title="Login" suffix={suffix}>
      <RootStyle>
        {mdUp && (
          <SectionStyle>
            {logo?.length > 0 ? (
              <Stack justifyContent="center" alignItems="center">
                <Box
                  component="img"
                  src={logo}
                  m={1}
                  sx={{ maxWidth: "30%" }}
                />
              </Stack>
            ) : (
              <Box display="flex">
                <Box component="img" src="/assets/new_logo.svg" m={1} />
                <Box component="img" src="/assets/new_name_logo.svg" m={1} />
              </Box>
            )}
          </SectionStyle>
        )}
        <Container maxWidth="md">
          <ContentStyle>
            <Outlet />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
