import { formatError } from "src_common/utils/misc";
import axios from "src_common/utils/axios";
import { AxiosResponse } from "axios";

export enum InvoiceStatus {
  DRAFT = "DRAFT",
  ISSUED = "ISSUED",
  PAID = "PAID",
  CANCELED = "CANCELED",
}

export const getInvoiceStatus: Record<
  InvoiceStatus,
  { name: string; color: string }
> = {
  [InvoiceStatus.DRAFT]: { name: "Draft", color: "#FFC007" },
  [InvoiceStatus.ISSUED]: { name: "Issued", color: "#0086B0" },
  [InvoiceStatus.PAID]: { name: "Paid", color: "#53D62B" },
  [InvoiceStatus.CANCELED]: { name: "Cancelled", color: "#FF635F" },
};

export type InvoiceQuery = {
  matter?: string | null;
  bank_account?: string | null;
  status?: string | null;
  start?: string | null;
  end?: string | null;
};

export type InvoiceSearchParams = {
  page: number;
  size: number;
  matter: string | null;
  bank_account: string | null;
  status: string | null;
  start: string | null;
  end: string | null;
};

export type Invoice = {
  _id: string;
  total: number;
  disbursements: ["string"];
  total_disbursements: number;
  vat: number;
  vat_percentage: number;
  less_paid_on_account: number;
  billing_amount: number;
  billable_entries: number[];
  billable: number;
  outstanding: number;
  paid: number;
  invoice_number: number;
  status: string;
  client: {
    _id: string;
    first_name: string;
    last_name: string;
    display_name: string;
  };
  law_firm: any;
  author: any;
  bank_account: any;
  reference: string;
  url: string;
  matter: {
    _id: string;
    number: number;
    case_name: string;
  };
  due_date: Date;
  created_at: Date;
  updated_at: Date;
  payable_by?: string;
  other_fees?: InvoiceDisbursement[];
  debit_account?: string;
  receiving_account?: {
    _id: string;
    name: string;
  };
  account_code?: string;
  firm_fees?: {
    description: string;
    value: number;
    subtotal: number;
    vat: number;
  }[];
  integration_id?: string;
  invoice_date?: Date;
  solicitor?: { _id: string; name: string };
  subtotal?: number;
  time_entries?: {
    assignee: string;
    date: string;
    description: string;
    unit_value: number;
    units: number;
    value: number;
    work_type: string;
    write_off: number;
  }[];
  time_entries_url?: string;
  total_firm_fees?: number;
  total_other_fees?: number;
  write_off?: number;
};

export type InvoiceDisbursement = {
  description: string;
  value: number;
};

export type InvoiceCreateModel = {
  draft: boolean;
  disbursements: { _id: string; apply_vat: boolean }[];
  billing_amount: number;
  vat_percentage: number;
  less_paid_on_account: number;
  matter: string;
  debit_account: string | null;
  receiving_account: string | null;
  solicitor: string | null;
  client: string | null;
  time_entries: string[];
  due_date: Date;
  invoice_date?: Date;
  narrative?: string;
  payable_by: string;
  other_fees: InvoiceDisbursement[];
  firm_fees: InvoiceDisbursement[];
};

export async function invoiceSearch(
  params: InvoiceSearchParams
): Promise<Invoice[]> {
  try {
    const response: AxiosResponse<Invoice[]> = await axios.post(
      "/invoices/search",
      params
    );
    return response.data || [];
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function invoiceCreate(
  params: InvoiceCreateModel
): Promise<Invoice> {
  try {
    const response: AxiosResponse<Invoice> = await axios.post(
      "/invoices",
      params
    );
    return response.data as Invoice;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function invoiceCancel(id: string): Promise<boolean> {
  try {
    const response: AxiosResponse<{ success: boolean }> = await axios.patch(
      `/invoices/${id}/cancel`,
      {}
    );
    return response.data?.success || false;
  } catch (e) {
    throw new Error(formatError(e));
  }
}
