import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
} from "react";
import cookie from "js-cookie";
import axios from "src_common/utils/axios";
import { isValidToken, setSession } from "src_common/utils/jwt";
import { AuthState, JWTContextType } from "src_common/@types/auth";
import _ from "lodash";

enum Types {
  Initial = "INITIALIZE",
  Login = "LOGIN",
  Logout = "LOGOUT",
}

const AUTH_ROOT =
  process.env.REACT_APP_NAME === "backoffice"
    ? "/yaoers"
    : process.env.REACT_APP_NAME === "lawfirm"
    ? "/attorneys"
    : process.env.REACT_APP_NAME === "client"
    ? "/contacts"
    : "";

export type JWTActions = {
  type: Types.Initial | Types.Login | Types.Logout;
  payload: {
    isAuthenticated?: boolean;
    user?: any;
  };
};

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state: AuthState, action: JWTActions) => {
  const newState = _.cloneDeep(state);
  const payload = action.payload;

  switch (action.type) {
    case Types.Initial:
      newState.isAuthenticated = payload.isAuthenticated || false;
      newState.isInitialized = true;
      newState.user = payload.user;
      break;

    case Types.Login:
      newState.isAuthenticated = true;
      newState.user = payload.user;
      break;

    case Types.Logout:
      newState.isAuthenticated = false;
      break;

    default:
      break;
  }

  return newState;
};

const AuthContext = createContext<JWTContextType | null>(null);

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = async () => {
    let user = null;
    let isAuthenticated = false;

    const accessToken = cookie.get("accessToken");
    const userCached = cookie.get("user") as string;

    if (isValidToken(accessToken)) {
      setSession(accessToken);

      try {
        user = JSON.parse(userCached);
        isAuthenticated = true;
      } catch (e) {
        console.log(e);
      }
    }

    dispatch({ type: Types.Initial, payload: { isAuthenticated, user } });
  };

  // lawfirm attorney login
  const login = useCallback(
    async (email: string, password: string, code?: number) => {
      const response = await axios.post(`${AUTH_ROOT}/login`, {
        email,
        password,
        code,
      });
      const user = {
        ...response.data,
      };
      const { token } = response.data;
      setSession(token);
      cookie.set("user", JSON.stringify(user));

      dispatch({
        type: Types.Login,
        payload: {
          user,
        },
      });
    },
    []
  );

  const clientRequestCode = useCallback(async (phone: string) => {
    await axios.patch(`${AUTH_ROOT}/login`, {
      phone,
    });
  }, []);

  const clientLogin = useCallback(async (phone: string, code: string) => {
    const response = await axios.post(`${AUTH_ROOT}/login`, {
      phone,
      code,
    });
    const user = {
      ...response.data,
      name: response.data.display_name,
    };
    const { token } = response.data;
    setSession(token);
    cookie.set("user", JSON.stringify(user));

    dispatch({
      type: Types.Login,
      payload: {
        user,
      },
    });
  }, []);

  const logout = async () => {
    setSession(null);
    cookie.remove("accessToken");
    cookie.remove("user");
    localStorage.removeItem('logo_url')
    dispatch({ type: Types.Logout, payload: {} });
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        clientRequestCode,
        clientLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
