import { AxiosResponse } from "axios";
import axios from "src_common/utils/axios";
import { base64ToBlob, formatError } from "src_common/utils/misc";
import { Attorney } from "./attorneys";
import { Contact } from "./contacts";
import { Matter } from "./matters";
import { saveAs } from "file-saver";
import { CorrespondenceSignStatusSelect } from "src_lawfirm/pages/matters/CorrespondenceFolderTabV2/CorrespondenceFolderContext";

export const CORRESPONDENCE_EVENT_KEY = "refetch_correspondences";

export async function createCorrespondence(
  form: CorrespondenceForm
): Promise<Correspondence> {
  try {
    const formData = new FormData();
    formData.append("_id", form._id || "");
    formData.append("title", form.title || "");
    formData.append("category", form?.category || "");
    formData.append("key", form.key || "");
    formData.append("matter", form?.matter || "");
    if (form.parent_folder)
      formData.append("parent_folder", form.parent_folder);
    if (form.case_contact)
      formData.append("case_contact", form.case_contact || "");
    formData.append("other_contact", form.other_contact || "");
    if (form.correspondence_date)
      formData.append(
        "correspondence_date",
        form.correspondence_date.toISOString()
      );
    formData.append("correspondence_time", form.correspondence_time || "");
    formData.append("note", form.note || "");
    formData.append("email_body", form.email_body || "");
    formData.append("email_html_body", form.email_html_body || "");

    if (form.to) form.to.map((a) => formData.append("to[]", a));
    if (form.from) form.from.map((a) => formData.append("from[]", a));

    if (form.attachments)
      form.attachments.map((a) => formData.append("attachments", a));

    const res = await axios.post("/correspondence", formData);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function replyCorrespondence(
  form: CorrespondenceForm
): Promise<Correspondence> {
  try {
    const formData = new FormData();
    formData.append("_id", form._id || "");
    formData.append("title", form.title || "");
    formData.append("category", form?.category || "");
    formData.append("key", form.key || "");
    formData.append("matter", form?.matter || "");
    if (form.parent_folder)
      formData.append("parent_folder", form.parent_folder);
    if (form.case_contact)
      formData.append("case_contact", form.case_contact || "");
    formData.append("other_contact", form.other_contact || "");
    if (form.correspondence_date)
      formData.append(
        "correspondence_date",
        form.correspondence_date.toISOString()
      );
    formData.append("correspondence_time", form.correspondence_time || "");
    formData.append("note", form.note || "");
    formData.append("email_body", form.email_body || "");
    formData.append("email_html_body", form.email_html_body || "");

    if (form.to) form.to.map((a) => formData.append("to[]", a));
    if (form.from) form.from.map((a) => formData.append("from[]", a));

    if (form.attachments)
      form.attachments.map((a) => {
        return formData.append("attachments[]", JSON.stringify(a));
      });

    if (form.cc_emails)
      form.cc_emails.map((a) => formData.append("cc_emails[]", a));
    if (form.bcc_emails)
      form.bcc_emails.map((a) => formData.append("bcc_emails[]", a));

    if (form.task_id) {
      formData.append("task_id", form.task_id);
    }

    const res = await axios.post("/correspondence/reply", formData);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function getCorrespondenceFileDetail(
  id: string
): Promise<Correspondence> {
  const res: AxiosResponse<Correspondence> = await axios.get(
    `/correspondence/${id}`
  );
  return res.data as Correspondence;
}

export async function updateCorrespondence(
  form: CorrespondenceForm
): Promise<Correspondence> {
  try {
    const res = await axios.patch(`/correspondence/${form._id}`, form);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function duplicateCorrespondence(
  form: CorrespondenceForm
): Promise<Correspondence> {
  try {
    const res = await axios.post(`/correspondence/${form._id}/duplicate`, {
      title: form.title,
    });
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export enum CorrespondenceCategory {
  CALL = "CALL",
  DOCUMENT = "DOCUMENT",
  EMAIL = "EMAIL",
  NOTE = "NOTE",
  FOLDER = "FOLDER",
}

export async function createCorrespondenceFolder(
  form: CorrespondenceFolderForm
): Promise<CorrespondenceFolder> {
  try {
    const res = await axios.post("/correspondence", {
      ...form,
      category: CorrespondenceCategory.FOLDER,
    });
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function updateCorrespondenceFolder(
  form: CorrespondenceFolderForm
): Promise<CorrespondenceFolder> {
  try {
    const res = await axios.patch(`/correspondence/folders/${form._id}`, form);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function moveCorrespondenceFolder({
  _id,
  parent_folder,
}: Partial<CorrespondenceFolderForm>): Promise<CorrespondenceFolder> {
  try {
    const res = await axios.patch(`/correspondence/${_id}/move`, {
      parent_folder,
    });
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function getCorrespondenceDetails(
  id: string
): Promise<Correspondence> {
  try {
    const res = await axios.get(`/correspondence/${id}/details`);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function searchCorrespondences(params: {
  search?: string;
  category?: string[];
  author?: string;
  matter_id?: string;
  parent_folder?: string | null;
  sort?: object;
  page?: number;
  limit?: number;
  sign_status?: CorrespondenceSignStatusSelect;
}) {
  const res = await axios.post<Correspondence[]>(`correspondence/search`, {
    search: params.search,
    category: params.category,
    author: params.author,
    matter: params.matter_id,
    parent_folder: params.parent_folder,
    sort: params.sort,
    page: params.page || 1,
    limit: params.limit || undefined,
    sign_status: params.sign_status || undefined,
  });
  return res.data;
}

export async function getCorrespondenceFilesAndFolders(matter_id: string) {
  const { data } = await axios.post<Correspondence[]>(`correspondence/search`, {
    params: {
      matter: matter_id,
    },
  });

  return data;
}

export async function getCorrespondenceNotes({
  matter,
  only_unhidden = false,
}: {
  matter: string;
  only_unhidden: boolean;
}) {
  const { data } = await axios.post<Correspondence[]>(`correspondence/search`, {
    matter,
    category: [CorrespondenceCategory.NOTE],
    only_unhidden,
  });

  return data;
}

export async function hideCorrespondenceNote(noteId: string): Promise<boolean> {
  const response = await axios.patch(`correspondence/${noteId}/hide`);
  return response?.data?.success || false;
}

export async function processPrecedentDocument(
  template_id: string,
  matter_id: string
) {
  const { data } = await axios.post(`correspondence/precedents`, {
    template_id,
    matter_id,
  });

  return data;
}

export async function correspondenceDownload(
  ids: string[],
  parent_folder: string | undefined = undefined
): Promise<void> {
  try {
    const response = await axios.patch(
      "correspondence/download",
      {
        ids,
        parent_folder,
      },
      {
        responseType: "text",
        timeout: Number.MAX_SAFE_INTEGER,
      }
    );
    const blob = base64ToBlob(response.data, "application/zip");
    saveAs(blob, "correspondence.zip");
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function getCorrespondenceFolders(
  matter: string
): Promise<CorrespondenceFolderGraph[]> {
  try {
    const response: AxiosResponse<CorrespondenceFolderGraph[]> =
      await axios.get(`correspondence/${matter}/folders`);
    return response?.data || [];
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function publishCorrespondence(
  payload: CorrespondenceListDto
): Promise<boolean> {
  const response = await axios.patch<{ success: boolean }>(
    `correspondence/publish`,
    payload
  );
  return response?.data?.success;
}

export async function convertCorrespondenceToPdf(
  id: string
): Promise<Correspondence> {
  try {
    const res = await axios.patch(
      `/correspondence/${id}/convert/pdf`,
      {},
      {
        timeout: 60 * 60 * 1000,
      }
    );
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function unpublishCorrespondence(
  payload: CorrespondenceListDto
): Promise<boolean> {
  const response = await axios.patch<{ success: boolean }>(
    `correspondence/unpublish`,
    payload
  );
  return response?.data?.success;
}

export async function getCorrespondenceToSignCount() {
  try {
    const res: AxiosResponse<{ count: number }> = await axios.get(
      `/correspondence/contact/signatures/count`
    );
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export interface CorrespondenceListDto {
  ids: string[];
}

export type CorrespondenceMails = {
  name?: string;
  address?: string;
};

export type CorrespondenceMailAttachments = {
  filename: string;
  key: string;
};

export enum CorrespondenceSignStatus {
  REQUESTED = "REQUESTED",
  SIGNED = "SIGNED",
}

export interface CorrespondenceSign {
  contact: string;
  token?: string;
  status: CorrespondenceSignStatus;
  fingerprint?: string;
  sign_date?: Date;
}

export type Correspondence = {
  title?: string;
  original_title?: string;
  sign_requested_date?: Date;
  category: CorrespondenceCategory;
  children_count?: number;
  matter: Matter;
  law_firm: string;
  author: Attorney;
  parent_folder?: string | Correspondence;
  key?: string;
  case_contact?: Contact | null;
  other_contact?: string | null;
  correspondence_date?: Date | null;
  correspondence_time?: string;
  note?: string;
  email_body?: string;
  email_html_body?: string;
  email_path?: string;
  to_emails?: CorrespondenceMails[];
  from_emails?: CorrespondenceMails[];
  attachments?: CorrespondenceMailAttachments[];
  cc_emails: string[];
  bcc_emails: string[];
  _id: string;
  created_at: Date;
  updated_at: Date;
  __v: number;
  attachments_with_problem?: string[];
  units?: number;
  wopi_token?: string;
  published?: boolean;
  sign_status?: CorrespondenceSignStatus;
  sign_contacts?: CorrespondenceSign[];
};

export type CorrespondenceForm = {
  _id?: string;
  title?: string;
  category?: CorrespondenceCategory;
  key?: string;
  matter?: string;
  parent_folder?: string;
  case_contact?: string;
  other_contact?: string;
  correspondence_date?: Date;
  correspondence_time?: string;
  note?: string;
  file?: File;
  attachments?: CorrespondenceMailAttachments[];
  email_body?: string;
  email_html_body?: string;
  to_emails?: CorrespondenceMails[];
  from_emails?: CorrespondenceMails[];
  cc_emails?: string[];
  bcc_emails?: string[];
  to?: string[];
  from?: string[];
  task_id?: string;
};

export type CorrespondenceFilter = {
  category: string[];
  author?: string;
  search?: string;
  sort?: object;
  parent_folder?: string | null;
};

export type CorrespondenceFolder = {
  title: string;
  matter: string;
  law_firm: string;
  author: string;
  parent_folder?: string;
  _id: string;
  created_at: Date;
  updated_at: Date;
  __v: number;
};

export type CorrespondenceFolderForm = {
  _id?: string;
  title: string;
  matter: string;
  parent_folder?: string | null;
};

export interface CorrespondenceFilesAndFoldersResponse {
  folders: CorrespondenceFolder[];
  files: Correspondence[];
}

export interface CorrespondenceFolderGraph {
  _id: string;
  title: string;
  parent_folder: string;
  children_count: number;
  level?: number;
  children: readonly CorrespondenceFolderGraph[];
}

export interface AwsPreSignedResponse {
  url: string;
  fields: {
    Key: string;
    bucket: string;
    "X-Amz-Algorithm": string;
    "X-Amz-Credential": string;
    "X-Amz-Date": string;
    "X-Amz-Security-Token": string;
    Policy: string;
    "X-Amz-Signature": string;
  };
}
