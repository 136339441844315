import { formatError } from "src_common/utils/misc";
import { AxiosResponse } from "axios";
import axios from "src_common/utils/axios";
import { YAOFieldAutocompleteOption } from "src_common/components/yao-form-fields/YAOFieldAutocomplete";
import { Office } from "./offices";

export enum AttorneyStatus {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  DISABLED = "DISABLED",
}

export type LinkAccountsDto = {
  attorney_id: string;
  integration_account_id: string;
  integration_account_code: string;
};

export const AttorneyStatusDetails = {
  ACTIVE: { name: "Active", color: "success" },
  PENDING: { name: "Pending", color: "primary" },
  DISABLED: { name: "Disabled", color: "secondary" },
};

export enum AttorneyPermission {
  ADMIN = "ADMIN",
  ACCOUNTANT = "ACCOUNTANT",
  USER = "USER",
  WORKFLOW_MANAGER = "WORKFLOW_MANAGER",
}

export async function createAttorney(
  form: AttorneyFormData
): Promise<Attorney> {
  try {
    const res = await axios.post("attorneys/", form);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function updateAttorney(
  id: string,
  form: AttorneyFormData
): Promise<Attorney> {
  try {
    const res = await axios.put(`attorneys/${id}`, form);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function forgotPassword(
  form: ForgotAttorneyPasswordForm
): Promise<void> {
  try {
    await axios.post("/attorneys/forgot-password", form);
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function resetAttorneyPassword(
  form: ResetAttorneyPasswordForm
): Promise<Attorney> {
  try {
    const res = await axios.post("attorneys/reset-password", form);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function getAttorney(id: string) {
  const res: AxiosResponse<Attorney> = await axios.get(`attorneys/${id}`);
  return res.data;
}

export async function getAttorneys(
  params: AttorneyFilter
): Promise<Attorney[]> {
  const res = await axios.get("attorneys/", { params });
  return res.data;
}

export async function patchIntegrationAccounts(
  body: LinkAccountsDto[]
): Promise<Attorney> {
  return await axios.patch("attorneys/integration", body);
}

export type ForgotAttorneyPasswordForm = {
  email: string;
};

export type ResetAttorneyPasswordForm = {
  password: string;
  token: string;
};

export type AttorneyFormData = {
  permissions: YAOFieldAutocompleteOption;
  name: string;
  surname: string;
  email: string;
  password: string;
  phone: string;
  status: YAOFieldAutocompleteOption;
  position: string;
  law_firm: string;
  rate: number;
  office?: YAOFieldAutocompleteOption;
  email_default_signature?: string;
};

export type Attorney = {
  _id: string;
  name: string;
  surname: string;
  email: string;
  status: AttorneyStatus;
  phone: string;
  position: string;
  law_firm: string;
  rate: number;
  permissions: AttorneyPermission[];
  createdAt: string;
  integration_account_id?: string;
  integration_account_code?: string;
  email_default_signature?: string;
  office?: Pick<Office, "_id" | "name">;
};

export type AttorneyFilter = {
  status?: string[];
  search?: string;
};
