import { Box } from "@mui/material";
import { IconButtonAnimate } from "src_common/components/animate";
import CustomIcon from "src_common/components/custom-icon";

type Props = {
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
};

export default function CollapseButton({
  onToggleCollapse,
  collapseClick,
}: Props) {
  return (
    <IconButtonAnimate onClick={onToggleCollapse}>
      <Box
        sx={{
          lineHeight: 0,
          transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shorter,
            }),
          ...(collapseClick && {
            transform: "rotate(180deg)",
          }),
        }}
      >
        <CustomIcon name="collapse" size={20} />
      </Box>
    </IconButtonAnimate>
  );
}
