import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
  Button,
  Dialog,
  Stack,
  Typography,
} from "@mui/material";
import { ReactNode, useCallback } from "react";

const ConfirmDialog = NiceModal.create<{
  title: string;
  description: string;
  children?: ReactNode;
  withInputText?: {
    placeholder: string;
  };
}>(({ title, description, children, withInputText }) => {
  const modal = useModal();

  const handleCloseForm = useCallback(() => {
    modal.hide();
  }, [modal]);

  const handleFinishForm = useCallback(() => {
    modal.resolve(true);
    modal.hide();
  }, [modal]);

  return (
    <Dialog
      maxWidth="md"
      open={modal.visible}
      style={{ textAlign: "center" }}
      onClose={handleCloseForm}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <div style={{ padding: 60 }}>
        <Typography variant="h2" paragraph>
          {title}
        </Typography>
        <Typography variant="body2" paragraph>
          {description}
        </Typography>
        <Stack
          direction="row"
          sx={{
            mt: 4,
            justifyContent: "center",
          }}
          spacing={2}
        >
          <Button color="secondary" onClick={handleCloseForm}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ minWidth: 100 }}
            onClick={handleFinishForm}
          >
            Yes
          </Button>
        </Stack>
      </div>
    </Dialog>
  );
});

export default ConfirmDialog;
