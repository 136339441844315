import axios from "src_common/utils/axios";

export enum LawFirmSize {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

export enum LawFirmStatus {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  DISABLED = "DISABLED",
}

export interface ILinkDisbursementAccountDTO {
  disbursement_account_id: string;
  disbursement_account_code: string;
}

export interface IIntegrationAccountDTO {
  account_id: string;
  account_code: string;
  type: "DISBURSEMENT" | "FEES"
}

export type LawFirm = {
  _id: string;
  code: number;
  name: string;
  regulation_number: string;
  address: {
    street: string;
    number: string;
    zip_code: string;
    city: string;
    state?: string;
    district?: string;
  };
  size: LawFirmSize;
  practice_areas: string[];
  matter_number?: number;
  invoice_number?: number;
  status: LawFirmStatus;
  disbursement_account_id?: string;
  disbursement_account_code?: string;
  email?: string;
  fax?: string;
  phone?: string;
  fees_account_id?: string;
  fees_account_code?: string;
  owner: {
    name: string;
    email: string;
  };
  country: {
    _id: string;
    name: string;
    country_code: string;
    tax_name: string;
    currency_symbol: string;
    currency_code: string;
    tax_percentage: number;
  }
  subdomain?: string
};

export async function getLawFirm(): Promise<LawFirm> {
  const res = await axios.get("/law-firms");
  return res.data;
}

export async function linkDisbursementAccount(
  linkDisbursementAccountDTO: ILinkDisbursementAccountDTO
): Promise<{ success: boolean }> {
  const res = await axios.patch(
    "/law-firms/disbursement-account/",
    linkDisbursementAccountDTO
  );
  return res.data;
}

export async function saveIntegrationAccount(
  payload: IIntegrationAccountDTO
): Promise<{ success: boolean }> {
  const res = await axios.patch(
      "/law-firms/integration-account/",
      payload
  );
  return res.data;
}