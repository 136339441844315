import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  DialogActions,
  IconButton,
  Modal,
  Stack,
} from "@mui/material";
import { useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { FormProvider, RHFTextField } from "src_common/components/hook-form";
import Label from "src_common/components/Label";
import { CorrespondenceForm } from "src_lawfirm/api/correspondence";

export enum Types {
  FILE = "File",
  FOLDER = "Folder",
}

export const FileForm = ({
  visible,
  methods,
  handleClose,
  onSubmit,
  title,
  buttonTitle,
  type = Types.FILE,
}: {
  visible: boolean;
  handleClose: () => void;
  onSubmit: (form: Partial<CorrespondenceForm>) => Promise<void>;
  methods: UseFormReturn<Partial<CorrespondenceForm>, any>;
  title: string;
  buttonTitle: string;
  type?: Types;
}) => {

  return (
    <Modal
      open={visible}
      onClose={handleClose}
      style={{
        fontSize: "24px",
        textAlign: "left",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box width="40%" sx={{ backgroundColor: "#fff" }}>
        <FormProvider
          methods={methods}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <IconButton
            onClick={handleClose}
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <CloseIcon />
          </IconButton>

          <Stack spacing={0} style={{ padding: "0px" }}>
            <Label
              sx={{
                fontSize: "24px",
                backgroundColor: "#FFF",
                textAlign: "left",
              }}
            >
              {title}
            </Label>
          </Stack>
          <Stack spacing={4} style={{ padding: "20px" }}>
            <RHFTextField label={`${type} name`} name="title" />
          </Stack>

          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={methods.formState.isSubmitting}
            >
              {buttonTitle}
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Box>
    </Modal>
  );
};
