import { Chip } from "@mui/material";
import palette from "src_common/theme/palette";
import { CellProps } from "../types";

const priorityColor: { [key: string]: string } = {
  LOW: palette.yao.secondary[1],
  URGENT: palette.yao.secondary[2],
  "CRITICAL_DATE": palette.yao.secondary[4],
  STANDARD: palette.yao.secondary[6],
};

export default function PriorityCell({ data, formatter }: CellProps) {
  const value = (formatter ? formatter(data) : data) as string;
  const color = priorityColor[value] || palette.yao.primary[4];

  return (
    <Chip
      label={value.replace('_', ' ')}
      variant="outlined"
      size="small"
      sx={{
        px: 0.5,
        borderColor: color,
        color,
      }}
    />
  );
}