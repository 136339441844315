import { useContext, useEffect } from 'react';
import { CorrespondenceContext } from 'src_common/contexts/CorrespondenceContext';
import { CorrespondenceFilter } from 'src_lawfirm/api/correspondence';

export const defaultFilters: CorrespondenceFilter = {
  category: [],
};

export const useCorrespondenceContext = (matter_id = '') => {
  const { filters, setFilters, callCorrespondencesApi, correspondences } =
    useContext(CorrespondenceContext);

  const fetchCorrespondences = (newFilters = filters, cb?: () => void) => {
    if (matter_id) {
      callCorrespondencesApi(matter_id, newFilters, cb);
      if (cb) cb();
    }
  };

  useEffect(fetchCorrespondences, [matter_id]);

  return {
    correspondences,
    filters,
    setFilters,
    resetFilters: () => setFilters(defaultFilters),
    fetchCorrespondences,
  };
};
