import { formatError } from "src_common/utils/misc";
import axios from "src_common/utils/axios";
import { AxiosError, AxiosResponse, CancelToken } from "axios";
import axiosLib from "axios";
import { addMonths, isBefore, isDate, isValid } from "date-fns";
import { MongooseUpdateResponse } from "./workflow";
import { Attorney } from "./attorneys";

export enum SalutationTypes {
  "Mr" = "Mr",
  "Mrs" = "Mrs",
  "Miss" = "Miss",
  "Ms" = "Ms",
  "Dr" = "Dr",
}

export type ConflictQueryDTO = {
  page?: number;
  contact?: string;
  limit: number;
};

export enum ContactType {
  Person = "Person",
  Company = "Company",
}

export type SearchContacts = {
  search: string;
  limit: number;
  last_id: string;
  is_archived: boolean;
  type: ContactType | undefined;
  tag?: string;
  company?: string;
  ids_filter?: string[];
};

export async function createContact(form: ContactFormData): Promise<Contact> {
  return new Promise<Contact>((resolve, reject) => {
    const ax = axiosLib.create({
      baseURL: axios.defaults.baseURL,
      headers: {
        ...axios.defaults.headers.common,
      },
    });
    ax.post("/contacts", form).then(
      (res: AxiosResponse<Contact>) => resolve(res.data),
      (err: AxiosError<{ data: Contact[] }>) => {
        if (err?.response?.status === 409) {
          reject({
            type: "conflict",
            data: err.response?.data?.data || [],
          });
        }
        reject({
          type: "default",
          data: err.response?.data,
        });
      }
    );
  });
}

export async function editContact(
  id: string,
  form: ContactFormData
): Promise<Contact> {
  try {
    const res = await axios.patch(`/contacts/${id}`, form);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function archiveContact(id: string): Promise<boolean> {
  try {
    const res: AxiosResponse<Contact> = await axios.patch(
      `/contacts/${id}/archive`,
      {}
    );
    return res.data?.is_archived || false;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function getContacts(
  queryModel: SearchContacts,
  cancelToken: CancelToken | undefined = undefined
): Promise<Contact[]> {
  const params = {
    search: queryModel?.search || "",
    limit: queryModel?.limit || 50,
    last_id: queryModel?.last_id || "",
    is_archived: queryModel?.is_archived || false,
    type: queryModel?.type || undefined,
    tag: queryModel?.tag || undefined,
    company: queryModel?.company || undefined,
    ids_filter: queryModel?.ids_filter || undefined,
  };
  const res: AxiosResponse<ContactsApiResponse> = await axios.get("/contacts", {
    params,
    cancelToken,
  });
  return res.data?.rows || [];
}

export async function getContact(id: string): Promise<Contact> {
  const res: AxiosResponse<Contact> = await axios.get(`/contacts/${id}`);
  return res.data as Contact;
}

export async function getAllContactWithConflicts() {
  try {
    const res = await axios.get<ContactsWithConflictApiResponse>("/contacts/conflict-contact");
    return res.data;
  } catch (error) {
    throw new Error(formatError(error));
  }
}

export async function getConflicts(params: ConflictQueryDTO) {
  try {
    const res = await axios.get<ConflictApiResponse>("/contacts/conflicts", { params });
    return res.data;
  } catch (error) {
    throw new Error(formatError(error));
  }
}

export async function searchCompany(text: string): Promise<UkApiResponse[]> {
  const res: AxiosResponse<{
    items: UkApiResponse[];
    items_per_page: number;
    kind: string;
    page_number: number;
    start_index: number;
    total_results: number;
  }> = await axios.get("/contacts/search/company", {
    params: { q: text },
  });
  return res.data?.items || [];
}

export async function sendSmsCode(id: string): Promise<boolean> {
  const res: AxiosResponse<{ success: boolean }> = await axios.patch(
    `/contacts/${id}/code`
  );
  return res.data.success;
}

export async function editContactWithSmsCode(
  id: string,
  form: ContactFormData,
  update_code: string
): Promise<Contact> {
  const res = await axios.put(`/contacts/${id}/code`, {
    ...form,
    update_code,
  });
  return res.data;
}

export async function getContactsTags(): Promise<string[]> {
  const res: AxiosResponse<string[]> = await axios.get("/contacts/tags");
  return res.data || [];
}

export enum ConflictType {
  EMAIL = "EMAIL",
  NAME = "NAME",
  PHONE = "PHONE",
  ADDRESS = "ADDRESS",
}

export type ContactFormData = {
  title: string | undefined;
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string | undefined;
  mobile_phone: string | undefined;
  home_phone: string | undefined;
  work_phone: string | undefined;
  landline: string | undefined;
  address: {
    region: string;
    postal_code: string;
    premises: string;
    locality: string;
    address_line_1: string;
    address_line_2: string;
    country: string;
    state: string;
    city: string;
  };
  birth_date: string | undefined;
  national_insurance_number: string;
  salutation: string;
  occupation: string;
  notes: string;
  bank_account: {
    account_sort_code: string;
    account_number: string;
    account_name: string;
  };
  company: string | null;
  company_name: string;
  company_number: string;
  company_web_filling_code: string;
  company_utr: string;
  fax_number: string;
  website: string;
  directors: string[];
  tags: string[];
  beneficial_owner: string;
  type: ContactType | undefined;
  force_create: boolean | undefined;
  cc_emails: string[] | undefined;
  reasons: { description: string; _id: string }[] | undefined;
};

export type UkApiResponse = {
  title: string;
  company_status?: "active" | "dissolved" | "inactive";
  company_number?: string;
  address_snippet?: string;
  address?: {
    address_line_1: string;
    locality: string;
    postal_code: string;
    premises: string;
  };
};

export type Contact = {
  _id: string;
  author?: {
    _id: string;
    name: string;
  }
  type: ContactType;
  conflict_type: ConflictType;
  title: string;
  name?: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  display_name: string;
  email: string;
  mobile_phone: string;
  home_phone: string;
  work_phone: string;
  landline: string;
  address: {
    _id?: string;
    region: string;
    postal_code: string;
    premises: string;
    locality: string;
    address_line_1: string;
    address_line_2: string;
    country: string;
    state: string;
    city: string;
  };
  birth_date: string;
  national_insurance_number: string;
  salutation: string;
  occupation: string;
  bank_account?: {
    _id?: string;
    account_sort_code: string;
    account_number: string;
    account_name: string;
  };
  notes: string;
  is_archived: boolean;
  law_firm: any;
  company: { company_name: string; _id: string };
  company_name: string;
  company_number: string;
  company_web_filling_code: string;
  company_utr: string;
  fax_number: string;
  website: string;
  directors: string[];
  tags: string[];
  beneficial_owner: string;
  update_code: string;
  created_at: Date;
  updated_at: Date;
  cc_emails: string[];
  contact_files?: ContactFile[];
};

export interface ContactConflict {
  _id: string;
  author: Pick<Attorney, '_id' | 'email' | 'name'>;
  contact: Pick<Contact, '_id' | 'display_name'>
  created_at: string;
  updated_at: string;
  reason: string;
  law_firm: string;
  type: ConflictType;
}

export type ConflictApiResponse = {
  limit: number;
  page: number;
  rows: ContactConflict[];
}

export type ContactsWithConflict = {
  _id: string;
  display_name: string;
};

export type ContactsWithConflictApiResponse = ContactsWithConflict[];

export type ContactsApiResponse = {
  limit: number;
  search: string;
  is_archived: boolean;
  rows: Contact[];
};

export interface ContactFileDto {
  name: string;
  key: string;
  expiration_date: Date | null;
}

export type ContactFile = {
  _id: string;
  name: string;
  key: string;
  expiration_date: Date | null;
  law_firm: string;
  author: string;
  contact: string;
  created_at: Date;
  updated_at: Date;
};

export async function addContactFile(
  id: string,
  payload: ContactFileDto
): Promise<ContactFile> {
  const res: AxiosResponse<ContactFile> = await axios.post(
    `/contacts/${id}/file`,
    payload
  );
  return res.data;
}

type isFileExpiringOutput = {
  color: string;
  message: string;
  expired: boolean;
};
export function contactIsFileExpiring(exp: any): isFileExpiringOutput {
  const expiration = !exp ? undefined : new Date(exp);

  if (!expiration || !isDate(expiration) || !isValid(expiration)) {
    return {
      color: "#9E9E9E",
      message: "expiration date not provided",
      expired: false,
    };
  }

  if (isBefore(expiration, new Date())) {
    return {
      color: "#F44336",
      message: "expired",
      expired: true,
    };
  }

  const soonToExpire = addMonths(new Date(), 4);
  const isExpiring = isBefore(expiration, soonToExpire);

  return isExpiring
    ? {
        color: "#FF9800",
        message: "soon to expire",
        expired: false,
      }
    : {
        color: "#03A9F4",
        message: "non expired documents",
        expired: false,
      };
}

export async function deleteContactFile(fileId: string): Promise<boolean> {
  const res: AxiosResponse = await axios.delete(`/contacts/file/${fileId}`);
  return res.data;
}

export async function editContactFile(
  id: string,
  payload: ContactFileDto
): Promise<MongooseUpdateResponse> {
  const res: AxiosResponse<MongooseUpdateResponse> = await axios.patch(
    `/contacts/file/${id}`,
    payload
  );
  return res.data;
}
