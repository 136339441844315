import { useState } from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import customAxios from "src_common/utils/axios";
import axios from 'axios';
import { useSnackbar } from "notistack";


export enum SupportedFileTypes {
  PDF = 'pdf',
  DOC = 'doc',
  DOCX = 'docx',
  XLXS = 'xlsx',
  XLS = 'xls',
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
  GIF = 'gif',
  TIFF = 'tiff',
  TIF = 'tif',
  TXT = 'txt',
  CSV = 'csv',
  MOV = 'mov',
  MP4 = 'mp4',
  MP3 = 'mp3',
  WAV = 'wav',
  MSG = 'msg',
  EML = 'eml',
  MACRO = 'xlsm',
}

interface UploadUrlType {
  fields: {
    Policy: string;
    'X-Amz-Algorithm': string;
    'X-Amz-Credential': string;
    'X-Amz-Date': string;
    'X-Amz-Signature': string;
    bucket: string;
    key: string;
  };
  url: string;
}

interface Props {
  onSuccess?: (fileData: { key: string; title: string }) => void;
  onSuccessPromise?: (fileData: { key: string; title: string }) => Promise<void>;
  multiple?: boolean;
  matter?: string;
}

export function YAOS3Uploader({ onSuccess, onSuccessPromise, matter, multiple }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const uploadFileToS3 = async (file: File) => {
    try {
      const extension = file.name.split('.').pop()?.toLowerCase() as SupportedFileTypes;

      if (!Object.values(SupportedFileTypes).includes(extension)) {
        throw new Error('File type not supported');
      }

      const payload = {
        size: file.size,
        extension,
        mimetype: file.type,
        matter,
      }

      const { data } = await customAxios.post<UploadUrlType>("/uploads", payload);
      if (!data) throw new Error('Invalid response from server');

      const formData = new FormData();
      formData.set('Policy', data.fields.Policy);
      formData.set('key', data.fields.key);
      formData.set('bucket', data.fields.bucket);
      formData.set('X-Amz-Algorithm', data.fields['X-Amz-Algorithm']);
      formData.set('X-Amz-Credential', data.fields['X-Amz-Credential']);
      formData.set('X-Amz-Date', data.fields['X-Amz-Date']);
      formData.set('X-Amz-Signature', data.fields['X-Amz-Signature']);
      formData.set('Content-Type', file.type);
      formData.append('file', file);

      const { status } = await axios.post(data.url, formData);
      if (status !== 204) throw new Error('Error uploading file');

      if (onSuccess) {
        onSuccess({ key: data.fields.key, title: file.name });
      }
      if (onSuccessPromise) {
        await onSuccessPromise({ key: data.fields.key, title: file.name });
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  const onChange = async (fileList: FileList | null) => {
    const files = Array.from(fileList || []);
    if (files?.length === 0) return;

    setLoading(true);

    await Promise.all(files.map(uploadFileToS3));

    setLoading(false);
  }

  return (
    <LoadingButton loading={loading} variant="contained" component="label">
      Upload

      <input
        hidden
        accept={Object.values(SupportedFileTypes).join(',')}
        onChange={(e) => onChange(e.target.files)}
        multiple={multiple}
        type="file"
      />
    </LoadingButton>
  );
}
