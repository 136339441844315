import {DragEvent, FC, useState} from "react";
import {Box, Stack, Typography} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {ContactAddDocuments, ContactDocumentUploaded} from "./ContactAddDocuments";
import NiceModal from "@ebay/nice-modal-react";
import {SupportedFileTypes, useUploadFileToS3} from "src_common/components/yao-form-fields";
import {ContactFile} from "../../../api/contacts";


export const ContactUploadDocuments: FC<{
    contactId: string;
    onUpload?: (files: ContactFile[]) => void
}> = ({contactId, onUpload}) => {

    const [uploading, setUploading] = useState<boolean>(false);
    const upload = useUploadFileToS3();
    const supportedFormats = Object.values(SupportedFileTypes).map(f => `${f.toLowerCase()}`);

    const formatIsValid = (file: File) => supportedFormats.includes(
        (file?.name || '').split('.').pop()?.toLowerCase() as string
    );

    const onDrop = async (ev: DragEvent<HTMLDivElement>): Promise<PromiseSettledResult<ContactDocumentUploaded>[]> => {
        ev.preventDefault();
        // @ts-ignore
        const files: File[] = ev.dataTransfer.items ? [...ev.dataTransfer.items].filter((item) => item.kind === "file").map((item) => item.getAsFile()) : [...ev.dataTransfer.files];
        if (!files.length) {
            return Promise.resolve([])
        }
        setUploading(true);
        return await Promise.allSettled(files.map(f => formatIsValid(f) ? upload(f) : Promise.resolve(null))) as any
    }

    const onSelect = async (fileList: FileList | null): Promise<PromiseSettledResult<ContactDocumentUploaded>[]> => {
        const files = Array.from(fileList || []);
        if (!files.length) {
            return Promise.resolve([])
        }
        setUploading(true);
        return await Promise.allSettled(files.map(f => formatIsValid(f) ? upload(f) : Promise.resolve(null))) as any
    }

    const handleFileUploaded = (files: PromiseSettledResult<ContactDocumentUploaded>[]) => {
        setUploading(false)
        // @ts-ignore
        const filteredFiles: ContactDocumentUploaded[] = files.filter(f => f.status === 'fulfilled' && !!f.value).map((f: PromiseFulfilledResult<ContactDocumentUploaded>) => f.value)
        if (!filteredFiles.length) {
            return
        }
        NiceModal.show<ContactFile[]>(ContactAddDocuments, {
            contactId,
            files: filteredFiles,
        }).then(response => {
            if(Array.isArray(response) && response.length > 0){
                onUpload && onUpload(response)
            }
        })
    }

    return <Box sx={{
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        border: "1px dashed #D8E4E4",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        display: "flex",
        padding: 2,
        marginTop: 2,
    }}
                onDrop={(e) => {
                    onDrop(e).then(handleFileUploaded)
                }}
                onDragOver={(ev) => {
                    ev.preventDefault();
                }}>
        <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
            <img
                src="/assets/icons/flags/ic_folder.png"
                alt="Folder"
                width={64}
            />
            <Stack direction="column" spacing={1} justifyContent="center" alignItems="center">
                <Typography sx={{fontSize: 12, fontWeight: 700}}>
                    Drag & drop client documents here
                </Typography>
                <Typography
                    color="gray"
                    sx={{fontSize: 12, fontWeight: 700}}
                >
                    OR
                </Typography>
                <LoadingButton
                    loading={uploading}
                    variant="contained"
                    component="label"
                >
                    Browser file
                    {!uploading && (
                        <input
                            hidden
                            accept={supportedFormats.map((ft) => `.${ft}`).join(',')}
                            onChange={(e) => onSelect(e.target.files).then(handleFileUploaded)}
                            type="file"
                            multiple
                        />
                    )}
                </LoadingButton>
            </Stack>
        </Stack>
    </Box>
}