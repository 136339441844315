import { ROOT_HOME } from "src_common/routes/paths";

const ROOT_TASKS = "/tasks";
const ROOT_CALENDAR = "/calendar";
const ROOT_CORRESPONDENCE = "/correspondences";
const ROOT_MATTERS = "/matters";
const ROOT_LEDGERS = "/ledgers";

const ROOT_CONTACTS = "/contacts";
const ROOT_ACCOUNTS = "/accounting/statements";
const ROOT_PAYMENT_NOTIFICATIONS = "/payment-notifications";
const ROOT_FORMS = "/forms-template";
const ROOT_PRECEDENTS = "/templates/precedents";
const ROOT_EMAILS = '/templates/emails'
const ROOT_USER_MGT = "/attorneys/list";

const ROOT_SUPPORT = "/help-and-support";
const ROOT_EXIT = "/exit";

const ROOT_WORKFLOW = "/workflow-template"

const ROOT_POST_HANDLING = '/post-handling'

export const PATH_HOME = {
  root: ROOT_HOME,
};

export const PATH_TASKS = {
  root: ROOT_TASKS,
};

export const PATH_CALENDAR = {
  root: ROOT_CALENDAR,
};

export const PATH_CORRESPONDENCE = {
  root: ROOT_CORRESPONDENCE,
};

export const PATH_MATTERS = {
  root: ROOT_MATTERS,
};

export const PATH_CONTACTS = {
  root: ROOT_CONTACTS,
};

export const PATH_ACCOUNTS = {
  root: ROOT_ACCOUNTS,
};

export const PATH_LEDGERS = {
  root: ROOT_LEDGERS,
};

export const PATH_PAYMENT_NOTIFICATIONS = {
  root: ROOT_PAYMENT_NOTIFICATIONS,
};

export const PATH_FORMS = {
  root: ROOT_FORMS,
};

export const PATH_PRECEDENTS = {
  root: ROOT_PRECEDENTS,
  email: ROOT_EMAILS,
};

export const PATH_USER_MGT = {
  root: ROOT_USER_MGT,
};

export const PATH_SUPPORT = {
  root: ROOT_SUPPORT,
};

export const PATH_EXIT = {
  root: ROOT_EXIT,
};

export const PATH_WORKFLOW = {
  root: ROOT_WORKFLOW,
}

export const PATH_POST_HANDLING = {
  root: ROOT_POST_HANDLING,
}