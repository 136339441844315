import {useEffect} from "react";
import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import Page from "src_common/components/Page";
import {useAPI} from "../../src_common/hooks/useAPI";
import {getDashboard} from "../api/dashboard";

const ContainerStyle = styled(Container)(({ theme }) => ({
  margin: "auto",
  textAlign: "center",
  position: "relative",
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  paddingLeft: 0,
  paddingRight: 0
}));

export default function HomePage() {
  const getDashboardAPI = useAPI(getDashboard);

  useEffect(() => {
    getDashboardAPI.invoke();
  }, [])

  return (
    <Page title="YAO">
      <ContainerStyle style={{padding: 0, margin: 0, paddingTop: 10}}>
        {!getDashboardAPI.loading &&
            <div style={{ height: '100vh' }}>
              {getDashboardAPI.data?.data && (
                  <iframe
                      title="Dashboard"
                      scroll="no"
                      src={getDashboardAPI.data.data.url}
                      frameBorder="0"
                      width="100%"
                      height="100%"
                  />
              )}
            </div>
        }
      </ContainerStyle>
    </Page>
  );
}
