import axios from "src_common/utils/axios";

export async function getDepartments(): Promise<Department[]> {
  const res = await axios.get("departments/");
  return res.data;
}


export type Department = {
  _id: string,
  title: string,
  short_description: string,
  long_description: string
}