import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "src_common/hooks/useAuth";
import { ROOT_HOME } from "src_common/routes/paths";

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={ROOT_HOME} />;
  }

  return <>{children}</>;
}
