export const copyToClipboard = (message: string, inputRef: React.RefObject<HTMLInputElement>, callback: (error: null | object) => void) => {
    try {
        if (!navigator.clipboard) {
      if(!inputRef || !inputRef.current){
        return;
      }
      inputRef.current.innerText = message;
      inputRef.current.select();
      inputRef.current.focus();
      document.execCommand("copy");
      callback(null);
    } else {
      navigator.clipboard.writeText(message).then(() => {
        callback(null);
      }, callback);
    }
    }
    catch (e) {
        callback(e);
    }

}