import { Theme } from "@mui/material/styles";
import palette from "../palette";

export default function Table(theme: Theme) {
  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: theme.palette.action.selected,
          },
          "&.MuiTableRow-hover:hover": {
            backgroundColor: palette.yao.grey[1],
            boxShadow: `4px 4px 25px ${palette.yao.grey[8_12]}`,
            borderRadius: 8,
            cursor: "pointer",
          },
          height: 52,
          "&:last-of-type": {
            height: "auto",
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          ".MuiTableCell-root": {
            color: palette.yao.grey[7],
            paddingTop: 10,
            paddingBottom: 10,
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          fontSize: 11,
          fontWeight: 400,
          color: palette.yao.grey[7],
          textTransform: "uppercase",
          "&.Mui-active": {
            color: palette.yao.grey[7],
          },
        },
        icon: {
          fontSize: 13,
          color: palette.yao.grey[7],
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          background: palette.yao.grey[1],
          outline: `1px solid ${palette.yao.primary[4]}`,
          borderRadius: 8,
          tr: {
            position: "relative",

            "&:after": {
              content: "''",
              position: "absolute",
              width: "calc(100% - 44px)",
              borderBottom: `0.5px solid ${palette.yao.primary[4]}`,
              borderTop: `0.5px solid ${palette.yao.primary[4]}`,
              height: "100%",
              pointerEvents: "none",
              top: 0,
              left: 22,
            },

            "&:first-of-type": {
              "td:first-of-type": {
                borderTopLeftRadius: 8,
              },
              "td:last-of-type": {
                borderTopRightRadius: 8,
              },
              "&:after": {
                borderTop: 0,
              },
            },

            "&:nth-last-of-type(2)": {
              "td:first-of-type": {
                borderBottomLeftRadius: 8,
              },
              "td:last-of-type": {
                borderBottomRightRadius: 8,
              },
            },

            "&:last-of-type:after": {
              display: "none",
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 0,
          borderBottom: "none",
        },
        head: {
          color: theme.palette.text.secondary,
          "&:first-of-type": {
            paddingLeft: theme.spacing(3),
            borderTopLeftRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius,
          },
          "&:last-of-type": {
            paddingRight: theme.spacing(8),
            borderTopRightRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
          },
        },

        stickyHeader: {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`,
        },
        body: {
          fontSize: 13,
          "&:first-of-type": {
            paddingLeft: theme.spacing(3),
          },
          "&:last-of-type": {
            paddingRight: theme.spacing(8),
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: `solid 1px ${theme.palette.divider}`,
        },
        toolbar: {
          height: 64,
        },
        select: {
          "&:focus": {
            borderRadius: theme.shape.borderRadius,
          },
        },
        selectIcon: {
          width: 20,
          height: 20,
          marginTop: -4,
        },
      },
    },
  };
}
