import { createContext, Dispatch, FC, SetStateAction, useState } from "react";
import { CaseType, getActiveCaseTypes } from "src_lawfirm/api/case-types";
import { Department } from "../../../src_lawfirm/api/departments";
import { getDepartments } from "src_lawfirm/api/departments";
import {
  YaoSelectorFilter,
  YaoSelectorFilterOptions,
  YaoSelectorFilterPlaceholder,
  YaoSelectorInputProps,
  YaoSelectorItem,
} from "./yao-selector.types";

type YaoSelectorContextProps = {
  setup: (params: YaoSelectorInputProps) => void;
  title: string;
  items: YaoSelectorItem[];
  filtersEnabled: YaoSelectorFilterOptions;
  filtersPlaceholder: YaoSelectorFilterPlaceholder;
  departments: Department[];
  caseTypes: CaseType[];
  filter: YaoSelectorFilter;
  setFilter: Dispatch<SetStateAction<YaoSelectorFilter>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  multipleSelection: boolean;
  selection: string[];
  toggleSelection: (id: string) => void;
  departmentsLoading: boolean;
  backdropEnabled: boolean;
};

const DEFAULT_VALUES = {
  setup: () => {},
  title: "Find",
  items: [],
  filtersEnabled: {
    case_type: false,
    department: false,
    text: true,
  },
  filtersPlaceholder: {
    case_type: "Choose case type",
    text: "Type here to search",
  },
  departments: [],
  caseTypes: [],
  filter: {
    case_type: "",
    department: "",
    text: "",
  },
  setFilter: () => {},
  loading: false,
  setLoading: () => {},
  multipleSelection: false,
  selection: [],
  toggleSelection: (id: string) => {},
  departmentsLoading: false,
  backdropEnabled: true,
};

const YaoSelectorContext =
  createContext<YaoSelectorContextProps>(DEFAULT_VALUES);

const YaoSelectorContextProvider: FC = ({ children }) => {
  const [title, setTitle] = useState<string>(DEFAULT_VALUES.title);
  const [items, setItems] = useState<YaoSelectorItem[]>(DEFAULT_VALUES.items);
  const [filtersEnabled, setFiltersEnabled] =
    useState<YaoSelectorFilterOptions>(DEFAULT_VALUES.filtersEnabled);
  const [filtersPlaceholder, setFiltersPlaceholder] =
    useState<YaoSelectorFilterPlaceholder>(DEFAULT_VALUES.filtersPlaceholder);
  const [filter, setFilter] = useState<YaoSelectorFilter>(
    DEFAULT_VALUES.filter
  );
  const [loading, setLoading] = useState<boolean>(DEFAULT_VALUES.loading);
  const [departments, setDepartments] = useState<Department[]>(
    DEFAULT_VALUES.departments
  );
  const [caseTypes, setCaseTypes] = useState<CaseType[]>(
    DEFAULT_VALUES.caseTypes
  );
  const [multipleSelection, setMultipleSelection] = useState<boolean>(
    DEFAULT_VALUES.multipleSelection
  );
  const [selection, setSelection] = useState<string[]>(
    DEFAULT_VALUES.selection
  );
  const [departmentsLoading, setDepartmentsLoading] = useState<boolean>(DEFAULT_VALUES.departmentsLoading);
  const [backdropEnabled, setBackdropEnabled] = useState<boolean>(DEFAULT_VALUES.backdropEnabled);

  const setup = (params: YaoSelectorInputProps) => {
    setTitle(params.title);
    setItems(params.items);
    setFiltersEnabled({
      case_type: params.filters?.case_type || false,
      department: params.filters?.department || false,
      text: params.filters?.text || false,
    });
    setFiltersPlaceholder({
      case_type: params.placeholders?.case_type || "Choose case type",
      text: params.placeholders?.text || "Type here to search",
    });
    setFilter({
      case_type: params.defaultValues?.case_type || "",
      department: params.defaultValues?.department || "",
      text: params.defaultValues?.text || "",
    });
    setMultipleSelection(params.multiple || false);
    if (params.filters?.case_type || params.filters?.department) {
      setDepartmentsLoading(true);
      (async () => {
        try {
          const [caseTypeApi, departmentsAPI] = await Promise.all([
            getActiveCaseTypes(),
            getDepartments(),
          ]);
          setCaseTypes(caseTypeApi || []);
          setDepartments(departmentsAPI || []);
          setDepartmentsLoading(false);
        } catch (e) {
          console.error(e);
          setDepartmentsLoading(false);
        }
      })();
    }
    if(params.disableBackdrop){
      setBackdropEnabled(false);
    }
  };

  const toggleSelection = (id: string) => {
    const indexOf = selection.indexOf(id);
    let next: string[] = [];
    if (multipleSelection) {
      next = [...selection];
      if (indexOf === -1) {
        next.push(id);
      } else {
        next.splice(indexOf, 1);
      }
    } else if (indexOf === -1) {
      next.push(id);
    }
    setSelection(next);
  };

  return (
    <YaoSelectorContext.Provider
      value={{
        setup,
        title,
        items,
        filtersEnabled,
        filtersPlaceholder,
        departments,
        caseTypes,
        filter,
        setFilter,
        loading,
        setLoading,
        multipleSelection,
        selection,
        toggleSelection,
        departmentsLoading,
        backdropEnabled,
      }}
    >
      {children}
    </YaoSelectorContext.Provider>
  );
};

export { YaoSelectorContextProvider };
export default YaoSelectorContext;
