import { ReactNode, createContext, useState, useEffect } from "react";
import { useAPI } from "src_common/hooks/useAPI";
import {
  Correspondence,
  CorrespondenceFilter,
  searchCorrespondences,
} from "src_lawfirm/api/correspondence";

export const defaultFilters: CorrespondenceFilter = {
  category: [],
  sort: { correspondence_date: -1 },
};

export type CorrespondenceContextProps = {
  filters: CorrespondenceFilter;
  correspondences: Correspondence[];
  setFilters: (filters: CorrespondenceFilter) => void;
  resetFilters: () => void;
  callCorrespondencesApi: (
    matter_id: string,
    newFilters?: CorrespondenceFilter,
    cb?: () => void
  ) => void;
};

const initialState: CorrespondenceContextProps = {
  filters: defaultFilters,
  setFilters: () => {},
  resetFilters: () => {},
  callCorrespondencesApi: () => {},
  correspondences: [],
};

const CorrespondenceContext = createContext(initialState);

type CorrespondenceProviderProps = {
  children: ReactNode;
};

function CorrespondenceProvider({ children }: CorrespondenceProviderProps) {
  const [filters, setFilters] = useState<CorrespondenceFilter>(defaultFilters);
  const [correspondences, setCorrespondences] = useState<Correspondence[]>([]);

  const searchCorrespondencesAPI = useAPI(searchCorrespondences);

  const callCorrespondencesApi = (
    matter_id: string,
    newFilters = filters,
    cb?: () => void
  ) => {
    searchCorrespondencesAPI.invoke({ ...newFilters, matter_id });
    if (cb) cb();
  };

  useEffect(() => {
    if (searchCorrespondencesAPI.data) {
      setCorrespondences(searchCorrespondencesAPI.data);
    }
  }, [searchCorrespondencesAPI.data]);

  return (
    <CorrespondenceContext.Provider
      value={{
        filters,
        correspondences,
        setFilters,
        resetFilters: () => setFilters(defaultFilters),
        callCorrespondencesApi,
      }}
    >
      {children}
    </CorrespondenceContext.Provider>
  );
}

export { CorrespondenceProvider, CorrespondenceContext };
