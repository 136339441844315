import { styled } from "@mui/material/styles";
import { List, Box, ListSubheader } from "@mui/material";
import useLocales from "src_common/hooks/useLocales";
import { NavSectionProps } from "../type";
import { NavListRoot } from "./NavList";
import useAuth from "src_common/hooks/useAuth";
import {useContext} from "react";
import {MenuContext} from "../../menu/menu-context";

export const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

export default function NavSectionVertical({
  navConfig,
  isCollapse = false,
  ...other
}: NavSectionProps) {
  const { translate } = useLocales();
  const { user } = useAuth();
  const menuContext = useContext(MenuContext)

  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {translate(group.subheader)}
          </ListSubheaderStyle>

          {group.items
            .filter(
              (list) =>
                list.visible === undefined ||
                list.visible === null ||
                typeof list.visible !== "function" ||
                list.visible(user, menuContext)
            )
            .map((list) => (
              <NavListRoot
                key={list.title + list.path}
                list={list}
                isCollapse={isCollapse}
              />
            ))}
        </List>
      ))}
    </Box>
  );
}
