import { useFormContext, RegisterOptions, Controller } from "react-hook-form";
import NumberFormat, { InputAttributes } from "react-number-format";
import { TextField, TextFieldProps } from "@mui/material";
import "./YAOFieldInputOverride.css";
import React, { forwardRef } from "react";

type YAOFieldCurrencyProps = Omit<
  TextFieldProps,
  "name" | "fullWidth" | "className" | "error" | "helperText"
> & {
  name: string;
  rules?: Omit<
    RegisterOptions<Record<string, any>, string>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const NumberFormatCustom = forwardRef<
  NumberFormat<InputAttributes>,
  CustomProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      decimalScale={2}
    />
  );
});

export function YAOFieldCurrency({
  name,
  rules,
  defaultValue,
  margin = "none",
  variant = "outlined",
  ...fieldProps
}: YAOFieldCurrencyProps) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({
        field: { value = "", onChange, ...props },
        fieldState: { error },
      }) => (
        <TextField
          fullWidth
          {...fieldProps}
          {...props}
          value={fieldProps.value || value}
          onChange={(e) => onChange(e.target.value)}
          variant={variant}
          margin={margin}
          id={name}
          error={!!error}
          helperText={error?.message}
          className="yao-input-override"
          InputProps={{
            ...(fieldProps.InputProps || {}),
            inputComponent: NumberFormatCustom as any,
          }}
        />
      )}
    />
  );
}
