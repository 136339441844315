import axios from "src_common/utils/axios";

export async function getCasetype(department_id: string | undefined): Promise<CaseType[]> {
  const res = await axios.get(`case-types`, {
    params: {
      department_id,
    },
  });

  return res.data;
}

export type CaseType = {
  _id: string;
  title: string;
  short_description: string;
  long_description: string;
  law_firm: string;
  departments: string[];
  is_deleted: boolean;
  created_at: Date;
  updated_at: Date;
  department?: { _id: string; title: string } 
};

export async function getActiveCaseTypes(): Promise<CaseType[]> {
  const res = await axios.get("/case-types/active");
  return res.data;
}
