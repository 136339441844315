import { FormTemplateModel } from "../../@interfaces/forms";
import NiceModal from "@ebay/nice-modal-react";
import CheatSheetModal from "./CheatSheetModal";
import { CheatSheetField, CheatSheetGroup } from "./CheatSheet.interface";

type UseCheatSheetHook = {
  forForm: (current?: FormTemplateModel, currentFormId?: string) => void;
  forPrecedent: () => void;
  extractFieldKey: (value: any) => string;
};

export const cheatSheetCaseContactGroup = (
  key: string,
  path: string
): CheatSheetField[] => {
  return [
    {
      id: `${path}.1`,
      key: `{${key}.salutation}`,
      label: "Preferred Name",
    },
    {
      id: `${path}.2`,
      key: `{${key}.last-name}`,
      label: "Last name",
    },
    {
      id: `${path}.3`,
      key: `{${key}.name}`,
      label: "Full name",
    },
    {
      id: `${path}.4`,
      key: `{${key}.full-address}`,
      label: "Full Address",
      sub: "(formatted for letters, i.e. not in a single line but in rows)",
    },
    {
      id: `${path}.5`,
      key: `{${key}.company-number}`,
      label: "Company Number",
    },
    {
      id: `${path}.5`,
      key: `{${key}.reference}`,
      label: "Reference",
      sub: "(for current Matter)",
    },
    {
      id: `${path}.7`,
      label: "Company",
      children: [
        {
          id: `${path}.7.1`,
          key: `{${key}.company.name}`,
          label: "Name",
        },
        {
          id: `${path}.7.2`,
          key: `{${key}.company.email}`,
          label: "Email Address",
        },
        {
          id: `${path}.7.3`,
          key: `{${key}.company.company-number}`,
          label: "Number",
        },
        {
          id: `${path}.7.4`,
          key: `{${key}.company.full-address}`,
          label: "Full Address",
          sub: "(formatted for letters, i.e. not in a single line but in rows)",
        },
        {
          id: `${path}.7.5`,
          key: `{${key}.company.address.city}`,
          label: "City",
        },
        {
          id: `${path}.7.6`,
          key: `{${key}.company.address.state}`,
          label: "State",
        },
        {
          id: `${path}.7.7`,
          key: `{${key}.company.address.postal_code}`,
          label: "Postal code",
        },
        {
          id: `${path}.7.8`,
          key: `{${key}.company.address.address_line_1}`,
          label: "Address line 1",
        },
      ],
    },
  ];
};

export const cheatSheetCommonGroups = (): CheatSheetGroup[] => {
  return [
    {
      id: "1",
      title: "Clients Details",
      tabs: [
        {
          id: "1.1",
          label: "Client",
          fields: [
            {
              id: "1.1.1",
              key: "{all-clients.salutation}",
              label: "All Clients Preferred Names",
            },
            {
              id: "1.1.2",
              key: "{all-clients.last-name}",
              label: "All Clients Last Names",
            },
            {
              id: "1.1.3",
              key: "{all-clients.name}",
              label: "All Clients Full Names",
            },
            {
              id: "1.1.4",
              key: "{client.0.salutation}",
              label: "Client Preferred Name",
            },
            {
              id: "1.1.5",
              key: "{client.0.name}",
              label: "Client Name",
            },
            {
              id: "1.1.6",
              key: "{client.0.last-name}",
              label: "Client Last name",
            },
            {
              id: "1.1.7",
              key: "{client.0.email}",
              label: "Email Address",
            },
            {
              id: "1.1.8",
              key: "{client.0.national-insurance-number}",
              label: "National Insurance Number",
            },
            {
              id: "1.1.9",
              key: "{client.0.birth-date}",
              label: "Date of Birth",
            },
            {
              id: "1.1.10",
              key: "{client.0.company-number}",
              label: "Company Number",
            },
            {
              id: "1.1.11",
              label: "Address",
              children: [
                {
                  id: "1.1.11.1",
                  key: "{client.0.full-address}",
                  label: "Full Address",
                  sub: "(formatted for letters, i.e. not in a single line but in rows)",
                },
                {
                  id: "1.1.11.2",
                  key: "{client.0.address.city}",
                  label: "City",
                },
                {
                  id: "1.1.11.3",
                  key: "{client.0.address.state}",
                  label: "State",
                },
                {
                  id: "1.1.11.4",
                  key: "{client.0.address.postal_code}",
                  label: "Postal code",
                },
                {
                  id: "1.1.11.5",
                  key: "{client.0.address.address_line_1}",
                  label: "Address line 1",
                },
              ],
            },
          ],
        },
        {
          id: "1.2",
          label: "Client Company",
          fields: [
            {
              id: "1.2.1",
              key: "{client.0.company.name}",
              label: "Name",
            },
            {
              id: "1.2.2",
              key: "{client.0.company.email}",
              label: "Email Address",
            },
            {
              id: "1.2.3",
              key: "{client.0.company.company-number}",
              label: "Number",
            },
            {
              id: "1.2.4",
              label: "Address",
              children: [
                {
                  id: "1.2.4.1",
                  key: "{client.0.company.full-address}",
                  label: "Full Address",
                  sub: "(formatted for letters, i.e. not in a single line but in rows)",
                },
                {
                  id: "1.2.4.2",
                  key: "{client.0.company.address.city}",
                  label: "City",
                },
                {
                  id: "1.2.4.3",
                  key: "{client.0.company.address.state}",
                  label: "State",
                },
                {
                  id: "1.2.4.4",
                  key: "{client.0.company.address.postal_code}",
                  label: "Postal code",
                },
                {
                  id: "1.2.4.5",
                  key: "{client.0.company.address.address_line_1}",
                  label: "Address line 1",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "2",
      title: "Case Contacts",
      tabs: [
        {
          id: "2.1",
          label: "Solicitor",
          fields: cheatSheetCaseContactGroup("solicitor", "2.1"),
        },
        {
          id: "2.2",
          label: "Lender",
          fields: cheatSheetCaseContactGroup("lender", "2.2"),
        },
        {
          id: "2.3",
          label: "Broker",
          fields: cheatSheetCaseContactGroup("broker", "2.3"),
        },
        {
          id: "2.4",
          label: "Estate Agent",
          fields: cheatSheetCaseContactGroup("estate-agent", "2.4"),
        },
        {
          id: "2.5",
          label: "Counsel",
          fields: cheatSheetCaseContactGroup("counsel", "2.5"),
        },
        {
          id: "2.6",
          label: "Expert",
          fields: cheatSheetCaseContactGroup("expert", "2.6"),
        },
        {
          id: "2.7",
          label: "Insurer",
          fields: cheatSheetCaseContactGroup("insurer", "2.7"),
        },
        {
          id: "2.8",
          label: "Landlord",
          fields: cheatSheetCaseContactGroup("landlord", "2.8"),
        },
        {
          id: "2.9",
          label: "Management Company",
          fields: cheatSheetCaseContactGroup("management-company", "2.9"),
        },
        {
          id: "2.10",
          label: "Local Authority",
          fields: cheatSheetCaseContactGroup("local-authority", "2.10"),
        },
        {
          id: "2.11",
          label: "Other Party",
          fields: cheatSheetCaseContactGroup("other-party", "2.11"),
        },
        {
          id: "2.12",
          label: "Tenant",
          fields: cheatSheetCaseContactGroup("tenant", "2.12"),
        },
        {
          id: "2.13",
          label: "Search Provider",
          fields: cheatSheetCaseContactGroup("search-provider", "2.13"),
        },
        {
          id: "2.14",
          label: "Court",
          fields: cheatSheetCaseContactGroup("court", "2.14"),
        },
      ],
    },
    {
      id: "3",
      title: "Matter Details",
      fields: [
        {
          id: "3.1",
          key: "{matter.case-name}",
          label: "Matter Name",
        },
        {
          id: "3.2",
          key: "{matter.number}",
          label: "Matter Number",
        },
        {
          id: "3.5",
          key: "{matter.rate}",
          label: "Override Default Rate",
        },
        {
          id: "3.6",
          key: "{matter.client-letter-subject}",
          label: "Subject for client correspondence",
        },
        {
          id: "3.7",
          key: "{matter.other-party-letter-subject}",
          label: "Subject for other party correspondence",
        },
        {
          id: "3.8",
          key: "{matter.client-account-balance}",
          label: "Client account balance",
        },
        {
          id: "3.9",
          key: "{matter.office-account-balance}",
          label: "Office account balance",
        },
        {
          id: "3.10",
          key: "{matter.outstanding}",
          label: "Outstanding invoice total",
        },
      ],
    },
    {
      id: "5",
      title: "Matter Responsible",
      tabs: [
        {
          id: "5.1",
          label: "Responsible Lawyer",
          fields: [
            {
              id: "5.1.1",
              key: "{responsible-lawyer.full-name}",
              label: "Full Name",
            },
            {
              id: "5.1.2",
              key: "{responsible-lawyer.surname}",
              label: "Last Name",
            },
            {
              id: "5.1.3",
              key: "{responsible-lawyer.initials}",
              label: "Initials",
            },
            {
              id: "5.1.4",
              key: "{responsible-lawyer.name}",
              label: "First Name",
            },
            {
              id: "5.1.5",
              key: "{responsible-lawyer.email}",
              label: "Email",
            },
            {
              id: "5.1.6",
              key: "{responsible-lawyer.phone}",
              label: "Phone",
            },
            {
              id: "5.1.7",
              key: "{responsible-lawyer.rate}",
              label: "Rate",
            },
          ],
        },
        {
          id: "5.2",
          label: "Responsible Supervisor",
          fields: [
            {
              id: "5.2.1",
              key: "{responsible-supervisor.full-name}",
              label: "Full Name",
            },
            {
              id: "5.2.2",
              key: "{responsible-supervisor.name}",
              label: "First Name",
            },
            {
              id: "5.2.3",
              key: "{responsible-supervisor.initials}",
              label: "Initials",
            },
            {
              id: "5.2.4",
              key: "{responsible-supervisor.email}",
              label: "Email",
            },
            {
              id: "5.2.5",
              key: "{responsible-supervisor.phone}",
              label: "Phone",
            },
            {
              id: "5.2.6",
              key: "{responsible-supervisor.rate}",
              label: "Rate",
            },
          ],
        },
        {
          id: "5.4",
          label: "Secretary",
          fields: [
            {
              id: "5.4.1",
              key: "{secretary.full-name}",
              label: "Full Name",
            },
            {
              id: "5.4.2",
              key: "{secretary.name}",
              label: "First Name",
            },
            {
              id: "5.4.3",
              key: "{secretary.initials}",
              label: "Initials",
            },
            {
              id: "5.4.4",
              key: "{secretary.email}",
              label: "Email",
            },
            {
              id: "5.4.5",
              key: "{secretary.phone}",
              label: "Phone",
            },
            {
              id: "5.4.6",
              key: "{secretary.rate}",
              label: "Rate",
            },
          ],
        },
        {
          id: "5.5",
          label: "Paralegal",
          fields: [
            {
              id: "5.5.1",
              key: "{paralegal.full-name}",
              label: "Full Name",
            },
            {
              id: "5.5.2",
              key: "{paralegal.name}",
              label: "First Name",
            },
            {
              id: "5.5.3",
              key: "{paralegal.initials}",
              label: "Initials",
            },
            {
              id: "5.5.4",
              key: "{paralegal.email}",
              label: "Email",
            },
            {
              id: "5.5.5",
              key: "{paralegal.phone}",
              label: "Phone",
            },
            {
              id: "5.5.6",
              key: "{paralegal.rate}",
              label: "Rate",
            },
          ],
        },
        {
          id: "5.3",
          label: "Current User",
          fields: [
            {
              id: "5.3.1",
              key: "{current-user.full-name}",
              label: "Full Name",
            },
            {
              id: "5.3.2",
              key: "{current-user.name}",
              label: "First Name",
            },
            {
              id: "5.3.3",
              key: "{current-user.initials}",
              label: "Initials",
            },
            {
              id: "5.3.4",
              key: "{current-user.email}",
              label: "Email",
            },
            {
              id: "5.3.5",
              key: "{current-user.phone}",
              label: "Phone",
            },
            {
              id: "5.3.6",
              key: "{current-user.rate}",
              label: "Rate",
            },
          ],
        },
      ],
    },
    {
      id: "4",
      title: "Law Firm",
      fields: [
        {
          id: "4.1",
          key: "{law-firm.code}",
          label: "Code",
        },
        {
          id: "4.2",
          key: "{law-firm.name}",
          label: "Name",
        },
        {
          id: "4.3",
          key: "{law-firm.regulation-number}",
          label: "Regulation Number",
        },
        {
          id: "4.4",
          key: "{law-firm.vat-registration-number}",
          label: "Tax Registration Number",
        },
        {
          id: "4.5",
          key: "{law-firm.phone}",
          label: "Phone",
        },
        {
          id: "4.8",
          key: "{law-firm.fax}",
          label: "Fax",
        },
        {
          id: "4.6",
          key: "{law-firm.email}",
          label: "Email Address",
        },
        {
          id: "4.7",
          label: "Address",
          children: [
            {
              id: "4.7.1",
              key: "{law-firm.full-address}",
              label: "Full Address",
              sub: "(formatted for letters, i.e. not in a single line but in rows)",
            },
            {
              id: "4.7.2",
              key: "{law-firm.address.city}",
              label: "City",
            },
            {
              id: "4.7.3",
              key: "{law-firm.address.state}",
              label: "State",
            },
            {
              id: "4.7.4",
              key: "{law-firm.address.street}",
              label: "Street",
            },
            {
              id: "4.7.5",
              key: "{law-firm.address.number}",
              label: "Number",
            },
            {
              id: "4.7.6",
              key: "{law-firm.address.postal_code}",
              label: "Postal Code",
            },
          ],
        },
      ],
    },
  ];
};

export const useCheatSheet = (): UseCheatSheetHook => {
  const extractFieldKey = (value: any): string => {
    if (!value || typeof value !== "string") {
      return "";
    }
    return `{${value.toLowerCase().replace(/\s/g, "-")}}`;
  };

  const forForm = (current?: FormTemplateModel, currentFormId?: string) => {
    const groups: CheatSheetGroup[] = cheatSheetCommonGroups();
    const usableFields = (current?.fields || []).filter(
      (f) => f.name?.length > 0 && !!f.type
    );
    if (usableFields.length > 0) {
      groups.push({
        id: currentFormId || "0",
        title: "Current Form",
        fields: usableFields.map((field) => ({
          id: field._id,
          key: extractFieldKey(field.name),
          label: field.name,
          sub: field.type.label,
        })),
      });
    }
    NiceModal.show(CheatSheetModal, {
      title: "Select Form Field",
      description: [
        'Copy "Fields" below to insert into your Form or Precedent. Fields will be replaced by the Matter specific information, for example {client.0.salutation} will be presented in a completed Precedent as "Homer Simpson" and {matter.case-name} will be presented as "H Simpson vs Fox Studios". The copy buttons on the right simply copy the "Field Code" to your clipboard to allow you to then paste in the correct position of your template Form Document or Precedent.',
        "There is no limit on the number of fields you can use in a template nor how many times a single field is used.",
      ],
      groups,
      currentForm: currentFormId,
      enableFormsSearch: true,
    });
  };

  const forPrecedent = () => {
    NiceModal.show(CheatSheetModal, {
      title: "Select Precedent Field",
      description: [
        'Copy "Fields" below to insert into your Precedent document. Fields will be replaced by the Matter specific information, for example {client.0.salutation} will be presented in a completed Precedent as "Homer Simpson" and {matter.case-name} will be presented as "H Simpson vs Fox Studios". The copy buttons on the right simply copy the "Field Code" to your clipboard to allow you to then paste in the correct position of your template Precedent Document.',
        "There is no limit on the number of fields you can use in a template nor how many times a single field is used.",
      ],
      groups: cheatSheetCommonGroups(),
      enableFormsSearch: true,
    });
  };

  return {
    forForm,
    forPrecedent,
    extractFieldKey,
  };
};
