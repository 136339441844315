import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Correspondence,
  CorrespondenceForm,
  updateCorrespondence,
  duplicateCorrespondence
} from "src_lawfirm/api/correspondence";
import * as Yup from "yup";
import { FileForm } from "./FileForm.component";

const FileSchema = Yup.object().shape({
  title: Yup.string().required("File name is required"),
});

export const UpdateCorrespondenceModal = NiceModal.create<{
  defaultValues: Correspondence;
  matterId: string;
  isDuplicateAction?: boolean
}>(({ defaultValues, matterId, isDuplicateAction }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const modal = useModal();

  const handleCloseForm = useCallback(() => {
    modal.hide();
  }, [modal]);

  useEffect(() => {
    methods.reset({
      _id: defaultValues._id,
      title: isDuplicateAction ? `${defaultValues.title} - Copy`: defaultValues.title,
    });
  }, [defaultValues, isDuplicateAction]);

  const methods = useForm<Partial<CorrespondenceForm>>({
    resolver: yupResolver(FileSchema),
    defaultValues: {
      _id: defaultValues._id,
      title: isDuplicateAction ? `${defaultValues.title} - Copy`: defaultValues.title,
    },
  });
  const onSubmit = async (form: Partial<CorrespondenceForm>) => {
    const method = isDuplicateAction ? duplicateCorrespondence : updateCorrespondence
    try {
      const file = await method({ ...form, matter: matterId });
      const toast = `${file.title} ${isDuplicateAction ? 'duplicated' : 'updated'} successfully!`;
      enqueueSnackbar(toast);

      queryClient.invalidateQueries({
        queryKey: [matterId, "correspondences"],
      });

      modal.resolve(file);
      modal.hide();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <FileForm
      visible={modal.visible}
      methods={methods}
      onSubmit={onSubmit}
      handleClose={handleCloseForm}
      buttonTitle={isDuplicateAction ? "Duplicate" : "Update"}
      title={isDuplicateAction ? "Duplicate File" : "Update File"}
    />
  );
});
