import axios from "src_common/utils/axios";
import { ClockTimeEntryData } from "src_lawfirm/pages/matters/TimeEntry/ClockTimeEntry";
import { Attorney } from "./attorneys";
import { Matter } from "./matters";
import { TimeEntry } from "./time-entries";

export async function getTimeTracks(): Promise<TimeTrack[]> {
  const res = await axios.get("time-track/");
  return res.data;
}

export async function createTimeTrack(matterId: string): Promise<TimeTrack> {
  const res = await axios.post("time-track/", {
    matter: matterId,
  });

  return res.data;
}

export async function clockTimeTrack(timeTrackId: string): Promise<TimeTrack> {
  const res = await axios.put(`time-track/${timeTrackId}/clock`);

  return res.data;
}
export async function stopTimeTrack(params: {
  timeTrackId: string;
  data: ClockTimeEntryData;
}): Promise<TimeEntry> {
  const res = await axios.put(
    `time-track/${params.timeTrackId}/stop`,
    params.data
  );

  return res.data;
}

export interface TimeTrack {
  _id: string;
  sum: number;
  status: "Running" | "Paused";
  matter: Matter;
  law_firm: string;
  assignee: Attorney;
  created_at: string;
  updated_at: string;
}
