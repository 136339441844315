import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import {
  addListToDropdown,
  createDropdown,
  Model,
} from "@ckeditor/ckeditor5-ui";
import { Collection } from "@ckeditor/ckeditor5-utils";
import { cheatSheetCommonGroups } from "../components/cheat-sheet/useCheatSheet";
import YaoFormFieldCheatSheetPlugin from "./ckeditor_yaoformfield_plugin";
import { CheatSheetField } from "../components/cheat-sheet/CheatSheet.interface";
import { Editor } from "@ckeditor/ckeditor5-core";
import { EditorWithUI } from "@ckeditor/ckeditor5-core/src/editor/editorwithui";

const extractField = (field: CheatSheetField, nameComplement = "") => ({
  type: "button",
  model: new Model({
    id: field.key,
    withText: true,
    label: `${nameComplement}${nameComplement.length > 0 ? " " : ""}${
      field.label
    }`,
  }),
});

const pluginContentGenerator = (
  editor: Editor | EditorWithUI,
  pluginName: string,
  buttonLabel: string,
  groupKey: string,
  tabKey: string
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  editor.ui.componentFactory.add(pluginName, (locale) => {
    const dropdownView = createDropdown(locale);
    dropdownView.buttonView.set({
      label: buttonLabel,
      withText: true,
      tooltip: true,
    });
    const items = new Collection();
    const groups = cheatSheetCommonGroups();
    if (Array.isArray(groups) && groups.length > 0) {
      const group = groups.find((g) => g.id === groupKey);
      if (Array.isArray(group?.tabs)) {
        const tab = group?.tabs.find((t) => t.id === tabKey);
        if (Array.isArray(tab?.fields)) {
          tab?.fields.forEach((field) => {
            if (Array.isArray(field.children)) {
              field.children.forEach((child) => {
                items.add(extractField(child, field.label));
              });
            } else {
              items.add(extractField(field));
            }
          });
        }
      } else if (Array.isArray(group?.fields)) {
        group?.fields.forEach((field) => {
          if (Array.isArray(field.children)) {
            field.children.forEach((child) => {
              items.add(extractField(child, field.label));
            });
          } else {
            items.add(extractField(field));
          }
        });
      }
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    addListToDropdown(dropdownView, items);
    dropdownView.on("execute", (e) => {
      const { id } = e.source;
      if (!`${id || ""}`.length) {
        return;
      }

      editor.model.change((writer) => {
        const selection = editor.model.document.selection;
        const currentAttrs = selection.getAttributes();
        const position = selection.focus;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        writer.insertText(id, currentAttrs, position);
      });
    });
    return dropdownView;
  });
};

class YaoCheatSheetClientPlugin extends Plugin {
  init() {
    const editor = this.editor;
    pluginContentGenerator(editor, "yaoCheatSheetClient", "Client", "1", "1.1");
  }
}

class YaoCheatSheetClientCompanyPlugin extends Plugin {
  init() {
    const editor = this.editor;
    pluginContentGenerator(
      editor,
      "yaoCheatSheetClientCompany",
      "Company",
      "1",
      "1.2"
    );
  }
}

class YaoCheatSheetCCSolicitorPlugin extends Plugin {
  init() {
    const editor = this.editor;
    pluginContentGenerator(
      editor,
      "yaoCheatSheetCCSolicitor",
      "Solicitor",
      "2",
      "2.1"
    );
  }
}

class YaoCheatSheetCCLenderPlugin extends Plugin {
  init() {
    const editor = this.editor;
    pluginContentGenerator(
      editor,
      "yaoCheatSheetCCLender",
      "Lender",
      "2",
      "2.2"
    );
  }
}

class YaoCheatSheetCCBrokerPlugin extends Plugin {
  init() {
    const editor = this.editor;
    pluginContentGenerator(
      editor,
      "yaoCheatSheetCCBroker",
      "Broker",
      "2",
      "2.3"
    );
  }
}

class YaoCheatSheetCCEstateAgentPlugin extends Plugin {
  init() {
    const editor = this.editor;
    pluginContentGenerator(
      editor,
      "yaoCheatSheetCCEstateAgent",
      "Estate Agent",
      "2",
      "2.4"
    );
  }
}

class YaoCheatSheetCCCounselPlugin extends Plugin {
  init() {
    const editor = this.editor;
    pluginContentGenerator(
      editor,
      "yaoCheatSheetCCCounsel",
      "Counsel",
      "2",
      "2.5"
    );
  }
}

class YaoCheatSheetCCExpertPlugin extends Plugin {
  init() {
    const editor = this.editor;
    pluginContentGenerator(
      editor,
      "yaoCheatSheetCCExpert",
      "Expert",
      "2",
      "2.6"
    );
  }
}

class YaoCheatSheetCCInsurerPlugin extends Plugin {
  init() {
    const editor = this.editor;
    pluginContentGenerator(
      editor,
      "yaoCheatSheetCCInsurer",
      "Insurer",
      "2",
      "2.7"
    );
  }
}

class YaoCheatSheetCCLandlordPlugin extends Plugin {
  init() {
    const editor = this.editor;
    pluginContentGenerator(
      editor,
      "yaoCheatSheetCCLandlord",
      "Landlord",
      "2",
      "2.8"
    );
  }
}

class YaoCheatSheetCCManagementCompanyPlugin extends Plugin {
  init() {
    const editor = this.editor;
    pluginContentGenerator(
      editor,
      "yaoCheatSheetCCManagementCompany",
      "Management Company",
      "2",
      "2.9"
    );
  }
}

class YaoCheatSheetCCLocalAuthorityPlugin extends Plugin {
  init() {
    const editor = this.editor;
    pluginContentGenerator(
      editor,
      "yaoCheatSheetCCLocalAuthority",
      "Local Authority",
      "2",
      "2.10"
    );
  }
}

class YaoCheatSheetCCOtherPartyPlugin extends Plugin {
  init() {
    const editor = this.editor;
    pluginContentGenerator(
      editor,
      "yaoCheatSheetCCOtherParty",
      "Other Party",
      "2",
      "2.11"
    );
  }
}

class YaoCheatSheetCCTenantPlugin extends Plugin {
  init() {
    const editor = this.editor;
    pluginContentGenerator(
      editor,
      "yaoCheatSheetCCTenant",
      "Tenant",
      "2",
      "2.12"
    );
  }
}

class YaoCheatSheetCCSearchProviderPlugin extends Plugin {
  init() {
    const editor = this.editor;
    pluginContentGenerator(
      editor,
      "yaoCheatSheetCCSearchProvider",
      "Search Provider",
      "2",
      "2.13"
    );
  }
}

class YaoCheatSheetCCCourtPlugin extends Plugin {
  init() {
    const editor = this.editor;
    pluginContentGenerator(
      editor,
      "yaoCheatSheetCCCourt",
      "Court",
      "2",
      "2.14"
    );
  }
}

class YaoCheatSheetMatterDetailsPlugin extends Plugin {
  init() {
    const editor = this.editor;
    pluginContentGenerator(
      editor,
      "yaoCheatSheetMatterDetails",
      "Matter Details",
      "3",
      ""
    );
  }
}

class YaoCheatSheetLawFirmPlugin extends Plugin {
  init() {
    const editor = this.editor;
    pluginContentGenerator(editor, "yaoCheatSheetLawFirm", "Law firm", "4", "");
  }
}

class YaoCheatSheetMRLawyerPlugin extends Plugin {
  init() {
    const editor = this.editor;
    pluginContentGenerator(
      editor,
      "yaoCheatSheetMRLawyer",
      "Resp. Lawyer",
      "5",
      "5.1"
    );
  }
}

class YaoCheatSheetMRSupervisorPlugin extends Plugin {
  init() {
    const editor = this.editor;
    pluginContentGenerator(
      editor,
      "yaoCheatSheetMRSupervisor",
      "Resp. Supervisor",
      "5",
      "5.2"
    );
  }
}

class YaoCheatSheetMRCurrentUserPlugin extends Plugin {
  init() {
    const editor = this.editor;
    pluginContentGenerator(
      editor,
      "yaoCheatSheetMRCurrentUser",
      "Current User",
      "5",
      "5.3"
    );
  }
}

class YaoCheatSheetMRSecretaryPlugin extends Plugin {
  init() {
    const editor = this.editor;
    pluginContentGenerator(
      editor,
      "yaoCheatSheetMRSecretary",
      "Secretary",
      "5",
      "5.4"
    );
  }
}

class YaoCheatSheetMRParalegalPlugin extends Plugin {
  init() {
    const editor = this.editor;
    pluginContentGenerator(
      editor,
      "yaoCheatSheetMRParalegal",
      "Paralegal",
      "5",
      "5.5"
    );
  }
}

export {
  YaoCheatSheetClientPlugin,
  YaoCheatSheetClientCompanyPlugin,
  YaoCheatSheetCCSolicitorPlugin,
  YaoCheatSheetCCLenderPlugin,
  YaoCheatSheetCCBrokerPlugin,
  YaoCheatSheetCCEstateAgentPlugin,
  YaoCheatSheetCCCounselPlugin,
  YaoCheatSheetCCExpertPlugin,
  YaoCheatSheetCCInsurerPlugin,
  YaoCheatSheetCCLandlordPlugin,
  YaoCheatSheetCCManagementCompanyPlugin,
  YaoCheatSheetCCLocalAuthorityPlugin,
  YaoCheatSheetCCOtherPartyPlugin,
  YaoCheatSheetCCTenantPlugin,
  YaoCheatSheetCCSearchProviderPlugin,
  YaoCheatSheetCCCourtPlugin,
  YaoCheatSheetMatterDetailsPlugin,
  YaoCheatSheetLawFirmPlugin,
  YaoCheatSheetMRLawyerPlugin,
  YaoCheatSheetMRSupervisorPlugin,
  YaoCheatSheetMRCurrentUserPlugin,
  YaoCheatSheetMRSecretaryPlugin,
  YaoCheatSheetMRParalegalPlugin,
  YaoFormFieldCheatSheetPlugin,
};
